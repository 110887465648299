import React, { Component } from 'react';
import store from 'store';

import axios from 'axios';
import Header from '../Header';
import { Redirect, Link } from 'react-router-dom';
import Pagination from '../component/Pagination';
import Auth from '../modules/Auth';

class Access extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));

    this.getrolespermission();
    this.getpermission();

    this.state = {
      //exampleItems: exampleItems,
      fields: {},
      errors: {},
      pageOfItems: [],
      persons: [],
      permi: '',
      permission: [],
      perm: [],
      getperm: [],
      clearance: '1',
      errorMessage: '',
      status_code: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  getrolespermission() {
    axios
      .post(`${Auth.getapiurl()}/admin/getrolePermissions`, {
        session: Auth.getToken(),
        role_id: this.props.match.params.id,
      })
      .then((res) => {
        const perm = res.data;
        this.setState({ perm });
      });
  }

  getpermission() {
    axios.post(`${Auth.getapiurl()}/admin/getPermissions`, { session: Auth.getToken() }).then((res) => {
      const getperm = res.data;
      this.setState({ getperm });
    });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  handleperChange = (event) => {
    let fields = this.state.fields;
    fields[event.target.name] = Array.from(event.target.selectedOptions, (option) => option.value);
    this.setState({ fields });
  };

  handleclearChange = (event) => {
    this.setState({ clearance: event.target.value });
  };

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['permi']) {
      formIsValid = false;
      errors['permi'] = '*Please select permission.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.validateForm()) {
      let clr;
      if (this.state.clearance !== undefined) {
        clr = this.state.clearance;
      } else {
        clr = '1';
      }

      axios
        .post(`${Auth.getapiurl()}/admin/addRolePermission`, {
          session: Auth.getToken(),
          role_id: this.props.match.params.id,
          permission_id: this.state.fields.permi,
          permissionvalue: clr,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          this.getrolespermission();
        });
    }
  };

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeRolePermission`, { session: Auth.getToken(), perm_role_id: itemId })
      .then((response) => {
        this.getrolespermission();
      });
  };

  accesspermission = (roleid) => {
    this.props.history.push('/role-access/permission/' + roleid);
  };

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    {this.renderAlert()}
                    <div className="col-md-6">
                      <h3 className="box-title">Access Control List</h3>
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                  <div className="box-body">
                    <div className="row">
                      <form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label className="col-sm-2 control-label">
                            Select Permission: <span className="red">*</span>
                          </label>

                          <div className="col-sm-6">
                            <select
                              name="permi"
                              multiple={true}
                              onChange={this.handleperChange}
                              className="form-control"
                            >
                              <option value="">Select</option>
                              {this.state.getperm.map((items) => (
                                <option value={items.ID}>{items.Permissionkey}</option>
                              ))}
                            </select>
                            <div className="errorMsg">{this.state.errors.permi}</div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="col-sm-2 control-label">Select clearance: </label>

                          <div className="col-sm-6">
                            <select name="clearance" onChange={this.handleclearChange} className="form-control">
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                          </div>
                        </div>

                        <div className="box-footer text-center col-sm-8">
                          <Link className="btn btn-primary" to="/role">
                            Back
                          </Link>
                          <button type="submit" className="btn btn-info leftside">
                            Save
                          </button>
                        </div>
                      </form>

                      <div className="col-md-12">
                        <table className="table table-bordered table-striped">
                          <thead className="thead-dark">
                            <tr>
                              <td>ID</td>
                              <td>Permissions</td>
                              <td>Permission Value</td>
                              <td>Action</td>
                            </tr>
                          </thead>
                          <tfoot className="thead-dark">
                            <tr>
                              <td>ID</td>
                              <td>Permissions</td>
                              <td>Permission Value</td>
                              <td>Action</td>
                            </tr>
                          </tfoot>
                          <tbody>
                            {this.state.pageOfItems.map((item) => (
                              <tr key={item.ID}>
                                <td>{item.ID}</td>

                                <td>{item.Permissionkey}</td>

                                <td>{item.permissionValue}</td>

                                <td>
                                  <Link to={'/accessedit/' + item.ID + '/' + item.roleID} className="btn btn-info">
                                    <i className="fa fa-edit"></i>
                                  </Link>

                                  <button
                                    type="submit"
                                    className="btn btn-danger leftside"
                                    onClick={() => {
                                      if (window.confirm('Delete the item?')) {
                                        this.handleDelete(item.ID);
                                      }
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>{' '}
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <Pagination items={this.state.perm} onChangePage={this.onChangePage} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Access;
