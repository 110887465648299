import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './../../Header';
import Auth from './../../modules/Auth';

class WalletBalance extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
    this.addnewhandleclick = this.addnewhandleclick.bind(this);

    this.getbalance();

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      status: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  getbalance() {
    axios
      .post(`${Auth.getapiurl()}/admin/getBalancebyadmin`, {
        session: Auth.getToken(),
        customerID: this.props.match.params.id,
      })
      .then((res) => {
        const persons = res.data[0];
        this.setState({ persons });
      });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/orderstatus-add');
  }

  getsearch = () => {
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchOrderstatus`, {
        session: Auth.getToken(),
        search: this.state.search,
      })
      .then((res) => {
        const persons = res.data;
        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ status: '204' });
        }
      });
  };

  handlesearchChange = (event) => {
    this.setState(
      {
        search: event.target.value,
      },
      () => {
        if (this.state.search && this.state.search.length > 1) {
          if (this.state.search.length % 2 === 0) {
            this.getsearch();
          }
        } else {
          this.setState({ status: '204' });
          this.getbalance();
        }
      }
    );
  };

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const status = this.state.status;
    const status_msg = this.state.status_msg;

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">Wallet Balance</h3>
                    </div>
                    <div className="col-md-4 text-right"></div>
                    <div className="col-md-2 text-right">
                      <Link to={'/customers'} className="btn btn-success">
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                      </Link>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div align="center">
                          <h1>Balance : {this.state.persons.balance}</h1>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default WalletBalance;
