import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './../../Header';
import Auth from './../../modules/Auth';

class CustomerRoleedit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
    };
    this.getcustomerRole();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter role name.';
    }

    if (typeof fields['name'] !== 'undefined') {
      if (!fields['name'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['name'] = '*Please enter alphabet characters only.';
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/UpdatecustomerRole`, {
          session: Auth.getToken(),
          name: this.state.fields.name,
          role_id: this.props.match.params.id,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });

          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/customer-role');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  getcustomerRole() {
    axios
      .post(`${Auth.getapiurl()}/admin/getcustomerRole`, {
        session: Auth.getToken(),
        role_id: this.props.match.params.id,
      })
      .then((res) => {
        const fields = res.data[0];

        this.setState({ fields });
      });
  }

  render() {
    const fields = this.state.fields;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Role</h3>
                  </div>

                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Role Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="Role Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/customer-role">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CustomerRoleedit;
