import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class AccessEdit extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));

    this.getrolespermission();
    this.getpermission();
    this.getpermissionbyid();

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      permi: '',
      permission: [],
      perm: [],
      getperm: [],
      clearance: '',
      errorMessage: '',
      status_code: '',
      getpermbyid: [],
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  // get particllar role id //

  getrolespermission() {
    axios
      .post(`${Auth.getapiurl()}/admin/getrolePermissions`, {
        session: Auth.getToken(),
        role_id: this.props.match.params.roleid,
      })
      .then((res) => {
        const perm = res.data;
        this.setState({ perm });
      });
  }

  //==== get all permissiion ==============//

  getpermission() {
    axios.post(`${Auth.getapiurl()}/admin/getPermissions`, { session: Auth.getToken() }).then((res) => {
      const getperm = res.data;
      this.setState({ getperm });
    });
  }

  // get by permission id =====//

  getpermissionbyid() {
    axios
      .post(`${Auth.getapiurl()}/admin/getrolePermissionByid`, {
        session: Auth.getToken(),
        role_perm_id: this.props.match.params.id,
      })
      .then((res) => {
        const getpermbyid = res.data[0];

        this.setState({ getpermbyid });
      });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  handleperChange = (event) => {
    this.setState({ permi: event.target.value });
  };

  handleclearChange = (event) => {
    this.setState({ clearance: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    //console.log(this.state.permi);
    let permiitem;
    let permiitemvalue;
    if (this.state.permi !== '') {
      permiitem = this.state.permi;
    } else {
      permiitem = this.state.getpermbyid.permissionID;
    }

    if (this.state.clearance !== '') {
      permiitemvalue = this.state.clearance;
    } else {
      permiitemvalue = this.state.getpermbyid.permissionValue;
    }

    axios
      .post(`${Auth.getapiurl()}/admin/UpdateRolePermission`, {
        session: Auth.getToken(),
        role_id: this.props.match.params.roleid,
        PermissionRoleid: this.props.match.params.id,
        PermissionID: permiitem,
        PermissionValue: permiitemvalue,
      })

      .then((res) => {
        //console.log(res);

        this.setState({ errorMessage: res.data.error, status_code: res.data.status });

        if (res.data.status != '203') {
          setTimeout(
            function () {
              this.props.history.push('/role-access/permission/' + this.props.match.params.roleid);
            }.bind(this),
            2000
          );
        }

        //this.props.history.push('/role-access/permission/'+this.props.match.params.roleid);
        //this.getrolespermission();
      });
  };

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeRolePermission`, {
        session: Auth.getToken(),
        perm_role_id: itemId,
      })
      .then((response) => {
        console.log(response);
        this.getrolespermission();
      });
  };

  changeditpage(item_id, item_role_id) {
    this.props.history.push('/accessedit/' + item_id + '/' + item_role_id);
    this.props.match.params.id = item_id;

    this.getpermissionbyid();
  }

  accesspermission = (roleid) => {
    this.props.history.push('/role-access/permission/' + roleid);
  };

  render() {
    const getpermbyid = this.state.getpermbyid;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    {this.renderAlert()}
                    <div className="col-md-6">
                      <h3 className="box-title">Access Control List</h3>
                    </div>
                    <div className="col-md-6 text-right"></div>
                  </div>
                  <div className="box-body">
                    <div className="row">
                      <form className="form-horizontal" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label className="col-sm-2 control-label">
                            Select Permission: <span className="red">*</span>
                          </label>

                          <div className="col-sm-6 has-error">
                            <select name="permi" onChange={this.handleperChange} className="form-control">
                              <option value="">Select</option>
                              {this.state.getperm.map((items) => (
                                <option value={items.ID} selected={getpermbyid.permissionID == items.ID}>
                                  {items.Permissionkey}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="col-sm-2 control-label">
                            Select clearance: <span className="red">*</span>
                          </label>

                          <div className="col-sm-6 has-error">
                            <select name="clearance" onChange={this.handleclearChange} className="form-control">
                              <option value="1" selected={getpermbyid.permissionValue == 1}>
                                1
                              </option>
                              <option value="2" selected={getpermbyid.permissionValue == 2}>
                                2
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="box-footer text-center col-sm-8">
                          <Link className="btn btn-primary" to="/role">
                            Back
                          </Link>
                          <button type="submit" className="btn btn-info leftside">
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default AccessEdit;
