import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import Header from './../../Header';
import Auth from './../../modules/Auth';

class Productreplacementadd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
      options: [],
      Product: {},
      toProdID: '',
      selectedOption: '',
      isClearable: true,
    };

    this.getProduct();
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);

    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  getProduct() {
    axios
      .post(`${Auth.getapiurl()}/admin/getProductbyID`, {
        session: Auth.getToken(),
        product_id: this.props.match.params.id,
      })
      .then((res) => {
        const Product = res.data[0];

        this.setState({ Product });
      });
  }

  handleProductChange = (newValue: string) => {
    if (newValue != '') {
      axios
        .post(`${Auth.getapiurl()}/admin/getProductsForAutocomplete`, {
          session: Auth.getToken(),
          newValue: newValue,
        })
        .then((res) => {
          const options = res.data;

          if (options.length > 0) {
            this.setState({ options });
          } else {
          }
        });
    }
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });

    if (selectedOption) {
      const toProdID_value = selectedOption.value;
      this.setState({ toProdID: toProdID_value });
    }
  };

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/addReplacementByProductID`, {
          session: Auth.getToken(),
          origProdID: this.props.match.params.id,
          toProdID: this.state.toProdID,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/product-replacement/' + this.props.match.params.id);
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!this.state.Product.ID) {
      formIsValid = false;
      errors['origProdID'] = '*Please select Original Product.';
    }

    if (!this.state.toProdID) {
      formIsValid = false;
      errors['toProdID'] = '*Please select To Product.';
    }

    if (this.state.Product.ID === this.state.toProdID) {
      formIsValid = false;
      errors['toProdID'] = '*Please select Different To Product.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Replacement</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Original Product: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.origProdID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="origProdID" className="form-control">
                            <option value={this.state.Product.ID}>{this.state.Product.name}</option>
                          </select>
                          <div className="errorMsg">{this.state.errors.origProdID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          To Product: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.toProdID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <Select
                            name="toProdID"
                            placeholder="Product Search.."
                            value={this.state.selectedOption}
                            onChange={this.handleSelectedChange}
                            options={this.state.options}
                            isClearable={this.state.isClearable}
                            onInputChange={this.handleProductChange}
                          />
                          <div className="errorMsg">{this.state.errors.toProdID}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to={'/product-replacement/' + this.props.match.params.id}>
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Productreplacementadd;
