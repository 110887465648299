import ReactImageFallback from 'react-image-fallback';
import { Redirect, Link } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import algoliasearch from 'algoliasearch';
import React, { Component } from 'react';
import Auth from '../modules/Auth';
import Loader from 'react-loader';
import Select from 'react-select';
import Header from '../Header';
import axios from 'axios';
import './style.css';

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY } = process.env;
const client = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY);
const index = client.initIndex('products');

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        productID: 0,
        productName: '',
        productVendor: '',
        productPrice: 0,
        discountAmount: 0,
        discountType: 'fixedAmount',
        dateStart: null,
        dateEnd: null,
        isDeal: 0,
        productImage: '',
      },
      selectedProductOption: '',
      mindate: new Date(),
      prodOptions: [],
      loaded: false,
      errors: {},
      apiResponseElement: null,
      suffix: ''
    };

    this.dealID = this.props.match.params.id;
    this.getDealbyID();
    this.handleChange = this.handleChange.bind(this);
    this.updateDeal = this.updateDeal.bind(this);
  }

  getDealbyID() {
    if (this.dealID) {
      axios
        .post(`${Auth.getapiurl()}/admin/getDealbyID`, {
          session: Auth.getToken(),
          deal_id: this.dealID,
        })
        .then((res) => {
          if (res.data.status == 200) {
            const fields = res.data.results[0];

            this.setState({ loaded: true });
            this.setState({ fields });

            this.setState({
              selectedProductOption: {
                key: parseInt(fields.id),
                value: parseInt(fields.id),
                productName: fields.productName,
                productVendor: fields.productVendor,
              },
            });
          }
        });
    }
  }

  renderAlert(statusCode, message) {
    if (message && statusCode == 400) {
      this.setState({
        apiResponseElement: (
          <div className="alert alert-danger">
            <strong>Oops! </strong>
            {message}
          </div>
        )
      });

      setTimeout(() => {
        this.setState({
          apiResponseElement: null
        });
      }, 5000);
    } else if (message && statusCode == 200) {
      this.setState({
        apiResponseElement: (
          <div className="alert alert-success">
            <strong>Success, </strong>
            {message}
          </div>
        )
      });

      setTimeout(() => {
        this.setState({
          apiResponseElement: null
        });
      }, 3000);
    }
  }

  handleChangeDateStart = (dateStart) => this.setState({ fields: { ...this.state.fields, dateStart } });
  handleChangeDateEnd = (dateEnd) => this.setState({ fields: { ...this.state.fields, dateEnd } });

  handleChange(e) {
    let fields = this.state.fields;

    let value = e.target.value;
    let suffix = this.state.suffix;

    if (e.target.name === 'discountType') {
      suffix = '';
    } else if (e.target.name === 'discountAmount') {
      // keep only . and digits
      value = (value + '').replace(/[^.0-9]*/g, '');
      // remove all dots after the first one
      value = value.replace(/(?<=\.[^.]*)\./g, '');
      // remove past 2 decimal places without adding decimal places (so that the user can type 20. without it autocompleting to 20.00 or otherwise annoying the user)
      value = value.replace(/(?<=\..{2}).*$/, '');
      // check to see if there's a dot at the end or dot followed by a 0 or more 0s to re-add them (so it keeps the original intent), then add it back in if it is once the value has been sanitised
      suffix = value.match(/\.0*$/);
      if (suffix) suffix = suffix[0];
      else suffix = '';
      value = ((Number(value) || 0) / (this.state.fields.discountType !== 'fixedAmount' ? 100 : 1));
    }

    fields[e.target.name] = value;
    this.setState({ fields, suffix });
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['productID']) {
      formIsValid = false;
      errors['productID'] = '*Please enter the product ID.';
    }

    if (!fields['discountAmount']) {
      formIsValid = false;
      errors['discountAmount'] = '*Please enter the discount amount.';
    }
    if (fields['discountType'] === 'percent' && Number(fields['discountAmount']) > 100) {
      formIsValid = false;
      errors['discountAmount'] = '*Percentage discount should not exceed 100%.';
    }

    if (!fields['dateStart']) {
      formIsValid = false;
      errors['dateStart'] = '*Please enter start date.';
    }

    if (!fields['dateEnd']) {
      formIsValid = false;
      errors['dateEnd'] = '*Please enter the end date.';
    }

    this.setState({ errors });
    return formIsValid;
  }

  updateDeal(e) {
    e.preventDefault();

    if (this.validateForm()) {
      const formData = new FormData();

      if (this.dealID !== undefined) formData.set('dealID', this.dealID);

      formData.set('session', Auth.getToken());

      formData.set('productID', this.state.fields.productID);
      formData.set('discountAmount', this.state.fields.discountAmount);
      formData.set('discountType', this.state.fields.discountType);
      formData.set('dateStart', this.state.fields.dateStart);
      formData.set('dateEnd', this.state.fields.dateEnd);
      formData.set('isDeal', this.state.fields.isDeal);

      axios
        .post(`${Auth.getapiurl()}/admin/updateDeal`, formData, {
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent.loaded / progressEvent.total);
          },
        })
        .then((res) => {
          let message = res.data.message;
          let statusCode = res.data.status;

          if (res.data.status == 200) {
            this.renderAlert(statusCode, message);
            setTimeout(
              function () {
                this.props.history.push('/deals');
              }.bind(this),
              2000,
            );
          } else {
            this.renderAlert(statusCode, message);
          }

          this.setState({ loaded: true });
        });
    }
  }

  handleProductChange = (selectedProductOption) => {
    this.setState({ fields: { ...this.state.fields, ...selectedProductOption } });
    this.setState({ selectedProductOption, suffix: '' });
  };

  getAlgoliaProducts = async (newValue) => {
    try {
      const { hits } = await index.search(newValue, { clickAnalytics: false, analytics: false });

      let algoliaProductsArr = [];
      let position = 1;

      for (const hit of hits) {
        algoliaProductsArr.push({
          key: parseInt(hit.objectID),
          value: parseInt(hit.objectID),

          productID: parseInt(hit.objectID),
          productName: hit.name,
          productVendor: hit.vendor.name,
          productPrice: hit.pricing[0].originalPrice,
          discountAmount: (hit.pricing[0].amountDiscount || hit.pricing[0].percentageDiscount) ?? 0,
          discountType: hit.pricing[0].percentageDiscount ? 'percent' : 'fixedAmount',
          dateStart: hit.pricing[0].discountDateStart ?? new Date(),
          dateEnd: hit.pricing[0].discountDateEnd ?? new Date(),
          productImage: hit.imageURL,
          isDeal: 1,
        });
        position++;
      }

      if (algoliaProductsArr.length > 0) this.setState({ prodOptions: algoliaProductsArr });
    } catch (err) {
      console.log(err);
    }
  };

  handleProductInputChange = (newValue) => {
    if (newValue !== '') this.getAlgoliaProducts(newValue);
  };

  render() {
    if (!Auth.getToken()) return <Redirect to="/login" />;

    let discountAmountFormatted = this.state.fields.discountAmount // ${(this.state.fields.discountAmount * 100) + this.state.suffix};
    // change calculation based on suffix existence and whether or not % or R value
    if (this.state.fields.discountType === 'fixedAmount') {
      if (this.state.suffix) {
        // this means there are no decimal points, toFixed(0)
        discountAmountFormatted = discountAmountFormatted.toFixed(0) + this.state.suffix;
      } else {
        // decimal points are possible, but remove past 2 decimal points and if it's only .00 (since the suffix should handle that instead)
        discountAmountFormatted = discountAmountFormatted.toFixed(2).replace(/\.00$/, '');
      }
      discountAmountFormatted = 'R ' + discountAmountFormatted;
    } else {
      discountAmountFormatted *= 100;
      if (this.state.suffix) {
        // this means there are no decimal points, toFixed(0)
        discountAmountFormatted = discountAmountFormatted.toFixed(0) + this.state.suffix;
      } else {
        // decimal points are possible, but remove past 2 decimal points and if it's only .00 (since the suffix should handle that instead)
        discountAmountFormatted = discountAmountFormatted.toFixed(2).replace(/\.00$/, '');
      }
      discountAmountFormatted += ' %';
    }

    return (
      <div>
        {this.dealID && <Loader loaded={this.state.loaded} />}
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      {this.dealID ? (
                        <h3 className="box-title">
                          <i className="fa fa-edit" /> Edit Deal
                        </h3>
                      ) : (
                        <h3 className="box-title">
                          <i className="fa fa-plus-circle" /> Add Deal
                        </h3>
                      )}
                    </div>

                    <div className="col-md-3 text-right">
                      <label htmlFor="save" className="btn btn-info">
                        <i className="fa fa-save">{' Save'}</i>
                      </label>
                    </div>

                    <div className="col-md-3 text-right">
                      <Link to="/deals" className="btn btn-success">
                        <i className="fa fa-arrow-left" aria-hidden="true" />
                        {' Back'}
                      </Link>
                    </div>
                  </div>

                  <div className="box-body" id="divToPrint">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h3 className="panel-title">
                              <i className="fa fa-gift" />
                              {' Deal Info'}
                            </h3>
                          </div>

                          {this.state.apiResponseElement}

                          <form className="form-horizontal" onSubmit={this.updateDeal}>
                            <div className="box-body">
                              <div className="form-group">
                                <label className="col-sm-2 control-label">
                                  Product Name: <span className="red">*</span>
                                </label>
                                <div className={this.state.errors.productID ? 'col-sm-8 has-error' : 'col-sm-8'}>
                                  <Select
                                    name="productID"
                                    placeholder="Enter productName"
                                    value={this.state.selectedProductOption}
                                    onChange={this.handleProductChange}
                                    options={this.state.prodOptions}
                                    onInputChange={this.handleProductInputChange}
                                    getOptionLabel={(option) => `${option.productName} (${option.productVendor})`}
                                    isDisabled={this.dealID}
                                  />
                                  <div className="errorMsg">{this.state.errors.productID}</div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="col-sm-2 control-label">
                                  Product Price: <span className="red">*</span>
                                </label>

                                <div className={this.state.errors.productPrice ? 'col-sm-8 has-error' : 'col-sm-8'}>
                                  <input
                                    disabled
                                    type="text"
                                    className="form-control"
                                    id="inputproduct"
                                    placeholder="Enter productPrice"
                                    name="productPrice"
                                    value={this.state.fields.productPrice}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className="errorMsg">{this.state.errors.productPrice}</div>
                              </div>
                              <div className="form-group">
                                <label className="col-sm-2 control-label">
                                  {this.state.fields.discountType === 'fixedAmount' ? `Discount:` : `Discount %:`} <span className="red">*</span>
                                </label>

                                <div className={this.state.errors.discountAmount ? 'col-sm-8 has-error' : 'col-sm-8'}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inputproduct"
                                    placeholder="Enter discountAmount"
                                    name="discountAmount"
                                    value={discountAmountFormatted}
                                    onChange={this.handleChange}
                                  />
                                </div>
                                <div className="errorMsg">{this.state.errors.discountAmount}</div>
                              </div>

                              <div className="form-group">
                                <label className="col-sm-2 control-label">
                                  Discount Type: <span className="red">*</span>
                                </label>

                                <div className={this.state.errors.discountType ? 'col-sm-8 has-error' : 'col-sm-8'}>
                                  <input
                                    type="radio"
                                    name="discountType"
                                    value="fixedAmount"
                                    id="fixedAmount"
                                    onChange={this.handleChange}
                                    checked={this.state.fields.discountType === 'fixedAmount'}
                                  />
                                  <label htmlFor="fixedAmount">&nbsp;&nbsp;Fixed Amount</label> &nbsp;&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    name="discountType"
                                    value="percent"
                                    id="percent"
                                    checked={this.state.fields.discountType === 'percent'}
                                    onChange={this.handleChange}
                                  />
                                  <label htmlFor="percent">&nbsp;&nbsp;Percent</label>
                                  <div className="errorMsg">{this.state.errors.discountType}</div>
                                </div>
                              </div>

                              {!this.dealID && (
                                <div className="form-group">
                                  <label className="col-sm-2 control-label">
                                    Product Image<span className="red">*</span>
                                  </label>

                                  <div className="col-sm-4 fileContainer">
                                    <img
                                      alt="Product Image"
                                      src={Auth.imageCheck(this.state.fields.productImage)}
                                      fallbackImage={`${Auth.getapiurl()}/noimage.png`}
                                      initialImage={`${Auth.getapiurl()}/loading.gif`}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="form-group">
                                <label className="col-sm-2 control-label">
                                  Start Date: <span className="red">*</span>
                                </label>
                                <div className={this.state.errors.dateStart ? 'col-sm-8 has-error' : 'col-sm-8'}>
                                  <DatePicker
                                    onChange={this.handleChangeDateStart}
                                    value={new Date(this.state.fields.dateStart)}
                                    minDate={this.state.mindate}
                                    format="dd/MM/y"
                                    className="date-picker"
                                  />
                                </div>
                                <div className="errorMsg">{this.state.errors.dateStart}</div>
                              </div>

                              <div className="form-group">
                                <label className="col-sm-2 control-label ">
                                  End Date: <span className="red">*</span>
                                </label>
                                <div className={this.state.errors.dateEnd ? 'col-sm-8 has-error' : 'col-sm-8'}>
                                  <DatePicker
                                    onChange={this.handleChangeDateEnd}
                                    value={new Date(this.state.fields.dateEnd)}
                                    minDate={this.state.mindate}
                                    format="dd/MM/y"
                                    className="date-picker"
                                  />
                                </div>
                                <div className="errorMsg">{this.state.errors.dateEnd}</div>
                              </div>
                              {this.dealID && (
                                <div className="form-group">
                                  <label className="col-sm-2 control-label">
                                    Is Deal: <span className="red">*</span>
                                  </label>

                                  <div className={this.state.errors.isDeal ? 'col-sm-8 has-error' : 'col-sm-8'}>
                                    <input
                                      type="radio"
                                      name="isDeal"
                                      value="1"
                                      id="isDealYes"
                                      onChange={this.handleChange}
                                      checked={this.state.fields.isDeal == 1}
                                    />
                                    <label htmlFor="isDealYes">&nbsp;&nbsp;Yes</label> &nbsp;&nbsp;&nbsp;
                                    <input
                                      type="radio"
                                      name="isDeal"
                                      value="0"
                                      id="isDealNo"
                                      checked={this.state.fields.isDeal == 0}
                                      onChange={this.handleChange}
                                    />
                                    <label htmlFor="isDealNo">&nbsp;&nbsp;No</label>
                                    <div className="errorMsg">{this.state.errors.isDeal}</div>
                                  </div>
                                </div>
                              )}
                              <div className="box-footer text-center col-sm-8">
                                <Link className="btn btn-primary" to="/deals">
                                  Back
                                </Link>
                                <button id="save" type="submit" className="btn btn-info leftside">
                                  Save
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {(this.state.fields.productPrice || null) && (
                        <div className="col-md-3">
                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h3 className="panel-title">
                                <i className="fa fa-camera" />
                                {' Image'}
                              </h3>
                            </div>
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td id="dealImage">
                                    <h4 style={{ textAlign: 'center' }}>
                                      <b>{this.state.fields.productName}</b>
                                    </h4>
                                    <ReactImageFallback
                                      src={Auth.imageCheck(this.state.fields.productImage)}
                                      fallbackImage={`${Auth.getapiurl()}/noimage.png`}
                                      initialImage={`${Auth.getapiurl()}/loading.gif`}
                                      alt="Product Image"
                                    />
                                    <h4 style={{ textAlign: 'center' }}>({this.state.fields.productVendor})</h4>
                                    <h4 style={{ textAlign: 'center' }}>R {this.state.fields.productPrice}</h4>

                                    <h4 style={{ textAlign: 'center' }}>
                                      <b>
                                        Discount:&nbsp;
                                        {this.state.fields.discountType === 'fixedAmount'
                                          ? `R ${this.state.fields.discountAmount.toFixed(2)}`
                                          : `${(this.state.fields.discountAmount * 100).toFixed(2)}%`}
                                      </b>
                                    </h4>
                                    <h4 style={{ textAlign: 'center' }}>
                                      <b>
                                        Approx.:&nbsp;
                                        {this.state.fields.discountType === 'fixedAmount'
                                          ? `R ${(this.state.fields.productPrice - this.state.fields.discountAmount).toFixed(2)}`
                                          : `R ${(this.state.fields.productPrice * (1 - this.state.fields.discountAmount)).toFixed(2)}`}
                                      </b>
                                    </h4>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Edit;
