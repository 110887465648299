import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class PaymentMethodadd extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitPaymentMethodForm = this.submitPaymentMethodForm.bind(this);
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submitPaymentMethodForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields['name'] = '';

      this.setState({ fields: fields });
      console.log('abc');
      console.log(this.state);
      axios
        .post(`${Auth.getapiurl()}/admin/addPaymentMethod`, {
          session: Auth.getToken(),
          name: this.state.fields.name,
          type: this.state.fields.type,
          enabled: this.state.fields.enabled,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/payment-method');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter Payment Method Name.';
    }

    if (!fields['type']) {
      formIsValid = false;
      errors['type'] = '*Please enter Payment Method Type.';
    }

    if (!fields['enabled']) {
      formIsValid = false;
      errors['enabled'] = '*Please select status.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Payment Method</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal" onSubmit={this.submitPaymentMethodForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Payment Method Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputpaymentmethod"
                            placeholder="Method Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Payment Method Type: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.type ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputpaymentmethod"
                            placeholder="Method Type"
                            name="type"
                            value={this.state.fields.type}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.type}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Status: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.enabled ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select className="form-control" name="enabled" onChange={this.handleChange}>
                            <option value="">- Select -</option>
                            <option value="1" selected={this.state.fields.type == '1'}>
                              Enabled
                            </option>
                            <option value="0" selected={this.state.fields.type == '0'}>
                              Disabled
                            </option>
                          </select>
                          <div className="errorMsg">{this.state.errors.enabled}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/payment-method">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default PaymentMethodadd;
