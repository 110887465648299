import axios from 'axios';
import React, { Component } from 'react';

class Geo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      status: '',
      lat: '',
      lng: '',
      loc: '',
      tid: '',
    };

    //this.getlate = this.getlate.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.getlate(this.state.loc);
  }

  callapi() {
    console.log('hi');
    axios
      .post(`http://192.168.1.208:5000/walletpayment`, {
        session: '3uGCRDC5dAu2lAObqHpx72NvS6XhnZtENAbl0UtVDUQ=',
        order_amount: '100',
        paymentType: 'walletpeach',
        orderID: '73',
      })
      .then((res) => {
        console.log(res.data);

        this.setState({ tid: res.data.details.id });
      });
  }

  onPlaceSelected = (place) => {
    //console.log( 'plc', place.formatted_address);
    if (place.formatted_address !== undefined) {
      const address = place.formatted_address,
        addressArray = place.address_components,
        /*city = this.getCity( addressArray ),
        area = this.getArea( addressArray ),
        state = this.getState( addressArray ),*/
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      //const autoval = place.formatted_address;
      // Set these values in the state.

      this.setState({
        lat: latValue,
        lng: lngValue,
      });
    }
  };

  render() {
    const turl = this.state.tid;

    var loadScript = function (src) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body').appendChild(tag);
    };

    return (
      <div>
        <script
          async="true"
          src="https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=DDAEBCAA5D3BA62F6C384AAA84753B90.uat01-vm-tx03"
        ></script>

        <form action="http://192.168.1.208:5000/notify" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>

        <input type="button" name="save" value="save" onClick={this.callapi.bind(this)} />
      </div>
    );
  }
}

export default Geo;
