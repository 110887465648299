import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import Header from '../Header';
import Auth from '../modules/Auth';

class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: '',
        productPrice: 0,
        actualPrice: 0,
        discountedPrice: 0,
        discountAmount: 0,
        discountPercent: 0,
        offerDesc: '',
        barcode: '',
        description: '',
        imageURL: '',
        productURL: '',
        productType: '',
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        visibility: 0,
        featured: 0,
        in_stock: '',
        isDeal: '',
        slug: '',
        vendorID: '',
      },
      errors: {},
      category: [],
      tag: [],
      die: [],
      fileImage: null,
      selectedFile: null,
      searchdate: undefined,
      mindate: new Date(),
      searchtodate: undefined,
      vendors: [],
      selectedVendorOption: '',
    };

    this.getCategory();
    this.gettags();
    this.getDietary();
    this.getproductsbyid();
    this.handleChange = this.handleChange.bind(this);
    this.submitProductForm = this.submitProductForm.bind(this);
  }

  gettags() {
    axios.post(`${Auth.getapiurl()}/admin/getTags`, { session: Auth.getToken() }).then((res) => {
      const tag = res.data;

      this.setState({ tag });
    });
  }

  getCategory() {
    axios.post(`${Auth.getapiurl()}/admin/getAllCategories`, {}).then((res) => {
      const category = res.data;

      this.setState({ category });
    });
  }

  getDietary() {
    axios.post(`${Auth.getapiurl()}/admin/getDietary_types`, { session: Auth.getToken() }).then((res) => {
      const die = res.data;

      this.setState({ die });
    });
  }

  getproductsbyid() {
    if (this.props.match.params.id) {
      axios
        .post(`${Auth.getapiurl()}/admin/getProductbyID`, {
          session: Auth.getToken(),
          product_id: this.props.match.params.id,
        })
        .then((res) => {
          const fields = res.data[0];
          const selectedcatOption = fields.categoryID;
          const selectedtagOption = fields.tagID;
          const selecteddieOption = fields.dieID;
          const selectedVendorOption = fields.selectedVendorOption;

          this.setState({ fields, selectedcatOption, selectedtagOption, selecteddieOption, selectedVendorOption });

          if (!this.state.fields.discount_from_date) {
            this.state.searchdate = undefined;
          } else {
            this.state.searchdate = new Date(this.state.fields.discount_from_date);
          }

          if (!this.state.fields.discount_to_date) {
            this.state.searchtodate = undefined;
          } else {
            this.state.searchtodate = new Date(this.state.fields.discount_to_date);
          }

          this.getvendorsbyid(fields.vendorID);
        });
    }
  }

  getvendorsbyid(vendorID) {
    axios.post(`${Auth.getapiurl()}/admin/getVendor`, { session: Auth.getToken(), vendor_id: vendorID }).then((res) => {
      const vendor = { label: res.data[0].name, value: vendorID };
      this.setState({ selectedVendorOption: vendor });
    });
  }

  renderAlert(status, message) {
    if (status == 200) {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {message}
        </div>
      );
    } else if (status == 400) {
      return (
        <div className="alert alert-danger">
          <strong>!Oops, </strong>
          {message}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;

    if ((e.target.name === 'name') | (e.target.name === 'slug')) {
      let slug = e.target.value
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');

      fields['slug'] = slug;

      this.setState({
        fields,
      });
    }

    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  fileChangedHandler = (event) => {
    this.setState({ selectedFile: event.target.files[0], fileImage: URL.createObjectURL(event.target.files[0]) });
  };

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter product name.';
    }

    if (!fields['productPrice']) {
      formIsValid = false;
      errors['price'] = '*Please enter Price.';
    }

    if (!fields['barcode']) {
      formIsValid = false;
      errors['barcode'] = '*Please enter Barcode.';
    }

    if (!fields['categoryID']) {
      formIsValid = false;
      errors['categoryID'] = '*Please select Category.';
    }

    if (!fields['tagID']) {
      formIsValid = false;
      errors['tagID'] = '*Please select Tag.';
    }

    if (!fields['vendorID']) {
      formIsValid = false;
      errors['vendor'] = '*Please select a vendor.';
    }

    if (!fields['slug']) {
      formIsValid = false;
      errors['slug'] = '*Please enter slug.';
    }

    if (!fields['in_stock']) {
      formIsValid = false;
      errors['in_stock'] = '*Please select Stock.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submitProductForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const formData = new FormData();

      if (this.state.selectedFile) {
        formData.append('myFile', this.state.selectedFile, this.state.selectedFile.name);

        formData.set('image', 'YES');
      } else {
        formData.set('image', 'NULL');
      }

      formData.set('session', Auth.getToken());
      formData.set('name', this.state.fields.name);
      formData.set('price', this.state.fields.productPrice);
      formData.set('actualPrice', this.state.fields.actualPrice);
      formData.set('barcode', this.state.fields.barcode);
      formData.set('discountedPrice', this.state.fields.discountedPrice ? this.state.fields.discountedPrice : 0);
      formData.set('discountAmount', this.state.fields.discountAmount ? this.state.fields.discountAmount : 0);
      formData.set('discountPercent', this.state.fields.discountPercent ? this.state.fields.discountPercent : 0);
      formData.set('offerDesc', this.state.fields.offerDesc);
      formData.set('description', this.state.fields.description);
      formData.set('imageURL', this.state.fields.imageURL);
      formData.set('productURL', this.state.fields.productURL);
      formData.set('productType', this.state.fields.productType);
      formData.set('vendorID', this.state.fields.vendorID);
      formData.set('weight', this.state.fields.weight ? this.state.fields.weight : 0);
      formData.set('length', this.state.fields.length ? this.state.fields.length : 0);
      formData.set('width', this.state.fields.width ? this.state.fields.width : 0);
      formData.set('height', this.state.fields.height ? this.state.fields.height : 0);
      formData.set('visibility', this.state.fields.visibility);
      formData.set('featured', this.state.fields.featured);
      formData.set('isDeal', this.state.fields.isDeal ? this.state.fields.isDeal : 0);
      formData.set('slug', this.state.fields.slug);
      formData.set('inStock', this.state.fields.in_stock);
      formData.set('hiddenVendor', this.state.vendor_name);

      var catstr = [];
      this.state.fields.categoryID.forEach((selectedcatOption) => catstr.push(selectedcatOption.value));

      const newcatstr = catstr.join(',');
      formData.set('categoryIDs', newcatstr);

      var tagstr = [];
      this.state.fields.tagID.forEach((selectedtagOption) => tagstr.push(selectedtagOption.value));

      const newtagstr = tagstr.join(',');
      formData.set('tagIDs', newtagstr);

      if (this.state.fields.dieID) {
        var diestr = [];
        this.state.fields.dieID.forEach((selecteddieOption) => diestr.push(selecteddieOption.value));

        const newdiestr = diestr.join(',');
        formData.set('dieIDs', newdiestr);
      } else {
        formData.set('dieIDs', 0);
      }

      var newfromdate = moment(this.state.searchdate).format().split('T');
      var newtodate = moment(this.state.searchtodate).format().split('T');

      formData.set('fromdate', newfromdate[0]);
      formData.set('todate', newtodate[0]);

      if (this.props.match.params.id) {
        formData.set('productID', this.props.match.params.id);

        axios
          .post(`${Auth.getapiurl()}/admin/updateProduct`, formData, {
            onUploadProgress: (progressEvent) => {
              console.log(progressEvent.loaded / progressEvent.total);
            },
          })
          .then((res) => {
            if (res.data.status != 400) {
              this.renderAlert(res.data.status, res.data.message);
              setTimeout(
                function () {
                  this.props.history.push('/product');
                }.bind(this),
                2000
              );
            } else {
              this.renderAlert(res.data.status, res.data.message);
            }
          });
      } else {
        axios
          .post(`${Auth.getapiurl()}/admin/addProduct`, formData, {
            onUploadProgress: (progressEvent) => {
              console.log(progressEvent.loaded / progressEvent.total);
            },
          })
          .then((res) => {
            if (res.data.status != 400) {
              this.renderAlert(res.data.status, res.data.message);
              setTimeout(
                function () {
                  this.props.history.push('/product');
                }.bind(this),
                2000
              );
            } else {
              this.renderAlert(res.data.status, res.data.message);
            }
          });
      }
    }
  }

  /*==================== calender change ======================== */

  handleFromdate = (searchdate) => {
    this.setState(
      {
        searchdate: searchdate,
      },
      () => {
        //this.state.searchdate = moment(this.state.searchdate).format();
      }
    );
  };

  handleTodate = (searchtodate) => {
    this.setState(
      {
        searchtodate: searchtodate,
      },
      () => {
        //this.state.searchtodate = moment(this.state.searchtodate).format();
      }
    );
  };

  /* =================== Dietry type change ============== */

  handledieChange = (selecteddieOption) => {
    this.setState({ selecteddieOption });

    let fields = this.state.fields;
    fields['dieID'] = selecteddieOption;
    this.setState({ fields });
  };

  handleDieinputChange = (newValue: string) => {
    if (newValue != '') {
      axios
        .post(`${Auth.getapiurl()}/admin/getdieautocomplete`, {
          session: Auth.getToken(),
          newValue: newValue,
        })
        .then((res) => {
          const dieoptions = res.data;

          if (dieoptions.length > 0) {
            this.setState({ dieoptions });
          } else {
          }
        });
    }
  };

  /* =================== End Dietry type change ============== */

  /* =================== Tag change ============== */

  handletagChange = (selectedtagOption) => {
    this.setState({ selectedtagOption });

    let fields = this.state.fields;
    fields['tagID'] = selectedtagOption;
    this.setState({ fields });
  };

  handleTaginputChange = (newValue: string) => {
    if (newValue != '') {
      axios
        .post(`${Auth.getapiurl()}/admin/gettagautocomplete`, {
          session: Auth.getToken(),
          newValue: newValue,
        })
        .then((res) => {
          const tagoptions = res.data;

          if (tagoptions.length > 0) {
            this.setState({ tagoptions });
          } else {
          }
        });
    }
  };

  /* =================== End Tag change ============== */

  /* =================== Category change ============== */

  handlecatChange = (selectedcatOption) => {
    this.setState({ selectedcatOption });

    let fields = this.state.fields;
    fields['categoryID'] = selectedcatOption;
    this.setState({ fields });
  };

  handleCatinputChange = (newValue: string) => {
    if (newValue != '') {
      axios
        .post(`${Auth.getapiurl()}/admin/getCategoryForAutocomplete`, {
          session: Auth.getToken(),
          newValue: newValue,
        })
        .then((res) => {
          const catoptions = res.data;

          if (catoptions.length > 0) {
            this.setState({ catoptions });
          } else {
          }
        });
    }
  };

  handleChangeVendor = (selectedVendorOption) => {
    this.setState({ selectedVendorOption });
    let fields = this.state.fields;
    fields['vendorID'] = selectedVendorOption[0].value;
    this.setState({ fields });
  };

  getSearchVendor = (newValue: string) => {
    let allVendors = [];
    if (newValue != '') {
      axios
        .post(`${Auth.getapiurl()}/admin/getSearchVendor`, {
          session: Auth.getToken(),
          search: newValue,
          pageNumber: 1,
          perpage: 20,
        })
        .then((res) => {
          const vendors = res.data.results;

          for (let i = 0; i < vendors.length; i++) {
            let data = {
              label: vendors[i].name,
              value: vendors[i].ID,
            };
            allVendors.push(data);
          }

          if (allVendors.length > 0) {
            this.setState({ vendors: allVendors });
          } else {
          }
        });
    }
  };

  render() {
    const fields = this.state.fields;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">{this.props.match.params.id ? 'Edit Product' : 'Add Product'}</h3>
                  </div>

                  {this.renderAlert()}
                  <form className="form-horizontal" onSubmit={this.submitProductForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Product Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Product Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Price: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.price ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="number"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Price"
                            name="productPrice"
                            value={this.state.fields.productPrice}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.price}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Barcode: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.barcode ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Barcode"
                            name="barcode"
                            value={this.state.fields.barcode}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.barcode}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">isDeal: </label>

                        <div className="col-sm-6 ">
                          <input
                            type="radio"
                            name="isDeal"
                            value="1"
                            id="f_yes"
                            onChange={this.handleChange}
                            checked={this.state.fields.isDeal == 1}
                          />{' '}
                          <label for="f_yes">Yes</label> &nbsp;&nbsp;
                          <input
                            type="radio"
                            name="isDeal"
                            value="0"
                            id="f_no"
                            checked={this.state.fields.isDeal == 0}
                            onChange={this.handleChange}
                          />{' '}
                          <label for="f_no">No</label>
                        </div>
                      </div>

                      {this.state.fields.isDeal == true && (
                        <>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">Discounted Price: </label>

                            <div className="col-sm-6 ">
                              <input
                                type="number"
                                className="form-control"
                                id="inputproduct"
                                placeholder="Discounted Price"
                                name="discountAmount"
                                value={this.state.fields.discountAmount}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Discounted Percentage: </label>

                            <div className="col-sm-6 ">
                              <input
                                type="number"
                                className="form-control"
                                id="inputproduct"
                                placeholder="Discounted Percentage %"
                                name="discountPercent"
                                value={this.state.fields.discountPercent * 100}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Discount From date: </label>

                            <div className="col-sm-2 ">
                              <DatePicker onChange={this.handleFromdate} value={this.state.searchdate} />
                            </div>

                            <label className="col-sm-2 control-label">Discount To date: </label>

                            <div className="col-sm-2 ">
                              <DatePicker onChange={this.handleTodate} value={this.state.searchtodate} />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Offer Description: </label>

                        <div className="col-sm-6 ">
                          <textarea
                            className="form-control"
                            placeholder="Offer Description"
                            name="offerDesc"
                            value={this.state.fields.offerDesc}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Description: </label>

                        <div className="col-sm-6 ">
                          <textarea
                            className="form-control"
                            placeholder=" Description"
                            name="description"
                            value={this.state.fields.description}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Category: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.categoryID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <Select
                            name="categoryID"
                            placeholder="Category Search.."
                            value={this.state.selectedcatOption}
                            onChange={this.handlecatChange}
                            options={this.state.catoptions}
                            onInputChange={this.handleCatinputChange}
                            isMulti={true}
                          />

                          <div className="errorMsg">{this.state.errors.categoryID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Tag: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.tagID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <Select
                            name="tagID"
                            placeholder="Tag Search.."
                            value={this.state.selectedtagOption}
                            onChange={this.handletagChange}
                            options={this.state.tagoptions}
                            onInputChange={this.handleTaginputChange}
                            isMulti={true}
                          />

                          <div className="errorMsg">{this.state.errors.tagID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Dietary Type: <span className="red"></span>
                        </label>

                        <div className={this.state.errors.dieID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <Select
                            name="dieID"
                            placeholder="Dietry type Search.."
                            value={this.state.selecteddieOption}
                            onChange={this.handledieChange}
                            options={this.state.dieoptions}
                            onInputChange={this.handleDieinputChange}
                            isMulti={true}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Image: </label>

                        <div className="col-sm-4 fileContainer">
                          <img src={this.state.fields.imageURL ? this.state.fields.imageURL : this.state.fileImage} />
                          <label for="files" class="btn btn-success">
                            {this.state.fields.imageURL ? 'Change Image' : 'Select Image'}
                          </label>
                          <input id="files" type="file" onChange={this.fileChangedHandler} />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Product URL: </label>

                        <div className="col-sm-6 ">
                          <input
                            type="text"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Product URL"
                            name="productURL"
                            value={this.state.fields.productURL}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Slug: <span className="red">*</span>{' '}
                        </label>

                        <div className={this.state.errors.slug ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.fields.slug}
                            id="inputproduct"
                            placeholder="Enter slug"
                            name="slug"
                            value={this.state.fields.slug}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Vendor: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.tagID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <Select
                            name="vendor"
                            placeholder="Search for Vendor.."
                            value={this.state.selectedVendorOption}
                            onChange={this.handleChangeVendor}
                            options={this.state.vendors}
                            onInputChange={this.getSearchVendor}
                            isMulti={true}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Product Type: </label>

                        <div className="col-sm-6 ">
                          <select name="productType" className="form-control" onChange={this.handleChange}>
                            <option value="">--- Select ---</option>
                            <option value="simple" selected={this.state.fields.productType == 'simple'}>
                              Simple
                            </option>
                            <option value="variable" selected={this.state.fields.productType == 'variable'}>
                              Variable
                            </option>
                            <option value="bundle" selected={this.state.fields.productType == 'bundle'}>
                              Bundle
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">In Stock: </label>

                        <div className="col-sm-6">
                          <select name="in_stock" className="form-control" onChange={this.handleChange}>
                            <option value="">--- Select ---</option>
                            <option value="yes" selected={this.state.fields.in_stock == 'yes'}>
                              Yes
                            </option>
                            <option value="no" selected={this.state.fields.in_stock == 'no'}>
                              No
                            </option>
                          </select>
                          <div className="errorMsg">{this.state.errors.in_stock}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Weight: </label>

                        <div className="col-sm-6 ">
                          <input
                            type="number"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Weight"
                            name="weight"
                            value={this.state.fields.weight}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Length: </label>

                        <div className="col-sm-6 ">
                          <input
                            type="number"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Length"
                            name="length"
                            value={this.state.fields.length}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Width: </label>

                        <div className="col-sm-6 ">
                          <input
                            type="number"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Width"
                            name="width"
                            value={this.state.fields.width}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Height: </label>

                        <div className="col-sm-6 ">
                          <input
                            type="number"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Height"
                            name="height"
                            value={this.state.fields.height}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Visibility: </label>

                        <div className="col-sm-6 ">
                          <input
                            type="radio"
                            name="visibility"
                            value="1"
                            id="v_yes"
                            onChange={this.handleChange}
                            checked={this.state.fields.visibility == 1}
                          />{' '}
                          <label for="v_yes">Yes</label> &nbsp;&nbsp;
                          <input
                            type="radio"
                            name="visibility"
                            value="0"
                            id="v_no"
                            checked={this.state.fields.visibility == 0}
                            onChange={this.handleChange}
                          />{' '}
                          <label for="v_no">No</label>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Featured: </label>

                        <div className="col-sm-6 ">
                          <input
                            type="radio"
                            name="featured"
                            value="1"
                            id="f_yes"
                            onChange={this.handleChange}
                            checked={this.state.fields.featured == 1}
                          />{' '}
                          <label for="f_yes">Yes</label> &nbsp;&nbsp;
                          <input
                            type="radio"
                            name="featured"
                            value="0"
                            id="f_no"
                            checked={this.state.fields.featured == 0}
                            onChange={this.handleChange}
                          />{' '}
                          <label for="f_no">No</label>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/product">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ProductEdit;
