import axios from 'axios';
import store from 'store';

class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser(token) {
    localStorage.setItem('token', token);
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return localStorage.getItem('token') !== null;
  }

  static isnotUserAuthenticated() {
    return localStorage.getItem('token') == null;
  }

  static siteMail() {
    return process.env.REACT_SITE_EMAIL;
  }

  static siteName() {
    return process.env.REACT_SITE_NAME;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static getapiurl() {
    return process.env.REACT_APP_API_URL;
  }

  static getSiteUrl() {
    return process.env.REACT_APP_SITE_URL;
  }

  static getBasename() {
    return '';
    //return '/onecart/frontend';
  }

  static Itemperpagecount() {
    return 20;
  }

  static imageCheck(src) {
    if (src == null) {
      return this.getapiurl() + '/noimage.png';
    } else {
      const http_array = src.split('http');

      if (http_array.length > 1) {
        return src;
      } else {
        return this.getapiurl() + '/' + src;
      }
    }
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    return store.get('id_token');
  }

  static check_auth() {
    if (store.get('id_token')) {
      axios
        .post(`${this.getapiurl()}/admin/getUser`, {
          session: store.get('id_token'),
        })
        .then((res) => {
          if (JSON.stringify(res.data.fname)) {
            store.set('userdata', JSON.stringify(res.data));
          } else {
            window.location = `${(window.location = window.location.origin)}/login`;
            localStorage.clear();
          }
        });
    } else {
      localStorage.clear();
    }
  }

  static Mailsend(json_array) {
    const http = require('https');
    const options = {
      method: 'POST',
      hostname: 'api.sendgrid.com',
      port: null,
      path: '/v3/mail/send',
      headers: {
        authorization: 'Bearer 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',
        'content-type': 'application/json',
      },
    };

    const req = http.request(options, function (res) {
      const chunks = [];
      res.on('data', function (chunk) {
        chunks.push(chunk);
      });

      res.on('end', function () {
        const body = Buffer.concat(chunks);
        console.log(body.toString());
      });
    });

    req.write(JSON.stringify(json_array));
    req.end();
  }
}

export default Auth;
