import ReactImageFallback from 'react-image-fallback';
import { Redirect, Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import React, { Component } from 'react';
import Auth from '../modules/Auth';
import Loader from 'react-loader';
import Moment from 'react-moment';
import Header from '../Header';
import axios from 'axios';

class Deal extends Component {
  constructor(props) {
    super(props);

    this.getDeals(1, Auth.Itemperpagecount());

    this.state = {
      pageOfItems: [],
      deals: [],
      search: '',
      status_msg: '',
      showRecords: false,
      loaded: false,
      activePage: 1,
      totalRecords: Auth.Itemperpagecount(),
      stype: '',
      message: '',
      restorestatus: false,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);
  }

  handlePageChange(pageNumber) {
    const perpage = Auth.Itemperpagecount();

    if (this.state.stype == 1) this.getSearch(pageNumber, perpage);
    else this.getDeals(pageNumber, perpage);

    this.setState({ activePage: pageNumber });
  }

  getDeals(pageNumber, perpage) {
    axios
      .post(`${Auth.getapiurl()}/admin/getAllDeals`, {
        session: Auth.getToken(),
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        this.setState({ loaded: true });
        const deals = res.data.results;
        const totalRecords = res.data.records;
        this.setState({ deals, totalRecords, showRecords: true });
      });
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems });
  }

  handleAddNew() {
    this.props.history.push('/deal-add');
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeDeal`, {
        session: Auth.getToken(),
        deal_id: itemId,
      })
      .then((res) => {
        if (res.data.status == 200) {
          this.getDeals(1, Auth.Itemperpagecount());
        }
      });
  };

  getSearch = (pageNumber, perpage) => {
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchAllDeals`, {
        session: Auth.getToken(),
        search: this.state.search,
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        if (res.data.status == 400) {
          this.setState({ status_msg: res.data.error, showRecords: false });
        } else {
          const deals = res.data.results;
          const totalRecords = res.data.records;
          this.setState({ deals, totalRecords, showRecords: true, stype: 1 });
        }
      });
  };

  handleSearchChange = (event) => {
    if (event.key === 'Enter') {
      this.setState(
        {
          search: event.target.value,
        },
        () => {
          if (this.state.search && this.state.search.length > 1) {
            this.getSearch(1, Auth.Itemperpagecount());
          } else {
            this.setState({ showRecords: true });
            this.getDeals(1, Auth.Itemperpagecount());
          }
        },
      );
    }
  };

  render() {
    let paginate;
    if (this.state.totalRecords > Auth.Itemperpagecount()) {
      paginate = (
        <Pagination
          prevPageText="Previous"
          nextPageText="Next"
          firstPageText="First"
          lastPageText="Last"
          activePage={this.state.activePage}
          itemsCountPerPage={Auth.Itemperpagecount()}
          totalItemsCount={this.state.totalRecords}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange}
        />
      );
    }

    if (!Auth.getToken()) return <Redirect to="/login" />;

    const showRecords = this.state.showRecords;
    const status_msg = this.state.status_msg;

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-4">
                      <h3 className="box-title">
                        <i className="fa fa-gift" />
                        {` Deals`}
                      </h3>
                    </div>
                    <div className="col-md-4 text-right">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here.."
                        onKeyDown={this.handleSearchChange}
                      />
                    </div>
                    <div className="col-md-2 text-right">
                      <button className="btn btn-success" onClick={this.handleAddNew}>
                        <i className="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  </div>

                  <div className="box-body">
                    {this.state.restorestatus == true && (
                      <div className="alert alert-success">{this.state.message}</div>
                    )}
                    <div className="row">
                      {showRecords ? (
                        <div className="col-md-12">
                          <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <td>Deal ID</td>
                                <td>Product ID</td>
                                <td style={{ width: '25%' }}>Product Name</td>
                                <td>Price</td>
                                <td>Discount</td>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Quantity</td>
                                <td>Is Deal</td>
                                <td>Image</td>
                                <td style={{ textAlign: 'center' }}>Actions</td>
                              </tr>
                            </thead>

                            <tfoot className="thead-dark">
                              <tr>
                                <td>Deal ID</td>
                                <td>Product ID</td>
                                <td style={{ width: '25%' }}>Product Name</td>
                                <td>Price</td>
                                <td>Discount</td>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Quantity</td>
                                <td>Is Deal</td>
                                <td>Image</td>
                                <td style={{ textAlign: 'center' }}>Actions</td>
                              </tr>
                            </tfoot>

                            <tbody>
                              {this.state.deals.map((deal) => {
                                return (
                                <tr key={deal.id}>
                                  <td>
                                    <b>{deal.id}</b>
                                  </td>
                                  <td>{deal.productID}</td>
                                  <td>
                                    {deal.productName}
                                    <br />
                                    <b>({deal.productVendor})</b>
                                  </td>
                                  <td>
                                    <b>{`R ${deal.productPrice.toFixed(2)}`}</b>
                                  </td>
                                  <td>
                                    <b>
                                      {deal.discountType === 'fixedAmount'
                                        ? `R ${deal.discountAmount.toFixed(2)}`
                                        : `${(deal.discountAmount * 100).toFixed(2)}%`}
                                    </b>
                                  </td>
                                  <td>
                                    <Moment format="DD-MMM-YYYY">{deal.dateStart}</Moment>
                                  </td>
                                  <td>
                                    <Moment format="DD-MMM-YYYY">{deal.dateEnd}</Moment>
                                  </td>
                                  <td>{deal.minQty}</td>
                                  <td>{deal.isDeal ? 'YES' : 'NO'}</td>
                                  <td>
                                    <ReactImageFallback
                                      src={Auth.imageCheck(deal.productImage)}
                                      fallbackImage={Auth.getapiurl() + '/noimage.png'}
                                      initialImage={Auth.getapiurl() + '/loading.gif'}
                                      alt="Product Image"
                                      className="my-image"
                                    />
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <Link to={'/dealview/' + deal.id} className="btn btn-info leftside">
                                      <i className="fa fa-eye"></i>{' '}
                                    </Link>
                                    <Link to={'/deal-edit/' + deal.id} className="btn btn-info leftside">
                                      <i className="fa fa-edit"></i>
                                    </Link>

                                    <button
                                      type="submit"
                                      className="btn btn-danger leftside"
                                      onClick={() => {
                                        if (window.confirm('Do you want to move item ?')) this.handleDelete(deal.id);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              )})}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <td>Deal ID</td>
                                <td>Product ID</td>
                                <td style={{ width: '25%' }}>Product Name</td>
                                <td>Price</td>
                                <td>Discount</td>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Quantity</td>
                                <td>Is Deal</td>
                                <td>Image</td>
                                <td style={{ textAlign: 'center' }}>Actions</td>
                              </tr>
                            </thead>

                            <tfoot className="thead-dark">
                              <tr>
                                <td>Deal ID</td>
                                <td>Product ID</td>
                                <td style={{ width: '25%' }}>Product Name</td>
                                <td>Price</td>
                                <td>Discount</td>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Quantity</td>
                                <td>Is Deal</td>
                                <td>Image</td>
                                <td style={{ textAlign: 'center' }}>Actions</td>
                              </tr>
                            </tfoot>
                            <tbody>
                              <tr>
                                <td>{status_msg}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {paginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Deal;
