import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import store from 'store';
import { AuthConsumer } from './AuthContext';
import Auth from './modules/Auth';

class Login extends Component {
  static contextTypes = {
    encrypt: PropTypes.func.isRequired,
    decrypt: PropTypes.func.isRequired,
  };

  state = {
    email: '',
    password: '',
    errorMessage: '',
  };

  handleemailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handlepasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`${Auth.getapiurl()}/admin/login`, {
        email: this.state.email,
        password: this.state.password,
      })
      .then((res) => {
        if (res.data.status == 203) {
          this.setState({ errorMessage: res.data.error });
        } else {
          //localStorage.setItem('id_token', res.data.session);

          axios.post(`${Auth.getapiurl()}/admin/getUser`, { session: res.data.session }).then((res_user) => {
            /*const {
            encrypt,
            decrypt,
            } = this.context;


            let encryptedText;
            encryptedText = encrypt(res_user.data);
            console.log(encryptedText);*/

            store.set('id_token', res.data.session);
            store.set('userdata', JSON.stringify(res_user.data));
            window.location.href = '/';
          });
        }
      })
      .catch((error) => {
        return { errorMessage: error };
      });
  };

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  render() {
    if (store.get('id_token')) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        {this.renderAlert()}
        <AuthConsumer>
          {({ login }) => (
            <div className="login-box">
              <div className="login-logo">
                <i className="fa fa-lock"></i>
                <b>OneCart</b>
              </div>
              <div className="login-box-body">
                <p class="login-box-msg">Sign in to start your session</p>
                <form onSubmit={this.handleSubmit} className="form-horizontal">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      required
                      id="email"
                      name="email"
                      onChange={this.handleemailChange}
                      placeholder="Email"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      required
                      id="password"
                      name="password"
                      onChange={this.handlepasswordChange}
                      placeholder="Password"
                    />
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary" onClick={login}>
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </AuthConsumer>
      </div>
    );
  }
}

export default Login;
