import React, { Fragment } from 'react';

const OrderReceipt = (props) => {
  const renderItems = (items, itemName) => {
    return (
      <div className="col-md-12">
        <table className="table">
          <thead>
            <tr
              style={
                itemName === 'Substituted Items'
                  ? { borderBottomStyle: 'solid', borderBottomColor: 'orange' }
                  : itemName === 'Out of Stock Items'
                  ? { borderBottomStyle: 'solid', borderBottomColor: 'red' }
                  : itemName === 'Partial Items'
                  ? { borderBottomStyle: 'solid', borderBottomColor: '#6ec5fc' }
                  : { borderBottomStyle: 'solid', borderBottomColor: '#04c404' }
              }
            >
              <td
                className="text-left"
                style={
                  itemName === 'Substituted Items'
                    ? { color: 'orange' }
                    : itemName === 'Out of Stock Items'
                    ? { color: 'red' }
                    : itemName === 'Partial Items'
                    ? { color: '#6ec5fc' }
                    : { color: '#04c404' }
                }
              >
                <b>{itemName}</b>
              </td>
              <td className="text-right"></td>
              {(itemName === 'Substituted Items' || itemName === 'Partial Items') && <td className="text-right"></td>}
              <td
                className="text-right"
                style={
                  itemName === 'Substituted Items'
                    ? { color: 'orange' }
                    : itemName === 'Out of Stock Items'
                    ? { color: 'red' }
                    : itemName === 'Partial Items'
                    ? { color: '#6ec5fc' }
                    : { color: '#04c404' }
                }
              >
                <b>{items.count}</b>
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <h6>
                  <b>Product</b>
                </h6>
              </td>
              {itemName !== 'Substituted Items' && itemName !== 'Partial Items' && (
                <td className="text-right">
                  <h6>
                    <b>Qty</b>
                  </h6>
                </td>
              )}
              {(itemName === 'Substituted Items' || itemName === 'Partial Items') && (
                <td className="text-right">
                  <h6>
                    <b>Ord</b>
                  </h6>
                </td>
              )}
              {(itemName === 'Substituted Items' || itemName === 'Partial Items') && (
                <td className="text-right">
                  <h6>
                    <b>Supp</b>
                  </h6>
                </td>
              )}
              <td className="text-right">
                <h6>
                  <b>Total</b>
                </h6>
              </td>
            </tr>
          </thead>
          <tbody>
            {items.details.map((product, i) => (
              <Fragment key={i}>
                <tr>
                  <td className="text-left">
                    {product.name}{' '}
                    {itemName === 'Substituted Items' && <span style={{ color: 'orange' }}> - Substituted with:</span>}
                  </td>
                  {(itemName === 'Out of Stock Items' || itemName === 'Found Items') && (
                    <td className="text-right">{product.qty}</td>
                  )}
                  {(itemName === 'Substituted Items' || itemName === 'Partial Items') && (
                    <td className="text-right">{product.ordered}</td>
                  )}
                  {itemName === 'Substituted Items' && <td className="text-right">0</td>}
                  {itemName === 'Partial Items' && <td className="text-right">{product.supplied}</td>}
                  <td className="text-right">
                    {props.currency} {itemName === 'Out of Stock Items' ? product.credit : product.total}
                  </td>
                </tr>
                {itemName === 'Substituted Items' && (
                  <tr key={i}>
                    <td className="text-left" style={{ color: 'orange' }}>
                      {product.subname}
                    </td>
                    <td className="text-left"></td>
                    <td className="text-right" style={{ color: 'orange' }}>
                      {product.supplied}
                    </td>
                    <td className="text-right" style={{ color: 'orange' }}>
                      {props.currency} {product.subTotal}
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      {props.vendors && props.vendors.length > 0 ? (
        <div className="col-md-12">
          {props.vendors.map((item, i) => (
            <div key={i}>
              <div className="row">
                <div className="col-md-8 text-left">
                  <h4>
                    <b>{item.vendorName}</b>
                  </h4>
                </div>
                <div className="col-md-4 text-right">
                  <h4>
                    <b>
                      {props.currency} {item.total}
                    </b>
                  </h4>
                </div>
              </div>
              {item.foundItems && item.foundItems.count > 0 && renderItems(item.foundItems, 'Found Items')}
              {item.partialItems && item.partialItems.count > 0 && renderItems(item.partialItems, 'Partial Items')}
              {item.subItems && item.subItems.count > 0 && renderItems(item.subItems, 'Substituted Items')}
              {item.oosItems && item.oosItems.count > 0 && renderItems(item.oosItems, 'Out of Stock Items')}
            </div>
          ))}
          {props.subtotal && (
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>
                  <b>Subtotal : </b>
                </h4>
              </div>
              <div className="col-md-4 text-right">
                <h4>
                  <b>
                    {props.currency} {props.subtotal}
                  </b>
                </h4>
              </div>
            </div>
          )}
          {props.delivery && (
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Delivery Fee :</h4>
              </div>
              <div className="col-md-4 text-right">
                <h4>
                  {props.currency} {props.delivery}
                </h4>
              </div>
            </div>
          )}
          {props.concierge && (
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Concierge Fee :</h4>
              </div>
              <div className="col-md-4 text-right">
                <h4>
                  {props.currency} {props.concierge}
                </h4>
              </div>
            </div>
          )}
          {props.food_forward && (
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Food Forward :</h4>
              </div>
              <div className="col-md-4 text-right">
                <h4>
                  {props.currency} {props.food_forward}
                </h4>
              </div>
            </div>
          )}
          {props.tips && (
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>Tip :</h4>
              </div>
              <div className="col-md-4 text-right">
                <h4>
                  {props.currency} {props.tips}
                </h4>
              </div>
            </div>
          )}
          {props.total && (
            <div className="row">
              <div className="col-md-8 text-left">
                <h4>
                  <b>Total : </b>
                </h4>
              </div>
              <div className="col-md-4 text-right">
                <h4>
                  <b>
                    {props.currency} {props.total}
                  </b>
                </h4>
              </div>
            </div>
          )}

          <div className="row">
            {parseInt(props.creditTot) > 0 && (
              <>
                {props.refund === 1 && (
                  <>
                    <div className="col-md-8 text-left">
                      <h4>
                        <b>Credited to onecart wallet: </b>
                      </h4>
                    </div>
                    <div className="col-md-4 text-right">
                      <h4>
                        <b>
                          {props.currency} {props.creditTot}
                        </b>
                      </h4>
                    </div>
                  </>
                )}
                {props.refund === 0 && (
                  <>
                    <div className="col-md-8 text-left">
                      <h4>
                        <b>Refund to bank account: </b>
                      </h4>
                    </div>
                    <div className="col-md-4 text-right">
                      <h4>
                        <b>
                          {props.currency} {props.creditTot}
                        </b>
                      </h4>
                    </div>
                  </>
                )}
              </>
            )}
            {parseInt(props.creditTot) < 0 && (
              <>
                <div className="col-md-8 text-left">
                  <h4>
                    <b>Extra debit for substitutes: </b>
                  </h4>
                </div>
                <div className="col-md-4 text-right">
                  <h4>
                    <b>{-1 * props.creditTot}</b>
                  </h4>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            height: '380px',
            alignItems: 'center',
          }}
        >
          <h4 style={{ color: 'grey' }}>
            <b>Order isn`t completed yet!</b>
          </h4>
        </div>
      )}
    </>
  );
};

export default OrderReceipt;
