import $ from 'jquery';
import React from 'react';
// import { AuthConsumer } from './AuthContext'
import { Link, withRouter } from 'react-router-dom';
import store from 'store';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      users: [],
    };
  }

  componentDidMount() {
    this.setState({
      user: JSON.parse(store.get('userdata')),
      users: { loading: true },
    });

    $(document).ready(() => {
      $('ul li.treeview').click(function () {
        // $('ul li.treeview ul.treeview-menu').hide('1000');
        $(this).find('ul.treeview-menu').slideToggle();
      });
    });
  }

  getActive(ItemArray) {
    //  console.log(ItemArray.indexOf(this.props.location.pathname));
    const path = this.props.location.pathname.split('/')[1];

    return ItemArray.indexOf(path) >= 0 ? 'treeview-menu active-menu' : 'treeview-menu';
  }

  getActiveColor(ItemArray) {
    //  console.log(ItemArray.indexOf(this.props.location.pathname));
    const path = this.props.location.pathname.split('/')[1];

    return ItemArray.indexOf(path) >= 0 ? 'treeview menu-open' : 'treeview';
  }

  getActiveMenu(ItemArray) {
    //  console.log(ItemArray.indexOf(this.props.location.pathname));
    const path = this.props.location.pathname.split('/')[1];

    return ItemArray.indexOf(path) >= 0 ? 'active' : '';
  }

  render() {
    // console.log(this.props.location.pathname);
    const userItems = [
      'user',
      'useradd',
      'useredit',
      'role',
      'permission',
      'roleadd',
      'roleedit',
      'role-access',
      'permissionAdd',
      'permissionEdit',
    ];
    const vendorItems = [
      'vendor',
      'location',
      'mall',
      'store',
      'vendoredit',
      'vendoradd',
      'locationadd',
      'locationedit',
      'malledit',
      'malladd',
      'storeadd',
      'storeedit',
    ];
    const productItems = [
      'category',
      'tag',
      'product',
      'categoryedit',
      'categoryAdd',
      'tagadd',
      'tagedit',
      'product-add',
      'product-edit',
      'product-stock',
      'product-stock-add',
      'product-stock-edit',
      'dietary-type',
      'dietary-type-add',
      'dietary-type-edit',
      'product-replacement',
      'product-replacement-add',
      'product-replacement-edit',
      'product-dietary',
      'product-dietary-add',
      'product-dietary-edit',
      'import-export',
      'trashproducts',
    ];
    const customerItems = [
      'customers',
      'customer-role',
      'customer-lcard',
      'customer-addresstype',
      'customeradd',
      'customeredit',
      'customer-lcardadd',
      'customer-lcardedit',
      'customer-addresstypeedit',
      'customer-addresstypeadd',
      'customer-roleadd',
      'customer-roleedit',
      'listaddress',
      'editaddress',
      'address',
      'bilinglistaddress',
      'bilingaddress',
      'bilingeditaddress',
      'cards',
      'addcard',
      'editcard',
      'assignrole',
      'assignroleadd',
      'assignroleedit',
      'wallet',
      'wallet-balance',
    ];

    const recipeItems = ['recipe', 'recipeadd', 'recipeedit', 'recipeview'];
    const dealItems = ['deals', 'dealadd', 'dealedit', 'dealview'];

    const ordersItems = ['orders', 'order-view', 'order-edit', 'orderstatus', 'orderstatus-add', 'orderstatus-edit'];
    // const couponsItems = ['coupons', 'coupons-add', 'coupons-edit', 'coupons-exclude', 'couponsexadd', 'couponsexedit'];
    const paymentsItems = ['payment-method', 'payment-method-add', 'payment-method-edit'];
    const pagesItems = ['cmspages', 'cmspage-edit', 'cmspage-add', 'slider', 'slider-add', 'slider-edit'];
    const referitems = ['referfriend', 'referfriend-list', 'referfriend-setting'];

    const { user } = this.state;
    return (
      <div>
        <aside className="main-sidebar">
          <section className="sidebar">
            <ul className="sidebar-menu" data-widget="tree">
              <li className="header">MAIN NAVIGATION</li>
              <li>
                <Link to="/">
                  <i className="fa fa-dashboard" />
                  <span>Dashboard</span>
                </Link>
              </li>

              {/* <li className={this.getActiveColor(userItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-users"></i>
                  <span> User Section</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                  </span>
                </a>

                <ul className={this.getActive(userItems)}>
                  <li className={this.getActiveMenu(['user', 'useradd', 'useredit'])}>
                    <Link to="/user">
                      <i className="fa fa-user"></i>
                      <span>Users</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['role', 'roleadd', 'roleedit'])}>
                    <Link to="/role">
                      <i className="fa fa-tasks"></i>
                      <span>Roles</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['permission', 'permissionAdd', 'permissionEdit'])}>
                    <Link to="/permission">
                      <i className="fa fa-lock"></i>
                      <span>Permission</span>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li className={this.getActiveColor(vendorItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-industry" />
                  <span> Vendor Section</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(vendorItems)}>
                  <li className={this.getActiveMenu(['vendor', 'vendoradd', 'vendoredit'])}>
                    <Link to="/vendor">
                      <i className="fa fa-industry" />
                      <span>Vendor</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['location', 'locationadd', 'locationedit'])}>
                    <Link to="/location">
                      <i className="fa fa-map-marker" />
                      <span>Location</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['mall', 'malladd', 'malledit'])}>
                    <Link to="/mall">
                      <i className="fa fa-shopping-bag" />
                      <span>Mall</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['store', 'storeadd', 'storeedit'])}>
                    <Link to="/store">
                      <i className="fa fa-shopping-cart" />
                      <span>Store</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={this.getActiveColor(productItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-product-hunt" />
                  <span> Product Section</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(productItems)}>
                  <li className={this.getActiveMenu(['category', 'categoryAdd', 'categoryedit'])}>
                    <Link to="/category">
                      <i className="fa fa-list-alt" />
                      <span>Categories</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['tag', 'tagadd', 'tagedit'])}>
                    <Link to="/tag">
                      <i className="fa fa-tag" />
                      <span>Tag</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['dietary-type', 'dietary-type-add', 'dietary-type-edit'])}>
                    <Link to="/dietary-type">
                      <i className="fa fa-cutlery" />
                      <span>Dietary Type</span>
                    </Link>
                  </li>

                  <li
                    className={this.getActiveMenu([
                      'product',
                      'product-add',
                      'product-edit',
                      'product-stock',
                      'product-stock-add',
                      'product-stock-edit',
                      'product-replacement',
                      'product-replacement-add',
                      'product-replacement-edit',
                      'product-dietary',
                      'product-dietary-add',
                      'product-dietary-edit',
                    ])}
                  >
                    <Link to="/product">
                      <i className="fa fa-product-hunt" />
                      <span>Product</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['import-export'])}>
                    <Link to="/import-export">
                      <i className="fa fa-download" />
                      <span>Import/Export</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={this.getActiveColor(customerItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-user" />
                  <span> Customer Section</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(customerItems)}>
                  <li className={this.getActiveMenu(['customers', 'customeradd', 'customeredit', 'listaddress'])}>
                    <Link to="/customers">
                      <i className="fa fa-user" />
                      <span>Customers</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['customer-role', 'customer-roleadd', 'customer-roleedit'])}>
                    <Link to="/customer-role">
                      <i className="fa fa-tasks" />
                      <span>Roles</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['customer-lcard', 'customer-lcardadd', 'customer-lcardedit'])}>
                    <Link to="/customer-lcard">
                      <i className="fa fa-id-card" />
                      <span>Loyalty Card Type</span>
                    </Link>
                  </li>

                  <li
                    className={this.getActiveMenu([
                      'customer-addresstype',
                      'customer-addresstypeadd',
                      'customer-addresstypeedit',
                    ])}
                  >
                    <Link to="/customer-addresstype">
                      <i className="fa fa-address-card-o" />
                      <span>Address Types</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['wallet'])}>
                    <Link to="/wallet">
                      <i className="fa fa-address-card-o" />
                      <span>Wallet</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={this.getActiveColor(recipeItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-registered" />
                  {' '}
                  <span> Recipe Section </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(recipeItems)}>
                  <li className={this.getActiveMenu(['recipe', 'recipeadd', 'recipeedit', 'recipeview'])}>
                    <Link to="/recipe">
                      <i className="fa fa-cutlery" />
                      {' '}
                      <span>Recipe</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={this.getActiveColor(dealItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-gift" />
                  {' '}
                  <span> Deal Section </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(dealItems)}>
                  <li className={this.getActiveMenu(dealItems)}>
                    <Link to="/deals">
                      <i className="fa fa-gift" />
                      {' '}
                      <span>Deals</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={this.getActiveColor(ordersItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-shopping-cart" />
                  <span> Order Section</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(ordersItems)}>
                  <li className={this.getActiveMenu(['orders'])}>
                    <Link to="/orders">
                      <i className="fa fa-list-ul" />
                      <span>Orders</span>
                    </Link>
                  </li>
                  <li className={this.getActiveMenu(['orderstatus'])}>
                    <Link to="/orderstatus">
                      <i className="fa fa-list-ul" />
                      <span>Orders Status</span>
                    </Link>
                  </li>
                </ul>
              </li>

              {/* <li className={this.getActiveColor(couponsItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-creative-commons"></i>
                  <span> Coupon Section</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                  </span>
                </a>

                <ul className={this.getActive(couponsItems)}>
                  <li className={this.getActiveMenu(['coupons', 'coupons-add', 'coupons-edit'])}>
                    <Link to="/coupons">
                      <i className="fa fa-creative-commons"></i>
                      <span>Coupons</span>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li className={this.getActiveColor(referitems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-paper-plane" aria-hidden="true" />
                  <span> Refer A Friend</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(referitems)}>
                  <li className={this.getActiveMenu(['referfriend'])}>
                    <Link to="/referfriend-setting">
                      <i className="fa fa-paper-plane" aria-hidden="true" />
                      <span>Setting</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={this.getActiveColor(paymentsItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-money" />
                  <span> Payment Section</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(paymentsItems)}>
                  <li className={this.getActiveMenu(['payment-method', 'payment-method-add', 'payment-method-edit'])}>
                    <Link to="/payment-method">
                      <i className="fa fa-money" />
                      <span>Payments</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={this.getActiveColor(pagesItems)}>
                <a href="javascript:void(0);">
                  <i className="fa fa-building" />
                  <span> CMS Management</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>

                <ul className={this.getActive(pagesItems)}>
                  <li className={this.getActiveMenu(['cmspages', 'cmspage-edit', 'cmspage-add'])}>
                    <Link to="/cmspages">
                      <i className="fa fa-html5" />
                      <span>Pages</span>
                    </Link>
                  </li>

                  <li className={this.getActiveMenu(['slider', 'slider-add', 'slider-edit'])}>
                    <Link to="/slider">
                      <i className="fa fa-tasks" />
                      <span>Slider</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </aside>
      </div>
    );
  }
}

export default withRouter(Sidebar);
