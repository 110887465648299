import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import OrderNote from './OrderNote';
import OrderReceipt from './OrderReceipt';
import OrderTransactions from './OrderTransactions';
import ShoppingList from './ShoppingList';

const OrderTabs = (props) => {
  return (
    <Tabs selectedIndex={props.tabIndex} onSelect={props.onSelect}>
      <TabList>
        <Tab>Shopping List</Tab>
        <Tab>Receipt</Tab>
        <Tab>Transactions</Tab>
        <Tab>Notes</Tab>
        
      </TabList>
      <TabPanel>
        <ShoppingList
          delivery_charges={props.delivery_charges}
          food_forward={props.food_forward}
          tips={props.tips}
          currency={props.currency}
          service_fee={props.service_fee}
          sub_total={props.sub_total}
          coupon_details1={props.coupon_details1}
          coupon_details2={props.coupon_details2}
          fields={props.fields}
          persons={props.persons}
        />
      </TabPanel>
      <TabPanel>
        <OrderReceipt
          currency={props.currency}
          creditTot={props.creditTot}
          refund={props.refund}
          total={props.total}
          concierge={props.concierge}
          delivery={props.delivery}
          food_forward={props.food_forward}
          tips={props.tips}
          subtotal={props.subtotal}
          vendors={props.vendors}
        />
      </TabPanel>
      <TabPanel>
        <OrderTransactions currency={props.currency} transactions={props.transactions} />
      </TabPanel>
      <TabPanel>
        <OrderNote
          submituserRegistrationForm={props.submituserRegistrationForm}
          handleChange={props.handleChange}
          order_note_table={props.order_note_table}
          errors={props.errors}
        />
      </TabPanel>
    </Tabs>
  );
};

export default OrderTabs;
