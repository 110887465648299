import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import Pagination from './../../component/Pagination';
import Header from './../../Header';
import Auth from './../../modules/Auth';

console.disableYellowBox = true;

class Order extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      status: '',
      searchdate: new Date(),
      mindate: new Date(),
      options: [],
      tocustID: '',
      selectedOption: '',
      isClearable: true,
      order_status: [],
      balance_customer: { balance: 0 },
    };

    this.getsearchbycustomer();
    this.getBalancebyadminsearch();
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/deleteOrder`, {
        session: Auth.getToken(),
        orderID: itemId,
      })
      .then((response) => {
        this.getorders();
      });
  };

  accesspermission = (roleid) => {
    this.props.history.push('/role-access/permission/' + roleid);
  };

  getsearchbydate = () => {
    axios
      .post(`${Auth.getapiurl()}/admin/getAllTransactionsbyadmin`, {
        session: Auth.getToken(),
        search: this.state.searchdate,
        searchtype: 'dateseacrh',
      })
      .then((res) => {
        const persons = res.data;

        console.log(persons);

        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ status: '204' });
        }
      });
  };

  getsearchbycustomer = () => {
    let search;
    this.state.tocustID ? (search = this.state.tocustID) : (search = this.props.match.params.id);

    axios
      .post(`${Auth.getapiurl()}/admin/getAllTransactionsbyadmin`, { session: Auth.getToken(), search: search })
      .then((res) => {
        const persons = res.data;

        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ status: '204' });
        }
      });
  };

  getBalancebyadminsearch() {
    let search;
    this.state.tocustID ? (search = this.state.tocustID) : (search = this.props.match.params.id);

    axios
      .post(`${Auth.getapiurl()}/admin/getBalancebyadminsearch`, { session: Auth.getToken(), search: search })
      .then((res) => {
        const balance_customer = res.data.details[0];
        this.setState({ balance_customer: balance_customer ? balance_customer : { balance: 0 } });
      });
  }

  handleCustomerChange = (newValue: string) => {
    if (newValue != '') {
      axios
        .post(`${Auth.getapiurl()}/admin/getCustomerForAutocomplete`, {
          session: Auth.getToken(),
          newValue: newValue,
        })
        .then((res) => {
          const options = res.data;

          if (options.length > 0) {
            this.setState({ options });
          } else {
          }
        });
    }
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({ selectedOption });

    if (selectedOption) {
      const tocustID_value = selectedOption.value;
      this.setState(
        {
          tocustID: tocustID_value,
        },
        () => {
          if (this.state.tocustID) {
            this.getsearchbycustomer();
            this.getBalancebyadminsearch();
          } else {
            this.setState({ status: '204' });
            this.getorders();
          }
        }
      );
    }
  };

  handleCalanderChange = (searchdate) => {
    this.setState(
      {
        searchdate: searchdate,
      },
      () => {
        // console.log(this.state.searchdate.toLocaleDateString());
        //console.log(new Intl.DateTimeFormat('en-US').format(this.state.searchdate));
        //this.state.searchdate = new Intl.DateTimeFormat('en-GB').format(this.state.searchdate);

        //this.state.searchdate = new Date(this.state.searchdate);

        this.state.searchdate = moment(this.state.searchdate).format();

        //this.state.searchdate = new Date(this.state.searchdate).toISOString().slice(0,10);

        /*console.log(new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: 'long',
          day: '2-digit'
      }).format(this.state.searchdate));*/

        if (this.state.searchdate) {
          console.log('yes');
          this.getsearchbydate();
        } else {
          console.log('no');
          this.setState({ status: '204' });
          this.getorders();
        }
      }
    );
  };

  handlesearchChange = (event) => {
    this.setState(
      {
        search: event.target.value,
      },
      () => {
        if (this.state.search) {
          this.getsearch();
        } else {
          this.setState({ status: '204' });
          this.getorders();
        }
      }
    );
  };

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const status = this.state.status;
    const status_msg = this.state.status_msg;
    let table;
    if (status == '203') {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Transaction Id</td>
                <td>Amount</td>
                <td>Type</td>
                <td>Date</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Transaction Id</td>
                <td>Amount</td>
                <td>Type</td>
                <td>Date</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>{status_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      table = (
        <div className="col-md-12" id="showresult">
          <h1 align="center">Balance : {this.state.balance_customer.balance}</h1>
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Transaction Id</td>
                <td>Amount</td>
                <td>Type</td>
                <td>Date</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Transaction Id</td>
                <td>Amount</td>
                <td>Type</td>
                <td>Date</td>
              </tr>
            </tfoot>

            <tbody>
              {this.state.pageOfItems.map((item) => (
                <tr key={item.ID}>
                  <td>{item.ID}</td>
                  <td>{item.amount}</td>
                  <td>{item.details}</td>

                  <td>
                    <Moment format="DD/MM/YYYY HH:mm" fromNow>
                      {item.tdate}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination items={this.state.persons} onChangePage={this.onChangePage} />
        </div>
      );
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">Wallet Transactions</h3>
                    </div>
                    <div className="col-md-4 text-right"></div>
                    <div className="col-md-2 text-right"></div>
                  </div>

                  <div className="box-body">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          <i className="fa fa-filter"></i> Filters
                        </h3>
                      </div>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <Select
                                name="tocustID"
                                placeholder="Search by customer"
                                value={this.state.selectedOption}
                                onChange={this.handleSelectedChange}
                                options={this.state.options}
                                isClearable={this.state.isClearable}
                                onInputChange={this.handleCustomerChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="row">
                      {this.state.data}
                      {table}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Order;
