import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

const allvendors = [];

class CategoryAdd extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      category: [],
      vendor: [],
      errorMessage: '',
      status_code: '',
    };

    this.getCategory();
    this.getvendors();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  getvendors() {
    axios.post(`${Auth.getapiurl()}/admin/getVendors`, { session: Auth.getToken() }).then((res) => {
      const vendor = res.data;
      this.setState({ vendor });

      for (var i = 0; i < vendor.length; i++) allvendors[vendor[i].ID] = vendor[i].name;
    });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  validateForm() {
    let fields = this.state.fields;
    console.log(fields);
    let errors = {};
    let formIsValid = true;

    if (!fields['cname']) {
      formIsValid = false;
      errors['cname'] = '*Please enter category name.';
    }

    if (typeof fields['cname'] !== 'undefined') {
      if (!fields['cname'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['cname'] = '*Please enter alphabet characters only.';
      }
    }

    /*if (!fields["cateurl"]) {
        formIsValid = false;
        errors["cateurl"] = "*Please enter category url.";
      }*/

    if (!fields['vendor']) {
      formIsValid = false;
      errors['vendor'] = '*Please select vendor.';
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let parentitem;

      if (this.state.fields.parentcate !== undefined) {
        parentitem = this.state.fields.parentcate;
      } else {
        parentitem = '0';
      }

      axios
        .post(`${Auth.getapiurl()}/admin/addCategory`, {
          name: this.state.fields.cname,
          parentID: parentitem,
          vendorID: this.state.fields.vendor,
          categoryURL: this.state.fields.cateurl,
          session: Auth.getToken()
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/category');
              }.bind(this),
              2000
            );
          }

          //this.props.history.push("/category");
        });
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  getCategory() {
    axios.post(`${Auth.getapiurl()}/admin/getAllCategories`, {}).then((res) => {
      const category = res.data;

      this.setState({ category });
    });
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Category</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Category Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.cname ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputcate"
                            placeholder="Category Name"
                            name="cname"
                            value={this.state.fields.cname}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.cname}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Parent Category:</label>

                        <div className="col-sm-6">
                          <select name="parentcate" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.category.map((catitem) => (
                              <option value={catitem.ID}>
                                {catitem.name} - ({allvendors[catitem.vendorID]})
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Category URL: <span className="red"></span>
                        </label>

                        <div className={this.state.errors.cateurl ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="cateurl"
                            placeholder="Category URL"
                            name="cateurl"
                            value={this.state.fields.cateurl}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.cateurl}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Select Vendor: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.vendor ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="vendor" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.vendor.map((vendoritem) => (
                              <option value={vendoritem.ID}>{vendoritem.name}</option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.vendor}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/category">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CategoryAdd;
