import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from '../Header';
import Auth from '../modules/Auth';

class Refercodeedit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        referralcode: '',
        referorder: '',
        cookietime: '',
        coupontype: '',
        couponamount: '',
        enable: '',
        coupontypeperson: '',
        couponamountperson: '',
        pagesharelink: '',
        fbshare: '',
        twitshare: '',
        whatsappshare: '',
        emailshare: '',
        refercode: '',
      },
      errors: {},
      errorMessage: '',
      status_code: '',
    };
    this.getrefercodebyid();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeapplycart = this.handleChangeapplycart.bind(this);
    this.handleChangeCoupon = this.handleChangeCoupon.bind(this);

    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  handleChangeapplycart(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    if (e.target.checked === true) {
      fields['enable_apply_cart'] = 1;
    } else {
      fields['enable_apply_cart'] = 0;
    }

    this.setState({
      fields,
    });
  }

  handleChangeCoupon(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    if (e.target.checked === true) {
      fields['enable_person_coupon'] = 1;
    } else {
      fields['enable_person_coupon'] = 0;
    }

    this.setState({
      fields,
    });
  }

  getrefercodebyid() {
    axios
      .post(`${Auth.getapiurl()}/admin/getReferCodebyid`, {
        session: Auth.getToken(),
        referid: 1,
      })
      .then((res) => {
        const fields = res.data[0];
        this.setState({ fields });
      });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/updateRefercode`, {
          session: Auth.getToken(),
          referralcode: this.state.fields.referralcode,
          referorder: this.state.fields.referorder,
          cookietime: this.state.fields.cookietime,
          coupontype: this.state.fields.coupontype,
          couponamount: this.state.fields.couponamount,
          enable: this.state.fields.enable_person_coupon,
          enable_apply_cart: this.state.fields.enable_apply_cart,
          cartmsg: this.state.fields.cartmsg,
          coupontypeperson: this.state.fields.coupontypeperson,
          couponamountperson: this.state.fields.couponamountperson,
          pagesharelink: this.state.fields.pagesharelink,
          textbefore: this.state.fields.textbefore,
          fbshare: this.state.fields.fbshare,
          emailsubject: this.state.fields.emailsubject,
          emailheading: this.state.fields.emailheading,
          emailbody: this.state.fields.emailbody,
          referid: 1,
        })

        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/referfriend-setting');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['referorder']) {
      formIsValid = false;
      errors['referorder'] = '*Please enter referorder.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    console.log(this.state.fields);

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Referral Code</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <Tabs defaultIndex={0} onSelect={(index) => console.log(index)}>
                        <TabList>
                          <Tab>General</Tab>
                          <Tab>Coupon Setting</Tab>
                          <Tab>Referred Person Coupon Settings</Tab>

                          <Tab>My Account Page</Tab>
                          <Tab>Share option</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">Minimum referral order: </label>

                            <div className={this.state.errors.referorder ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="number"
                                className="form-control"
                                id="inputrole"
                                placeholder="Minimum referral order"
                                name="referorder"
                                value={this.state.fields.referorder}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.referorder}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Cookie Time: </label>

                            <div className={this.state.errors.cookietime ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="number"
                                className="form-control"
                                id="inputrole"
                                name="cookietime"
                                placeholder="cookies in days"
                                value={this.state.fields.cookietime}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.cookietime}</div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">Coupon Type: </label>

                            <div className={this.state.errors.coupontype ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <select name="coupontype" className="form-control" onChange={this.handleChange}>
                                <option value="F" selected={this.state.fields.coupontype == 'F'}>
                                  Fixed Discount
                                </option>
                                <option value="P" selected={this.state.fields.coupontype == 'P'}>
                                  % Discount
                                </option>
                              </select>
                              <div className="errorMsg">{this.state.errors.coupontype}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Coupon Amount: </label>

                            <div className={this.state.errors.couponamount ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="number"
                                className="form-control"
                                id="inputrole"
                                name="couponamount"
                                placeholder="Discount amount %"
                                value={this.state.fields.couponamount}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.couponamount}</div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">Enable: </label>

                            <div className={this.state.errors.enable_person_coupon ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <lable>
                                <input
                                  type="checkbox"
                                  name="enable_person_coupon"
                                  value="yes"
                                  onChange={this.handleChangeCoupon}
                                  checked={this.state.fields.enable_person_coupon == 1}
                                />{' '}
                                Check to enable. Coupon code will be sent to their email.
                              </lable>

                              <div className="errorMsg">{this.state.errors.enable_person_coupon}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Coupon Type: </label>

                            <div className={this.state.errors.coupontypeperson ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <select name="coupontypeperson" className="form-control" onChange={this.handleChange}>
                                <option value="F" selected={this.state.fields.coupontypeperson == 'F'}>
                                  Fixed Discount
                                </option>
                                <option value="P" selected={this.state.fields.coupontypeperson == 'P'}>
                                  % Discount
                                </option>
                              </select>
                              <div className="errorMsg">{this.state.errors.coupontypeperson}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Coupon Amount: </label>

                            <div className={this.state.errors.couponamountperson ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="number"
                                className="form-control"
                                id="inputrole"
                                name="couponamountperson"
                                placeholder="Discount amount %"
                                value={this.state.fields.couponamountperson}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.couponamountperson}</div>
                            </div>
                          </div>

                          <h3>Referral Coupons applied on fist purchase</h3>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Enable: </label>

                            <div className={this.state.errors.enable_apply_cart ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <lable>
                                <input
                                  type="checkbox"
                                  name="enable_apply_cart"
                                  value="yes"
                                  onChange={this.handleChangeapplycart}
                                  checked={this.state.fields.enable_apply_cart == 1}
                                />{' '}
                                Check to enable. Coupon code will be automatically applied in cart.
                              </lable>

                              <div className="errorMsg">{this.state.errors.enable_apply_cart}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Coupon code : </label>

                            <div className={this.state.errors.referralcode ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="referralcode"
                                placeholder="Discount amount %"
                                value={this.state.fields.referralcode}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.referralcode}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Cart Message: </label>

                            <div className={this.state.errors.cartmsg ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <textarea
                                className="form-control"
                                id="inputrole"
                                name="cartmsg"
                                onChange={this.handleChange}
                              >
                                {this.state.fields.cartmsg}
                              </textarea>

                              <div className="errorMsg">{this.state.errors.cartmsg}</div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">Page share link: </label>

                            <div className={this.state.errors.pagesharelink ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="pagesharelink"
                                placeholder="Page share link"
                                value={this.state.fields.pagesharelink}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.pagesharelink}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Text Before: </label>

                            <div className={this.state.errors.textbefore ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <textarea
                                className="form-control"
                                id="inputrole"
                                name="textbefore"
                                onChange={this.handleChange}
                              >
                                {this.state.fields.textbefore}
                              </textarea>

                              <div className="errorMsg">{this.state.errors.textbefore}</div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">FB,Twiiter & Whatsapp Share Link: </label>

                            <div className={this.state.errors.fbshare ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="fbshare"
                                placeholder="Facebook share link"
                                value={this.state.fields.fbshare}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.fbshare}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Email Subject: </label>

                            <div className={this.state.errors.emailsubject ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="emailsubject"
                                placeholder="Twitter share link"
                                value={this.state.fields.emailsubject}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.emailsubject}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Email heading: </label>

                            <div className={this.state.errors.emailheading ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="emailheading"
                                placeholder="Whatsapp share link"
                                value={this.state.fields.emailheading}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.emailheading}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">Email Body: </label>

                            <div className={this.state.errors.emailbody ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <textarea
                                className="form-control"
                                id="inputrole"
                                name="emailbody"
                                onChange={this.handleChange}
                              >
                                {this.state.fields.emailbody}
                              </textarea>

                              <div className="errorMsg">{this.state.errors.emailbody}</div>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/referfriend-list">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Refercodeedit;
