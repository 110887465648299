import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './../../Header';
import Auth from './../../modules/Auth';

class Cardsedit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
      persons: [],
    };
    this.getlcards();
    this.getLoyaltyCardbyid();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  getlcards() {
    axios.post(`${Auth.getapiurl()}/admin/getlcards`, { session: Auth.getToken() }).then((res) => {
      const persons = res.data;
      //console.log(persons);
      this.setState({ persons });
    });
  }

  getLoyaltyCardbyid() {
    axios
      .post(`${Auth.getapiurl()}/admin/getLoyaltyCardbyid`, {
        session: Auth.getToken(),
        LoyalityID: this.props.match.params.id
      })
      .then((res) => {
        const fields = res.data[0];
        //console.log(fields);
        this.setState({ fields });
      });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/editLoyaltyCardbyadmin`, {
          session: Auth.getToken(),
          loyaltyCardTypeID: this.state.fields.loyaltyCardTypeID,
          cardNumber: this.state.fields.cardNumber,
          loyaltyCardID: this.props.match.params.id,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/cards/' + this.state.fields.customerID);
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['loyaltyCardTypeID']) {
      formIsValid = false;
      errors['loyaltyCardTypeID'] = '*Please select card type.';
    }

    if (!fields['cardNumber']) {
      formIsValid = false;
      errors['cardNumber'] = '*Please enter card number.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  /* handleChange = event => {
     this.setState({ name: event.target.value });
   }

   handleSubmit = event => {
     event.preventDefault();


     axios.post(`http://192.168.1.208:5000/admin/addRole`, { session: store.get('id_token'),name: this.state.name })
       .then(res => {
        this.props.history.push("/role");
       })



   }*/

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Card</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Select CardType: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.loyaltyCardTypeID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="loyaltyCardTypeID" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            {this.state.persons.map((adtype) => (
                              <option value={adtype.ID} selected={this.state.fields.loyaltyCardTypeID == adtype.ID}>
                                {adtype.name}
                              </option>
                            ))}
                          </select>
                          <div className="errorMsg">{this.state.errors.loyaltyCardTypeID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Card Number: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.cardNumber ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="Card Number"
                            name="cardNumber"
                            value={this.state.fields.cardNumber}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.cardNumber}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to={'/cards/' + this.state.fields.customerID}>
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Cardsedit;
