import React, { Component } from 'react';

import axios from 'axios';
import Header from '../Header';
import { Redirect, Link } from 'react-router-dom';
//import Pagination from '../component/Pagination';
import Auth from '../modules/Auth';
import moment from 'moment';
import Loader from 'react-loader';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import Pagination from 'react-js-pagination';

class Order extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
    this.addnewhandleclick = this.addnewhandleclick.bind(this);

    this.getorders(1, Auth.Itemperpagecount());

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      status: '',
      searchdate: new Date(),
      mindate: new Date(),
      options: [],
      tocustID: '',
      selectedOption: '',
      loaded: false,
      isClearable: true,
      order_status: [],
      activePage: 1,
      TotalRecords: Auth.Itemperpagecount(),
      stype: '',
      search_id: '',
      search_date: '',
      search_customer: '',
    };

    this.getorderstatus();
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    const perpage = Auth.Itemperpagecount();

    if (this.state.stype == 1) {
      this.getsearchbystatus(pageNumber, perpage);
    } else if (this.state.search_id == 1) {
      this.getsearch(pageNumber, perpage);
    } else if (this.state.search_date == 1) {
      this.getsearchbydate(pageNumber, perpage);
    } else if (this.state.search_customer == 1) {
      this.getsearchbycustomer(pageNumber, perpage);
    } else {
      this.getorders(pageNumber, perpage);
    }
    this.setState({ activePage: pageNumber });
  }

  getorders(pageNumber, perpage) {
    this.setState({
      activePage: pageNumber,
    });
    axios
      .post(`${Auth.getapiurl()}/admin/getOrdersbyadmin`, {
        session: Auth.getToken(),
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        this.setState({ loaded: true });
        const persons = res.data.results;
        const TotalRecords = res.data.Records;
        this.setState({ persons });
        this.setState({ TotalRecords });
      });
  }

  getorderstatus() {
    axios.post(`${Auth.getapiurl()}/admin/OrderStatus`, { session: Auth.getToken() }).then((res) => {
      const order_status = res.data;
      this.setState({ order_status });
    });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/roleadd');
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/deleteOrder`, { session: Auth.getToken(), orderID: itemId })
      .then((response) => {
        this.getorders(1, Auth.Itemperpagecount());
      });
  };

  accesspermission = (roleid) => {
    this.props.history.push('/role-access/permission/' + roleid);
  };

  getsearch = (pageNumber, perpage) => {
    this.setState({
      activePage: pageNumber,
    });
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchbyorderid`, {
        session: Auth.getToken(),
        search: this.state.search,
        searchtype: 'normal',
        pageNumber: pageNumber,
        perpage: perpage,
      })

      .then((res) => {
        const persons = res.data.results;
        const TotalRecords = res.data.Records;
        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ TotalRecords });
          this.setState({ status: '204' });
          this.setState({ search_id: 1 });
        }
      });
  };

  getsearchbystatus = (pageNumber, perpage) => {
    this.setState({
      activePage: pageNumber,
    });
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchbyorderid`, {
        session: Auth.getToken(),
        search: this.state.search,
        searchtype: 'bystatus',
        pageNumber: pageNumber,
        perpage: perpage,
      })

      .then((res) => {
        const persons = res.data.results;
        const TotalRecords = res.data.Records;
        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ TotalRecords });
          this.setState({ status: '204' });
          this.setState({ stype: 1 });
        }
      });
  };

  getsearchbydate = (pageNumber, perpage) => {
    /* var search;
 
    if(this.state.searchdate == 'Invalid date')
	{
		var search = moment();
	}else{
		
		var search = this.state.searchdate;
	}*/
    this.setState({
      activePage: pageNumber,
    });
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchbyorderid`, {
        session: Auth.getToken(),
        search: this.state.searchdate,
        searchtype: 'dateseacrh',
        pageNumber: pageNumber,
        perpage: perpage,
      })

      .then((res) => {
        const persons = res.data.results;
        const TotalRecords = res.data.Records;

        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ TotalRecords });
          this.setState({ status: '204' });
          this.setState({ search_date: 1 });
        }
      });
  };

  getsearchbycustomer = (pageNumber, perpage) => {
    this.setState({
      TotalRecords: [],
      activePage: pageNumber,
    });

    axios
      .post(`${Auth.getapiurl()}/admin/getSearchbyorderid`, {
        session: Auth.getToken(),
        search: this.state.tocustID,
        searchtype: 'customerseacrh',
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        const persons = res.data.results;
        const TotalRecords = res.data.Records;

        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ TotalRecords });
          this.setState({ status: '204' });
          this.setState({ search_customer: 1 });
        }
      });
  };

  handleCustomerChange = (newValue) => {
    if (newValue != '') {
      axios
        .post(`${Auth.getapiurl()}/admin/getCustomerForAutocomplete`, {
          session: Auth.getToken(),
          newValue: newValue,
        })
        .then((res) => {
          const options = res.data;
          if (options.length > 0) {
            this.setState({ options: options });
          } else {
          }
        });
    }
  };

  handleSelectedChange = (selectedOption) => {
    this.setState({
      selectedOption,
      activePage: 1,
    });

    if (selectedOption) {
      const tocustID_value = selectedOption.value;
      this.setState(
        {
          tocustID: tocustID_value,
        },
        () => {
          if (this.state.tocustID) {
            this.getsearchbycustomer(1, Auth.Itemperpagecount());
          } else {
            this.setState({ status: '204' });
            this.getorders(1, Auth.Itemperpagecount());
          }
        }
      );
    }
  };

  handleCalanderChange = (searchdate) => {
    this.setState(
      {
        searchdate: searchdate,
        activePage: 1,
      },
      () => {
        // console.log(this.state.searchdate.toLocaleDateString());
        //console.log(new Intl.DateTimeFormat('en-US').format(this.state.searchdate));
        //this.state.searchdate = new Intl.DateTimeFormat('en-GB').format(this.state.searchdate);

        //this.state.searchdate = new Date(this.state.searchdate);

        this.state.searchdate = moment(this.state.searchdate).format();

        //this.state.searchdate = new Date(this.state.searchdate).toISOString().slice(0,10);

        /*console.log(new Intl.DateTimeFormat('en-GB', { 
    year: 'numeric', 
    month: 'long', 
    day: '2-digit' 
}).format(this.state.searchdate));*/

        if (this.state.searchdate && this.state.searchdate !== 'Invalid date') {
          console.log('yes');
          this.getsearchbydate(1, Auth.Itemperpagecount());
        } else if (this.state.searchdate === 'Invalid date') {
          console.log('do nothing');
        } else {
          console.log('no');
          this.setState({ status: '204' });
          this.getorders(1, Auth.Itemperpagecount());
        }
      }
    );
  };

  handlesearchChange = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      this.setState(
        {
          search: event.target.value,
          activePage: 1,
        },
        () => {
          if (this.state.search) {
            this.getsearch(1, Auth.Itemperpagecount());
          } else {
            this.setState({ status: '204' });
            this.getorders(1, Auth.Itemperpagecount());
          }
        }
      );
    }
  };

  handlestatusChange = (event) => {
    this.setState(
      {
        search: event.target.value,
        activePage: 1,
      },
      () => {
        if (this.state.search) {
          this.getsearchbystatus(1, Auth.Itemperpagecount());
        } else {
          this.setState({ status: '204' });
          this.getorders(1, Auth.Itemperpagecount());
        }
      }
    );
  };

  render() {
    let paginate;

    if (this.state.TotalRecords > Auth.Itemperpagecount() && this.state.status !== '203') {
      paginate = (
        <Pagination
          prevPageText="Previous"
          nextPageText="Next"
          firstPageText="First"
          lastPageText="Last"
          showPagination={false}
          activePage={this.state.activePage}
          itemsCountPerPage={Auth.Itemperpagecount()}
          totalItemsCount={this.state.TotalRecords}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange}
        />
      );
    }

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const status = this.state.status;
    const status_msg = this.state.status_msg;
    let table;
    if (status == '203') {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Order Number</td>
                <td>Name</td>

                <td>Order Status</td>
                <td>Amount</td>
                <td>Deliver Date</td>
                <td>Deliver Slot</td>
                <td>Payment Method</td>

                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Order Number</td>
                <td>Name</td>

                <td>Order Status</td>
                <td>Amount</td>
                <td>Deliver Date</td>
                <td>Deliver Slot</td>
                <td>Payment Method</td>

                <td>Action</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>{status_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Order Number</td>
                <td>Name</td>

                <td>Order Status</td>
                <td>Amount</td>
                <td>Deliver Date</td>
                <td>Deliver Slot</td>
                <td>Payment Method</td>

                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Order Number</td>
                <td>Name</td>

                <td>Order Status</td>
                <td>Amount</td>
                <td>Deliver Date</td>
                <td>Deliver Slot</td>
                <td>Payment Method</td>

                <td>Action</td>
              </tr>
            </tfoot>

            <tbody>
              {this.state.persons &&
                this.state.persons.map((item) => (
                  <tr key={item.ID}>
                    <td>{item.ID}</td>
                    <td>{item.customerName}</td>
                    <td>{item.order_status}</td>
                    <td>{item.total}</td>

                    <td>{item.deliverydate}</td>

                    <td>{item.deliveryslot}</td>

                    <td>{item.paymentMethod ? item.paymentMethod : ''}</td>

                    <td>
                      <Link
                        to={'/order-view/' + item.ID + '/' + item.customerID + '/' + item.bookingID}
                        className="btn btn-info"
                      >
                        <i className="fa fa-eye"></i>
                      </Link>

                      <Link to={'/order-edit/' + item.ID} className="btn btn-info leftside">
                        <i className="fa fa-edit"></i>
                      </Link>

                      <button
                        type="submit"
                        className="btn btn-danger leftside"
                        onClick={() => {
                          if (window.confirm('Delete the order and related order item?')) {
                            this.handleDelete(item.ID);
                          }
                        }}
                      >
                        <i className="fa fa-trash"></i>{' '}
                      </button>

                      {/* {item.paymentMethod == '' ? (
                        <Link
                          to={'/order-addpayment/' + item.ID + '/' + item.customerID}
                          className="btn btn-success leftside"
                        >
                          <i className="fa fa-money"></i>
                        </Link>
                      ) : (
                        ''
                      )} */}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">Orders</h3>
                    </div>
                    <div className="col-md-4 text-right"></div>
                    <div className="col-md-2 text-right"></div>
                  </div>

                  <div className="box-body">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          <i className="fa fa-filter"></i> Filters
                        </h3>
                      </div>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search by Order ID"
                                onKeyDown={this.handlesearchChange}
                              />
                            </td>

                            <td>
                              <select name="status" className="form-control" onChange={this.handlestatusChange}>
                                <option value="">Search by Status</option>

                                {this.state.order_status.map((item) => (
                                  <option value={item.ID}>{item.order_status}</option>
                                ))}
                              </select>
                            </td>

                            <td>
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleCalanderChange}
                                value={this.state.searchdate}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <Select
                                name="tocustID"
                                placeholder="Search by customer"
                                value={this.state.selectedOption}
                                onChange={this.handleSelectedChange}
                                options={this.state.options}
                                isClearable={this.state.isClearable}
                                onInputChange={this.handleCustomerChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="row">
                      {table}
                      {paginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Order;
