import React from 'react';
import { Link } from 'react-router-dom';
import store from 'store';
import Logout from './component/Logout';
import Sidebar from './Sidebar';
import Auth from '../src/modules/Auth';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      users: [],
    };

    if (Auth.getToken() != '') {
      Auth.check_auth();
    }
  }

  componentDidMount() {
    this.setState({
      user: JSON.parse(store.get('userdata')),
      users: { loading: true },
    });

    window.scrollTo(0, 0);
  }

  render() {
    const { user } = this.state;

    return (
      <div>
        <header className="main-header">
          <Link to="/" className="logo">
            <span className="logo-mini">
              <b>O</b>ne
            </span>
            <span className="logo-lg">
              <b>One</b>Cart
            </span>
          </Link>
          <nav className="navbar navbar-static-top">
            <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
              <span className="sr-only">Toggle navigation</span>
            </a>
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <li>
                  <Link to={'/useredit/' + user.ID}>
                    <i className="fa fa-user"></i> {user.fname}
                  </Link>
                </li>
                <li className="messages-menu">
                  <Logout />
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <Sidebar />
      </div>
    );
  }
}

export default Header;
