import axios from 'axios';
import React, { Component } from 'react';
import CKEditor from 'react-ckeditor-component';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class Cmspagesedit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
      content: '',
    };

    this.getSliderDetailsByID();
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  updateContent(newContent) {
    this.setState({
      content: newContent,
    });
  }

  onChange(evt) {
    console.log('onChange fired with event info: ', evt);
    var newContent = evt.editor.getData();

    this.setState({
      content: newContent,
    });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let newslug;
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });

    let str = this.state.fields.name;

    newslug = str
      .replace(/[^A-Za-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    fields['slug'] = newslug;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();

    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/updatepages`, {
          session: Auth.getToken(),
          name: this.state.fields.name,
          slug: this.state.fields.slug,
          description: this.state.content,
          status: this.state.fields.status,
          seo_title: this.state.fields.seo_title,
          keyword: this.state.fields.keyword,
          seo_description: this.state.fields.description,
          robots: this.state.fields.robots,
          pageID: this.props.match.params.id,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/cmspages');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  getSliderDetailsByID() {
    axios
      .post(`${Auth.getapiurl()}/admin/getPagesbyid`, {
        session: Auth.getToken(),
        pageid: this.props.match.params.id,
      })
      .then((res) => {
        const fields = res.data[0];
        this.setState({ fields });
        let content = res.data[0].description;
        this.setState({ content });
      });
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter role name.';
    }

    if (typeof fields['name'] !== 'undefined') {
      if (!fields['name'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['name'] = '*Please enter alphabet characters only.';
      }
    }

    if (!fields['status']) {
      formIsValid = false;
      errors['status'] = '*Please select status.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Cmspage</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Page Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="Page Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Page Slug:</label>

                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="slug"
                            name="slug"
                            value={this.state.fields.slug}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Page Content: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.editor ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <CKEditor
                            name="editor"
                            activeClass="p10"
                            content={this.state.content}
                            events={{
                              blur: this.onBlur,
                              afterPaste: this.afterPaste,
                              change: this.onChange,
                            }}
                          />
                          <div className="errorMsg">{this.state.errors.editor}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Status: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.status ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="status" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            <option value="A" selected={this.state.fields.status == 'A'}>
                              Active
                            </option>
                            <option value="D" selected={this.state.fields.status == 'D'}>
                              Inactive
                            </option>
                          </select>

                          <div className="errorMsg">{this.state.errors.status}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          SEO Title: <span className="red"></span>
                        </label>

                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            value={this.state.fields.seo_title}
                            placeholder="SEO Title"
                            name="seo_title"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Keyword: <span className="red"></span>
                        </label>

                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            value={this.state.fields.keyword}
                            placeholder="Keyword"
                            name="keyword"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Description: <span className="red"></span>
                        </label>

                        <div className="col-sm-6 ">
                          <textarea
                            name="description"
                            className="form-control"
                            rows="5"
                            cols="10"
                            value={this.state.fields.seo_decription}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Robots: <span className="red"></span>
                        </label>

                        <div className="col-sm-6">
                          <select name="robots" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            <option value="index_follow" selected={this.state.fields.robots == 'index_follow'}>
                              Index Follow
                            </option>
                            <option value="index_no_follow">Index No-Follow</option>
                            <option value="no_index_follow">No-Index Follow</option>
                            <option value="no_index_no_follow">No-Index No-Follow</option>
                          </select>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/cmspages">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Cmspagesedit;
