import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class Malladd extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      location: [],
      errorMessage: '',
      status_code: '',
    };

    this.getLocation();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  getLocation() {
    axios
      .post(`${Auth.getapiurl()}/admin/getLocations`, {
        session: Auth.getToken(),
      })
      .then((res) => {
        const location = res.data;
        console.log(location);

        this.setState({ location });
      });
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields['name'] = '';
      fields['latitude'] = '';
      fields['longitude'] = '';
      fields['locationID'] = '';
      fields['radius'] = '';
      fields['streams'] = '';
      fields['delivery_method'] = '';

      this.setState({ fields: fields });

      axios
        .post(`${Auth.getapiurl()}/admin/addMall`, {
          session: Auth.getToken(),
          name: this.state.fields.name,
          latitude: this.state.fields.latitude,
          longitude: this.state.fields.longitude,
          locationID: this.state.fields.locationID,
          radius: this.state.fields.radius,
          streams: this.state.fields.streams,
          delivery_method: this.state.fields.delivery_method,
        })
        .then((res) => {
          this.setState({
            errorMessage: res.data.error,
            status_code: res.data.status,
          });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/mall');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter mall name.';
    }

    if (!fields['latitude']) {
      formIsValid = false;
      errors['latitude'] = '*Please enter latitude.';
    }

    if (!fields['longitude']) {
      formIsValid = false;
      errors['longitude'] = '*Please enter longitude.';
    }

    if (!fields['locationID']) {
      formIsValid = false;
      errors['locationID'] = '*Please Select location.';
    }

    if (!fields['radius']) {
      formIsValid = false;
      errors['radius'] = '*Please enter mall radius.';
    }

    if (!fields['streams']) {
      formIsValid = false;
      errors['streams'] = '*Please enter mall stream.';
    }

    if (!fields['delivery_method']) {
      formIsValid = false;
      errors['delivery_method'] = '*Please Select Delivery Method.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Mall</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Location: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.locationID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="locationID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.location.map((locationitem) => (
                              <option value={locationitem.ID}>{locationitem.name}</option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.locationID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Mall Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Mall Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Latitude: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.latitude ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Latitude"
                            name="latitude"
                            value={this.state.fields.latitude}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.latitude}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Longitude: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.longitude ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Longitude"
                            name="longitude"
                            value={this.state.fields.longitude}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.longitude}</div>
                        </div>
                      </div>

                      {/* Radius */}
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Mall Radius: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.radius ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Mall Radius"
                            name="radius"
                            value={this.state.fields.radius}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.radius}</div>
                        </div>
                      </div>
                      {/* Streams */}
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Mall Stream: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.streams ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Mall Stream"
                            name="streams"
                            value={this.state.fields.streams}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.streams}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Delivery Method: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.delivery_method ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="delivery_method" className="form-control" onChange={this.handleChange}>
                            <option value="none">None</option>
                            <option value="delivery">Delivery</option>
                            <option value="click_and_collect">Click and Collect</option>
                            <option value="both">Both</option>
                          </select>

                          <div className="errorMsg">{this.state.errors.delivery_method}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/mall">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Malladd;
