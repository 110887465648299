import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { Link, Redirect } from 'react-router-dom';
import Header from '../../Header';
import Auth from '../../modules/Auth';

class Slideradd extends Component {
  constructor() {
    super();
    this.state = {
      fields: {
        status: 'Active',
        link: '',
        device_os: '',
      },
      errors: {},
      errorMessage: '',
      status_code: '',
      expiry: null,
      startdate: null,
      selectedFile: null,
      mindate: new Date(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  fileChangedHandler = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  handleCalanderChangeEndDate = (expiry) => this.setState({ expiry });
  handleCalanderChangeStartDate = (startdate) => this.setState({ startdate });

  submituserRegistrationForm(e) {
    e.preventDefault();

    if (this.validateForm()) {
      const formData = new FormData();
      formData.append('myFile', this.state.selectedFile, this.state.selectedFile.name);

      formData.set('session', Auth.getToken());
      formData.set('link', this.state.fields.link);
      formData.set('device_os', this.state.fields.device_os);
      formData.set('status', this.state.fields.status);

      if (this.state.startdate == null) {
        formData.set('startdate', null);
      } else {
        var newfromdate = moment(this.state.startdate).format().split('T');
        formData.set('startdate', newfromdate[0]);
      }

      if (this.state.expiry == null) {
        formData.set('expiry', null);
      } else {
        var newtodate = moment(this.state.expiry).format().split('T');
        formData.set('expiry', newtodate[0]);
      }

      axios
        .post(`${Auth.getapiurl()}/admin/addSlider`, formData, {
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent.loaded / progressEvent.total);
          },
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/slider');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!this.state.selectedFile) {
      formIsValid = false;
      errors['selectedFile'] = '*Please select Image.';
    }

    var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (this.state.fields.link) {
      if (!re.test(this.state.fields.link)) {
        formIsValid = false;
        errors['link'] = '*Enter valid link.';
      }
    }

    if (this.state.expiry && this.state.expiry < this.state.startdate) {
      formIsValid = false;
      errors['expiry'] = '*Expiry date should be greater than startdate.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Slider</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Image: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.selectedFile ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input type="file" className="form-control" onChange={this.fileChangedHandler} />

                          <div className="errorMsg">{this.state.errors.selectedFile}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Link:</label>

                        <div className={this.state.errors.link ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="link"
                            name="link"
                            onChange={this.handleChange}
                          />

                          <div className="errorMsg">{this.state.errors.link}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Device OS: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <select name="device_os" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            <option value="web">Web</option>
                            <option value="ios">IOS</option>
                            <option value="android">Android</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Status: <span className="red"></span>
                        </label>

                        <div className="col-sm-6">
                          <select name="status" className="form-control" onChange={this.handleChange}>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label ">
                          Start Date: <span className="red"></span>
                        </label>
                        <div className="col-sm-3 ">
                          <DatePicker
                            onChange={this.handleCalanderChangeStartDate}
                            value={this.state.startdate}
                            minDate={this.state.mindate}
                          />
                        </div>

                        <label className="col-sm-2 control-label ">
                          Expiry: <span className="red"></span>
                        </label>
                        <div className={this.state.errors.expiry ? 'col-sm-3 has-error' : 'col-sm-3'}>
                          <DatePicker
                            onChange={this.handleCalanderChangeEndDate}
                            value={this.state.expiry}
                            minDate={this.state.mindate}
                          />
                        </div>
                        <div className="errorMsg">{this.state.errors.expiry}</div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/slider">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Slideradd;
