import arrayMove from 'array-move';

import axios from 'axios';
import React, { Component } from 'react';
import Loader from 'react-loader';
import { Link, Redirect } from 'react-router-dom';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import Pagination from '../component/Pagination';
import Header from '../Header';
import Auth from '../modules/Auth';

class Mall extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
    this.addnewhandleclick = this.addnewhandleclick.bind(this);

    this.getmalls();

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      loaded: false,
      status: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  getmalls() {
    axios
      .post(`${Auth.getapiurl()}/admin/getMalls`, {
        session: Auth.getToken(),
      })
      .then((res) => {
        this.setState({ loaded: true });
        const persons = res.data;
        this.setState({ persons });
        console.log('Get_persons' + JSON.stringify(persons));
      });
  }

  updateSortType = (mallID, sortOrder) => {
    axios
      .post(`${Auth.getapiurl()}/admin/UpdateMallSort`, {
        session: Auth.getToken(),
        mallID: mallID,
        sortOrder: sortOrder,
      })
      .then((res) => {
        const persons = res.data;
        /*if(res.data.status == '203'){
    //this.setState({ status_msg:res.data.error });
    //this.setState({ status:res.data.status });
  }else{
    this.getmalls();
    this.setState({ status:'204' });
  } */
      });
  };

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/malladd');
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeMall`, {
        session: Auth.getToken(),
        mall_id: itemId,
      })
      .then((response) => {
        this.getmalls();
      });
  };

  getsearch = (pageNumber, perpage) => {
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchMall`, {
        session: Auth.getToken(),
        search: this.state.search,
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        const persons = res.data.results;
        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          const TotalRecords = res.data.Records;
          this.setState({ TotalRecords });
          this.setState({ status: '204' });
          this.setState({ stype: 1 });
        }
      });
  };

  handlesearchChange = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      this.setState(
        {
          search: event.target.value,
        },
        () => {
          if (this.state.search && this.state.search.length > 1) {
            //if (this.state.search.length % 2 === 0) {
            this.getsearch(1, Auth.Itemperpagecount());
            //}
          } else {
            this.setState({ status: '204' });
            this.getmalls(1, Auth.Itemperpagecount());
          }
        }
      );
    }
  };

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    /* const SortableList = SortableContainer(({items}) => {

  return (
    <tbody>
      {items.map((value, index) => (
        <SortableItem key={`item-${index.ID}`} index={index} value={value} />
      ))}
    </tbody>
  );
});

<SortableList items={this.state.pageOfItems} onSortEnd={this.onSortEnd} />

*/

    const DragHandle = sortableHandle(() => <span className="fa fa-circle"></span>);

    const SortableItem = sortableElement(({ value }) => (
      <tr>
        <td>
          <DragHandle />
        </td>
        <td>{value.ID}</td>
        <td>{value.name}</td>
        <td>{value.location_name}</td>
        <td>{value.radius}</td>
        <td>{value.streams}</td>
        <td>
          <Link to={'/malledit/' + value.ID} className="btn btn-info">
            <i className="fa fa-edit"></i>
          </Link>
          <button
            type="submit"
            className="btn btn-danger leftside"
            onClick={() => {
              if (window.confirm('Delete the item?')) {
                this.handleDelete(value.ID);
              }
            }}
          >
            <i className="fa fa-trash"></i>{' '}
          </button>
        </td>
      </tr>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <tbody>{children}</tbody>;
    });

    let onSortEnd = ({ oldIndex, newIndex }) => {
      this.setState({
        pageOfItems: arrayMove(this.state.pageOfItems, oldIndex, newIndex),
        oldIndex: oldIndex,
        newIndex: newIndex,
      });

      let i,
        page_items = this.state.pageOfItems;

      for (i = 0; i < page_items.length; i++) {
        let j = i + 1;
        this.updateSortType(this.state.pageOfItems[i].ID, j);
      }
      this.getmalls();
    };

    const status = this.state.status;
    const status_msg = this.state.status_msg;
    let table;
    if (status == '203') {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>mall Id</td>
                <td>mall Name</td>
                <td>Location</td>
                <td>Radius</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>mall Id</td>
                <td>mall Name</td>
                <td>Location</td>
                <td>Radius</td>
                <td>Action</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>{status_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Sort</td>
                <td>Mall Id</td>
                <td>Mall Name</td>
                <td>Location</td>
                <td>Radius</td>
                <td>Streams</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Sort</td>
                <td>Mall Id</td>
                <td>Mall Name</td>
                <td>Location</td>
                <td>Radius</td>
                <td>Streams</td>
                <td>Action</td>
              </tr>
            </tfoot>

            <SortableContainer onSortEnd={onSortEnd} axis="y" useDragHandle>
              {this.state.pageOfItems.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
              ))}
            </SortableContainer>
          </table>
          <Pagination items={this.state.persons} onChangePage={this.onChangePage} />
        </div>
      );
    }

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">Mall</h3>
                    </div>
                    <div className="col-md-4 text-right">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here.."
                        onKeyDown={this.handlesearchChange}
                      />
                    </div>
                    <div className="col-md-2 text-right">
                      <button className="btn btn-success" onClick={this.addnewhandleclick}>
                        <i className="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="row">{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Mall;
