import React from 'react';

const OrderNote = (props) => {
  return (
    <>
      <form className="form-horizontal has-validation-callback" onSubmit={() => props.submituserRegistrationForm()}>
        <div className="col-md-12 panel panel-default box-body">
          <div className="form-group">
            {/* <label className="col-sm-2 control-label">Change Status: <span className="red">*</span></label>
            <div className="col-sm-3">
              <select name="payment_status" className="form-control" onChange={this.handleChange}>
                {this.state.payment_status.map(pay_status =>
                  <option value={pay_status.ID} selected={this.state.pay_status_id.payStatus == pay_status.ID}>{pay_status.order_status}</option> 
                )}
              </select>
            </div> */}
            <label className="col-sm-2 control-label">
              Note Type: <span className="red"></span>
            </label>
            <div className={props.errors.note_type ? 'col-sm-3 has-error' : 'col-sm-3'}>
              <select name="note_type" className="form-control" onChange={() => props.handleChange()}>
                <option value="">-- Select --</option>
                <option value="private_note">Private Note</option>
                <option value="send_to_customer">Send to Customer</option>
                <div className="errorMsg">{props.errors.note_type}</div>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Note: <span className="red"></span>
            </label>
            <div className="col-sm-8">
              <textarea
                className="form-control"
                onChange={() => props.handleChange()}
                name="order_note"
                rows="5"
                cols="20"
              ></textarea>
            </div>
          </div>
          <div className="box-footer text-right col-sm-10">
            <button type="submit" className="btn btn-info leftside">
              Save
            </button>
          </div>
        </div>
      </form>
      {props.order_note_table}
    </>
  );
};

export default OrderNote;
