import React, { Component } from 'react';
import store from 'store';

import axios from 'axios';
import Header from './../../Header';
import { Redirect, Link } from 'react-router-dom';
import Pagination from './../../component/Pagination';
import Auth from './../../modules/Auth';

class Listaddress extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
    this.addnewhandleclick = this.addnewhandleclick.bind(this);

    this.getlistaddress();

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      status: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  getlistaddress() {
    axios
      .post(`${Auth.getapiurl()}/admin/getAddressesbyadmin`, {
        session: Auth.getToken(),
        customerID: this.props.match.params.id,
        type: '1',
      })
      .then((res) => {
        const persons = res.data;
        console.log(persons)
        this.setState({ persons });
      });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/address/' + this.props.match.params.id);
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeAddressbyadmin`, {
        session: Auth.getToken(),
        addressID: itemId,
        type: '1'
      })
      .then((response) => {
        this.getlistaddress();
      });
  };

  getsearch = () => {
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchaddrtype`, { session: Auth.getToken(), search: this.state.search })
      .then((res) => {
        const persons = res.data;
        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ status: '204' });
        }
      });
  };

  handlesearchChange = (event) => {
    this.setState(
      {
        search: event.target.value,
      },
      () => {
        if (this.state.search && this.state.search.length > 1) {
          if (this.state.search.length % 2 === 0) {
            this.getsearch();
          }
        } else {
          this.setState({ status: '204' });
          this.getlistaddress();
        }
      }
    );
  };

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const status = this.state.status;
    const status_msg = this.state.status_msg;
    let table;
    if (status == '203') {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Address 1</td>
                <td>Address 2</td>
                <td>City</td>
                <td>State</td>
                <td>Country</td>
                <td>Postal Code</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Address 1</td>
                <td>Address 2</td>
                <td>City</td>
                <td>State</td>
                <td>Country</td>
                <td>Postal Code</td>
                <td>Action</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>{status_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Address 1</td>
                <td>Address 2</td>
                <td>City</td>
                <td>State</td>
                <td>Country</td>
                <td>Postal Code</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Address 1</td>
                <td>Address 2</td>
                <td>City</td>
                <td>State</td>
                <td>Country</td>
                <td>Postal Code</td>
                <td>Action</td>
              </tr>
            </tfoot>

            <tbody>
              {this.state.pageOfItems.map((item) => (
                <tr key={item.addressID}>
                  <td>{item.addressID}</td>
                  <td>
                    {item.address1}
                  </td>
                  <td>
                    {item.address2}
                  </td>
                  <td>{item.city}</td>
                  <td>{item.state}</td>
                  <td>{item.country}</td>
                  <td>{item.postcode}</td>

                  <td>
                    <Link to={'/editaddress/' + item.addressID} className="btn btn-info">
                      <i className="fa fa-edit"></i>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-danger leftside"
                      onClick={() => {
                        if (window.confirm('Delete the item?')) {
                          this.handleDelete(item.addressID);
                        }
                      }}
                    >
                      <i className="fa fa-trash"></i>{' '}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination items={this.state.persons} onChangePage={this.onChangePage} />
        </div>
      );
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">Address</h3>
                    </div>
                    <div className="col-md-4 text-right"></div>
                    <div className="col-md-2 text-right">
                      <button className="btn btn-success" onClick={this.addnewhandleclick}>
                        <i className="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="row">{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Listaddress;
