import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './../../Header';
import Auth from './../../modules/Auth';

class BilingaddressEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
      persons: [],
      addressby: [],
    };
    this.getaddrtypes();
    this.getAddressesbyID();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  validateForm() {
    console.log(this.state.fields);

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['address1']) {
      formIsValid = false;
      errors['address1'] = '*Please enter address1.';
    }

    if (!fields['fname']) {
      formIsValid = false;
      errors['fname'] = '*Please enter first name.';
    }

    if (typeof fields['fname'] !== 'undefined') {
      if (!fields['fname'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['fname'] = '*Please enter alphabet characters only.';
      }
    }

    if (!fields['sname']) {
      formIsValid = false;
      errors['sname'] = '*Please enter last name.';
    }

    if (typeof fields['sname'] !== 'undefined') {
      if (!fields['sname'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['sname'] = '*Please enter alphabet characters only.';
      }
    }

    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = '*Please enter email-ID.';
    }

    if (typeof fields['email'] !== 'undefined') {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields['email'])) {
        formIsValid = false;
        errors['email'] = '*Please enter valid email-ID.';
      }
    }

    if (!fields['phone']) {
      formIsValid = false;
      errors['phone'] = '*Please enter phone no.';
    }

    if (typeof fields['phone'] !== 'undefined') {
      if (!fields['phone'].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors['phone'] = '*Please enter valid phone no.';
      }
    }

    if (!fields['postcode']) {
      formIsValid = false;
      errors['postcode'] = '*Please enter postcode.';
    }

    if (!fields['country']) {
      formIsValid = false;
      errors['country'] = '*Please select country.';
    }

    if (!fields['state']) {
      formIsValid = false;
      errors['state'] = '*Please select state.';
    }

    if (!fields['city']) {
      formIsValid = false;
      errors['city'] = '*Please enter city.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();

    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/editAddressbyadmin`, {
          session: Auth.getToken(),
          addressID: this.props.match.params.id,
          addressTypeID: this.state.fields.addressTypeID,
          fname: this.state.fields.fname,
          sname: this.state.fields.sname,
          company: this.state.fields.company,
          address1: this.state.fields.address1,
          address2: this.state.fields.address2,
          postcode: this.state.fields.postcode,
          city: this.state.fields.city,
          state: this.state.fields.state,
          country: this.state.fields.country,
          email: this.state.fields.email,
          phone: this.state.fields.phone,
          type: '2',
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });

          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/bilinglistaddress/' + this.state.fields.customerID);
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  getaddrtypes() {
    axios.post(`${Auth.getapiurl()}/admin/getaddrtypes`, { session: Auth.getToken() }).then((res) => {
      const persons = res.data;
      this.setState({ persons });
    });
  }

  getAddressesbyID() {
    axios
      .post(`${Auth.getapiurl()}/admin/getAddressesbyID`, {
        session: Auth.getToken(),
        addressID: this.props.match.params.id,
        type: '2'
      })
      .then((res) => {
        const fields = res.data[0];

        this.setState({ fields });
      });
  }

  render() {
    const fields = this.state.fields;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Address</h3>
                  </div>

                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <input
                        type="hidden"
                        className="form-control"
                        id="inputrole"
                        placeholder="First Name"
                        name="customerID"
                        value={this.state.fields.customerID}
                        onChange={this.handleChange}
                      />

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          First Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.fname ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="First Name"
                            name="fname"
                            value={this.state.fields.fname}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.fname}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Last Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.sname ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="Last Name"
                            name="sname"
                            value={this.state.fields.sname}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.sname}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Email: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.email ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="Email"
                            name="email"
                            value={this.state.fields.email}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.email}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Phone: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.phone ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="Phone"
                            name="phone"
                            value={this.state.fields.phone}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.phone}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Company:</label>

                        <div className={this.state.errors.company ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="Company Name"
                            name="company"
                            value={this.state.fields.company}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.company}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Address1: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.address1 ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <textarea
                            name="address1"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.fields.address1}
                          />
                          <div className="errorMsg">{this.state.errors.address1}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Address2: </label>

                        <div className={this.state.errors.address2 ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <textarea
                            name="address2"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.fields.address2}
                          />
                          <div className="errorMsg">{this.state.errors.address2}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          PostCode: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.postcode ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="postcode"
                            name="postcode"
                            value={this.state.fields.postcode}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.postcode}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Country: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.country ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="country" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            <option value="South Africa" selected={this.state.fields.country == 'South Africa'}>
                              South Africa
                            </option>
                          </select>
                          <div className="errorMsg">{this.state.errors.country}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          State: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.statename ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="statename" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            <option value="1" selected={this.state.fields.state == 1}>
                              Eastern Cape
                            </option>
                          </select>
                          <div className="errorMsg">{this.state.errors.country}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          City: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.city ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputrole"
                            placeholder="city"
                            name="city"
                            value={this.state.fields.city}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.city}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to={'/bilinglistaddress/' + this.state.fields.customerID}>
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default BilingaddressEdit;
