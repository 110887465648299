import React, { Component } from 'react';
import store from 'store';

import axios from 'axios';
import Header from './../../Header';
import { Redirect, Link } from 'react-router-dom';
import Pagination from './../../component/Pagination';
import Auth from './../../modules/Auth';
import Loader from 'react-loader';

class Stock extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
    this.addnewhandleclick = this.addnewhandleclick.bind(this);

    this.getstock();

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      status: '',
      loaded: false,
      Title: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  getstock() {
    axios
      .post(`${Auth.getapiurl()}/admin/getStockByProductID`, {
        session: Auth.getToken(),
        productID: this.props.match.params.id,
      })
      .then((res) => {
        this.setState({ loaded: true });
        const persons = res.data.results;
        const Title = res.data.product_name;

        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
          this.setState({ Title: Title });
        } else {
          this.setState({ persons });
          this.setState({ Title: Title });
        }
      });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/product-stock-add/' + this.props.match.params.id);
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeStockByStore`, { session: Auth.getToken(), stockID: itemId })
      .then((response) => {
        this.getstock();
      });
  };

  getsearch = () => {
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchlcard`, { session: Auth.getToken(), search: this.state.search })
      .then((res) => {
        const persons = res.data;
        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ status: '204' });
        }
      });
  };

  handlesearchChange = (event) => {
    this.setState(
      {
        search: event.target.value,
      },
      () => {
        if (this.state.search && this.state.search.length > 1) {
          if (this.state.search.length % 2 === 0) {
            this.getsearch();
          }
        } else {
          this.setState({ status: '204' });
          this.getstock();
        }
      }
    );
  };

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const status = this.state.status;
    const status_msg = this.state.status_msg;

    const total_qty_1 = this.state.persons.reduce((total_qty, item) => total_qty + parseInt(item.quantity, 0), 0);
    const total_avila_1 = this.state.persons.reduce(
      (total_avil, item) => total_avil + parseInt(item.availability, 0),
      0
    );

    let table;
    if (status == '203') {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Store</td>
                <td>Quantity</td>
                <td>Availability</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Store</td>
                <td>Quantity</td>
                <td>Availability</td>
                <td>Action</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>{status_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Store</td>
                <td>Quantity</td>
                <td>Availability</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Store</td>
                <td>Quantity</td>
                <td>Availability</td>
                <td>Action</td>
              </tr>
            </tfoot>

            <tbody>
              {this.state.pageOfItems.map((item) => (
                <tr key={item.ID}>
                  <td>{item.ID}</td>
                  <td>{item.storeID}</td>
                  <td>{item.quantity}</td>
                  <td>{item.availability}</td>

                  <td>
                    <Link to={'/product-stock-edit/' + item.ID} className="btn btn-info">
                      <i className="fa fa-edit"></i>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-danger leftside"
                      onClick={() => {
                        if (window.confirm('Delete the item?')) {
                          this.handleDelete(item.ID);
                        }
                      }}
                    >
                      <i className="fa fa-trash"></i>{' '}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination items={this.state.persons} onChangePage={this.onChangePage} />
        </div>
      );
    }

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-4">
                      <h3 className="box-title">Stock</h3>{' '}
                      <h5>
                        <b>{this.state.Title}</b>
                      </h5>{' '}
                    </div>

                    <div className="col-md-3 text-left">
                      <b>
                        Total Quantity : <span className="btn btn-info">{total_qty_1}</span>
                      </b>
                    </div>
                    <div className="col-md-3 text-left">
                      <b>
                        Total Availability : <span className="btn btn-info">{total_avila_1}</span>
                      </b>
                    </div>
                    <div className="col-md-2 text-right">
                      <button className="btn btn-success" onClick={this.addnewhandleclick}>
                        <i className="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="row">{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Stock;
