import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthConsumer } from './AuthContext';

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isAuth }) => (
      <Route render={(props) => (isAuth ? <Component {...props} /> : <Redirect to="/login" />)} {...rest} />
    )}
  </AuthConsumer>
);

export default ProtectedRoute;
