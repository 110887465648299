import axios from 'axios';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import ReactExport from 'react-data-export';
import ReactFileReader from 'react-file-reader';
import Loader from 'react-loader';
import { Redirect } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Header from '../../Header';
import Auth from '../../modules/Auth';

class Importexport extends Component {
  constructor(props) {
    super(props);

    this.getproducts();
    this.state = {
      persons: [],
      loaded: false,
      errorMessage: '',
      status_code: '',
    };

    this.handleFiles = this.handleFiles.bind(this);
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  getproducts() {
    axios.post(`${Auth.getapiurl()}/admin/exportProducts`, { session: Auth.getToken() }).then((res) => {
      this.setState({ loaded: true });
      const persons = res.data;

      this.setState({ persons });
    });
  }

  handleFiles(file) {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      var rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        var bstr = e.target.result;
        var wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

        var wsname = wb.SheetNames[0];
        var ws = wb.Sheets[wsname];

        var headers = {};
        var data = [];

        for (var z in ws) {
          if (z[0] === '!') continue;

          var col = z.substring(0, 1);
          var row = parseInt(z.substring(1));
          var value = ws[z].v;

          if (row == 1) {
            headers[col] = value;
            continue;
          }

          if (!data[row]) data[row] = {};
          data[row][headers[col]] = value;
        }
        //drop those first two rows which are empty
        data.shift();
        data.shift();
        //console.log(data);

        // other method
        /*  var json = XLSX.utils.sheet_to_json(ws, { header: 1, defVal=""});
         //var cols = make_cols(ws["!ref"]);
         var cols = [],
       C = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
       for (var i = 0; i < C; ++i) {
         cols[i] = { name: XLSX.utils.encode_col(i), key: i };
       }

         var data = { rows: json, cols: cols };
         resolve(data);
         console.log(data);   */

        axios
          .post(`${Auth.getapiurl()}/admin/importProduct`, {
            session: Auth.getToken(),
            product: data,
          })

          .then((res) => {
            alert(res.data.error);
            //this.setState({ errorMessage: res.data.error});
          });
      };
      if (file && rABS) reader.readAsBinaryString(file[0]);
      else reader.readAsArrayBuffer(file);
    });
  }

  generatecsv() {
    var headers = [
      { label: 'ID', key: 'ID' },
      { label: 'Name', key: 'name' },
      { label: 'Barcode', key: 'barcode' },
      { label: 'Common_NFU', key: 'commonNFU' },
      { label: 'Description', key: 'description' },
      { label: 'Discounted_Price', key: 'discountedPrice' },
      { label: 'Featured', key: 'featured' },
      { label: 'Height', key: 'height' },
      { label: 'Image_Url', key: 'imageURL' },
      { label: 'Last_Updated_NFU', key: 'lastUpdatedNFU' },
      { label: 'Length', key: 'length' },
      { label: 'Offer_Description', key: 'offerDesc' },
      { label: 'Price', key: 'price' },
      { label: 'Type', key: 'productType' },
      { label: 'URL', key: 'productURL' },
      { label: 'Visibility', key: 'visibility' },
      { label: 'Weight', key: 'weight' },
      { label: 'Width', key: 'width' },
      { label: 'Category', key: 'category' },
      { label: 'Tags', key: 'tag' },
      { label: 'VendorID', key: 'vendorID' },
      { label: 'InStock', key: 'inStock' },
    ];
  }

  render() {
    console.log(this.state.persons);

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    console.log(this.state.persons);
    const status = this.state.status;
    const status_msg = this.state.status_msg;
    let table;

    table = (
      <div className="col-md-2">
        <ExcelFile filename="product" element={<button>Export All Products</button>}>
          <ExcelSheet data={this.state.persons} name="Products">
            <ExcelColumn label="ID" value="ID" />
            <ExcelColumn label="Name" value="name" />
            <ExcelColumn label="Barcode" value="barcode" />
            <ExcelColumn label="Common_NFU" value="commonNFU" />
            <ExcelColumn label="Description" value="description" />
            <ExcelColumn label="Discounted_Price" value="discountedPrice" />
            <ExcelColumn label="Featured" value="featured" />
            <ExcelColumn label="Height" value="height" />
            <ExcelColumn label="Image_Url" value="imageURL" />
            <ExcelColumn label="Last_Updated_NFU" value="lastUpdatedNFU" />
            <ExcelColumn label="Length" value="length" />
            <ExcelColumn label="Offer_Description" value="offerDesc" />
            <ExcelColumn label="Price" value="price" />
            <ExcelColumn label="Type" value="productType" />
            <ExcelColumn label="URL" value="productURL" />
            <ExcelColumn label="Visibility" value="visibility" />
            <ExcelColumn label="Weight" value="weight" />
            <ExcelColumn label="Width" value="width" />
            <ExcelColumn label="Category" value="category" />
            <ExcelColumn label="Tag" value="tag" />
            <ExcelColumn label="VendorID" value="vendorID" />
            <ExcelColumn label="InStock" value="inStock" />
          </ExcelSheet>
        </ExcelFile>
      </div>
    );

    let csv_download = (
      <div className="col-md-2">
        <CSVLink data={this.state.persons} filename={'onecart_product.csv'} headers={this.headers}>
          Download CSV
        </CSVLink>
      </div>
    );

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">Import/Export</h3>
                    </div>
                  </div>
                  {this.renderAlert()}
                  <div className="box-body">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          <i className="fa fa-download"></i>Export
                        </h3>
                      </div>
                      <div className="box-body">
                        <div className="row">
                          {table} {csv_download}
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h3 className="panel-title">
                          <i className="fa fa-upload"></i>Import
                        </h3>
                      </div>
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-12">
                            <ReactFileReader handleFiles={this.handleFiles} fileTypes={('.csv', '.xlsx')}>
                              <button className="btn">Upload</button>
                            </ReactFileReader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Importexport;
