import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

const allvendors = [];

class CategoryEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      vendor: [],
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
    };

    this.getCategory();
    this.getvendors();
    this.getcategorybyid();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  validateForm() {
    let fields = this.state.fields;

    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter category name.';
    }

    if (!fields['categoryURL']) {
      formIsValid = false;
      errors['categoryURL'] = '*Please enter category url.';
    }

    if (!fields['vendorID']) {
      formIsValid = false;
      errors['vendorID'] = '*Please select vendor.';
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  getcategorybyid() {
    axios.post(`${Auth.getapiurl()}/admin/getCategorybyID`, { categoryID: this.props.match.params.id }).then((res) => {
      //console.log(res);
      const fields = res.data[0];
      this.setState({ fields });
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let catname;
      let prentitem;
      let vendoritem;
      let categoryurlitem;

      catname = this.state.fields.name;
      prentitem = this.state.fields.parentID;
      vendoritem = this.state.fields.vendorID;
      categoryurlitem = this.state.fields.categoryURL;

      axios
        .post(`${Auth.getapiurl()}/admin/updateCategory`, {
          categoryID: this.props.match.params.id,
          name: catname,
          parentID: prentitem,
          vendorID: vendoritem,
          categoryURL: categoryurlitem,
          session: Auth.getToken()
        })
        .then((res) => {
          console.log(res);

          this.setState({ errorMessage: res.data.error, status_code: res.data.status });

          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/category');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  getCategory() {
    axios.post(`${Auth.getapiurl()}/admin/getAllCategories`, {}).then((res) => {
      const category = res.data;

      this.setState({ category });
    });
  }

  getvendors() {
    axios.post(`${Auth.getapiurl()}/admin/getVendors`, { session: Auth.getToken() }).then((res) => {
      const vendor = res.data;
      this.setState({ vendor });

      for (var i = 0; i < vendor.length; i++) allvendors[vendor[i].ID] = vendor[i].name;
    });
  }

  render() {
    const fields = this.state.fields;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Category</h3>
                  </div>

                  {this.renderAlert()}
                  <form className="form-horizontal" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Category Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputcate"
                            placeholder="Category Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Parent Category:</label>

                        <div className="col-sm-6">
                          <select name="parentID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.category.map((catitem) => (
                              <option value={catitem.ID} selected={this.state.fields.parentID == catitem.ID}>
                                {catitem.name} - ({allvendors[catitem.vendorID]})
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Category URL: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.categoryURL ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="categoryURL"
                            placeholder="Category URL"
                            name="categoryURL"
                            value={this.state.fields.categoryURL}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.categoryURL}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Select Vendor: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.vendorID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="vendorID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.vendor.map((vendoritem) => (
                              <option value={vendoritem.ID} selected={this.state.fields.vendorID == vendoritem.ID}>
                                {vendoritem.name}
                              </option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.vendorID}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/category">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CategoryEdit;
