import React from 'react';

const OrderTransactions = (props) => {
  return (
    <div className="col-md-12">
      {props.transactions && props.transactions.length > 0 ? (
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <td className="text-left">Order ID</td>
              <td className="text-center">Payment Gateway name</td>
              <td className="text-right">Amount</td>
              <td className="text-center">Transaction Type</td>
              <td className="text-right">Date</td>
            </tr>
          </thead>
          <tbody>
            {props.transactions.map((item, i) => (
              <tr key={i}>
                <td className="text-left">{item.orderID}</td>
                <td className="text-center">{item.name}</td>
                <td className="text-right">
                  {props.currency} {item.amount}
                </td>
                <td className="text-center">{item.transactionType}</td>
                <td className="text-right">{item.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            height: '380px',
            alignItems: 'center',
          }}
        >
          <h4 style={{ color: 'grey' }}>
            <b>No transactions available for this order!</b>
          </h4>
        </div>
      )}
    </div>
  );
};

export default OrderTransactions;
