import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './../../Header';
import Auth from './../../modules/Auth';

class Stockadd extends Component {
  constructor() {
    super();
    this.state = {
      fields: {
        mallID: '',
        storeID: '',
      },
      errors: {},
      errorMessage: '',
      status_code: '',
      store: [],
      location: [],
      mall: [],
    };

    this.getLocation();
    this.handleChange = this.handleChange.bind(this);
    this.handlelocationChange = this.handlelocationChange.bind(this);
    this.handlemallChange = this.handlemallChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  getLocation() {
    axios.post(`${Auth.getapiurl()}/admin/getLocations`, { session: Auth.getToken() }).then((res) => {
      const location = res.data;
      //console.log(persons);
      this.setState({ location });
    });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handlelocationChange(e) {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });

    axios
      .post(`${Auth.getapiurl()}/admin/getMallByLocation`, { session: Auth.getToken(), locationID: e.target.value })
      .then((res) => {
        const mall = res.data;

        if (res.data.status != 203) {
          this.setState({ mall });
        } else {
          this.setState({ mall: [] });
          this.setState({ store: [] });
        }
      });
  }

  handlemallChange(e) {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields,
    });

    axios
      .post(`${Auth.getapiurl()}/admin/getStoreByMall`, { session: Auth.getToken(), mallID: e.target.value })
      .then((res) => {
        const store = res.data;

        if (res.data.status != 203) {
          this.setState({ store });
        } else {
          this.setState({ store: [] });
        }
      });
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/addStockByProductID`, {
          session: Auth.getToken(),
          storeID: this.state.fields.storeID,
          quantity: this.state.fields.quantity,
          availability: this.state.fields.availability,
          productID: this.props.match.params.id,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/product-stock/' + this.props.match.params.id);
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['locationID']) {
      formIsValid = false;
      errors['locationID'] = '*Please select Location.';
    }

    if (!fields['mallID']) {
      formIsValid = false;
      errors['mallID'] = '*Please select Mall.';
    }

    if (!fields['storeID']) {
      formIsValid = false;
      errors['storeID'] = '*Please select Store.';
    }

    if (!fields['quantity']) {
      formIsValid = false;
      errors['quantity'] = '*Please enter Quantity.';
    }

    if (!fields['availability']) {
      formIsValid = false;
      errors['availability'] = '*Please enter Availability.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  /* handleChange = event => {
     this.setState({ name: event.target.value });
   }

   handleSubmit = event => {
     event.preventDefault();


     axios.post(`http://192.168.1.208:5000/admin/addRole`, { session: store.get('id_token'),name: this.state.name })
       .then(res => {
        this.props.history.push("/role");
       })



   }*/

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Stock</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Location: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.locationID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="locationID" className="form-control" onChange={this.handlelocationChange}>
                            <option value="">-- Select --</option>
                            {this.state.location.map((location_Item) => (
                              <option value={location_Item.ID}>{location_Item.name}</option>
                            ))}
                          </select>
                          <div className="errorMsg">{this.state.errors.locationID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Mall: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.mallID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="mallID" className="form-control" onChange={this.handlemallChange}>
                            <option value="">-- Select --</option>
                            {this.state.mall.map((mall_Item) => (
                              <option value={mall_Item.ID}>{mall_Item.name}</option>
                            ))}
                          </select>
                          <div className="errorMsg">{this.state.errors.mallID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Store: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.storeID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="storeID" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            {this.state.store.map((store_Item) => (
                              <option value={store_Item.ID} selected={this.state.fields.storeID == store_Item.ID}>
                                {store_Item.name}
                              </option>
                            ))}
                          </select>
                          <div className="errorMsg">{this.state.errors.storeID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Quantity: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.quantity ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="number"
                            className="form-control"
                            id="inputrole"
                            placeholder="Quantity"
                            name="quantity"
                            value={this.state.fields.quantity}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.quantity}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Availability: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.availability ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="number"
                            className="form-control"
                            id="inputrole"
                            placeholder="Availability"
                            name="availability"
                            value={this.state.fields.availability}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.availability}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to={'/product-stock/' + this.props.match.params.id}>
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Stockadd;
