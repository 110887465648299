import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

class Logout extends Component {
  state = {
    redirect: false,
  };

  handleClick = () => {
    localStorage.clear();
    //this.props.history.push('/login');
    this.setState({ redirect: true });
  };

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect push to="/login" />;
    }

    return (
      <button className="btn btn-danger btn-xs" onClick={this.handleClick}>
        <i className="fa fa-sign-out"></i> Logout
      </button>
    );
  }
}

export default withRouter(Logout);
