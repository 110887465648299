import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class Storeedit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: [],
      vendor: [],
      mall: [],
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
    };

    this.getLocation();
    this.getVendor();
    this.getMall();

    this.getstoresbyid();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    if (e.target.checked === true) {
      fields['exclude_from_del'] = 'yes';
    } else {
      fields['exclude_from_del'] = 'no';
    }

    this.setState({
      fields,
    });
  }

  getLocation() {
    axios.post(`${Auth.getapiurl()}/admin/getLocations`, { session: Auth.getToken() }).then((res) => {
      const location = res.data;

      this.setState({ location });
    });
  }

  getVendor() {
    axios.post(`${Auth.getapiurl()}/admin/getVendors`, { session: Auth.getToken() }).then((res) => {
      const vendor = res.data;

      this.setState({ vendor });
    });
  }

  getMall() {
    axios.post(`${Auth.getapiurl()}/admin/getMalls`, { session: Auth.getToken() }).then((res) => {
      const mall = res.data;

      this.setState({ mall });
    });
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter store name.';
    }

    if (!fields['slug']) {
      formIsValid = false;
      errors['slug'] = '*Please enter store slug.';
    }

    if (!fields['latitude']) {
      formIsValid = false;
      errors['latitude'] = '*Please enter latitude.';
    }

    if (!fields['longitude']) {
      formIsValid = false;
      errors['longitude'] = '*Please enter longitude.';
    }

    if (!fields['locationID']) {
      formIsValid = false;
      errors['locationID'] = '*Please Select Location.';
    }

    if (!fields['mallID']) {
      formIsValid = false;
      errors['mallID'] = '*Please Select Mall.';
    }

    if (!fields['vendorID']) {
      formIsValid = false;
      errors['vendorID'] = '*Please Select vendor.';
    }

    if (!fields['delivery_method']) {
      formIsValid = false;
      errors['delivery_method'] = '*Please Select Delivery Method.';
    }

    if (!fields['service_charge']) {
      formIsValid = false;
      errors['service_charge'] = '*Please Select Service Charge.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submituserRegistrationForm(event) {
    event.preventDefault();

    if (this.validateForm()) {
      let store_name;
      let store_slug;
      let store_latitude;
      let store_longitude;
      let store_locationID;
      let store_mallID;
      let store_vendorID;
      let store_delivery_method;
      let store_exclude_from_del;
      let store_service_charge;
      let store_delivery_charge_type;
      let store_fix;
      let store_per_km;
      let store_min_del_charge;

      store_name = this.state.fields.name;
      store_slug = this.state.fields.slug;
      store_latitude = this.state.fields.latitude;
      store_longitude = this.state.fields.longitude;
      store_locationID = this.state.fields.locationID;
      store_mallID = this.state.fields.mallID;
      store_vendorID = this.state.fields.vendorID;
      store_delivery_method = this.state.fields.delivery_method;
      store_exclude_from_del = this.state.fields.exclude_from_del;
      store_service_charge = this.state.fields.service_charge;
      store_delivery_charge_type = this.state.fields.delivery_charge_type;
      store_fix = this.state.fields.fix;
      store_per_km = this.state.fields.per_km;
      store_min_del_charge = this.state.fields.min_del_charge;

      axios
        .post(`${Auth.getapiurl()}/admin/UpdateStore`, {
          session: Auth.getToken(),
          name: store_name,
          slug: store_slug,
          latitude: store_latitude,
          longitude: store_longitude,
          locationID: store_locationID,
          mallID: store_mallID,
          vendorID: store_vendorID,
          delivery_method: store_delivery_method,
          exclude_from_del: store_exclude_from_del,
          service_charge: store_service_charge,
          delivery_charge_type: store_delivery_charge_type,
          fix: store_fix,
          per_km: store_per_km,
          min_del_charge: store_min_del_charge,
          store_id: this.props.match.params.id,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });

          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/store');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  getstoresbyid() {
    axios
      .post(`${Auth.getapiurl()}/admin/getStore`, { session: Auth.getToken(), store_id: this.props.match.params.id })
      .then((res) => {
        const fields = res.data[0];

        this.setState({ fields });
      });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  render() {
    const fields = this.state.fields;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    let method;
    let min_charge;

    if (this.state.fields.delivery_charge_type == 'fix') {
      method = (
        <div className="form-group">
          <label className="col-sm-2 control-label">
            Fix: <span className="red"></span>
          </label>

          <div className={this.state.errors.fix ? 'col-sm-6 has-error' : 'col-sm-6'}>
            <input
              type="text"
              className="form-control"
              id="inputstore"
              name="fix"
              value={this.state.fields.fix}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.fix}</div>
          </div>
        </div>
      );
    } else if (this.state.fields.delivery_charge_type == 'variable') {
      method = (
        <div className="form-group">
          <label className="col-sm-2 control-label">
            Per KM: <span className="red"></span>
          </label>

          <div className={this.state.errors.per_km ? 'col-sm-6 has-error' : 'col-sm-6'}>
            <input
              type="text"
              className="form-control"
              id="inputstore"
              name="per_km"
              value={this.state.fields.per_km}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.per_km}</div>
          </div>
        </div>
      );

      min_charge = (
        <div className="form-group">
          <label className="col-sm-2 control-label">
            Minimum Delivery Charge: <span className="red"></span>
          </label>

          <div className={this.state.errors.min_del_charge ? 'col-sm-6 has-error' : 'col-sm-6'}>
            <input
              type="text"
              className="form-control"
              id="inputstore"
              name="min_del_charge"
              value={this.state.fields.min_del_charge}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.min_del_charge}</div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Store</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">Vendor:</label>

                        <div className={this.state.errors.vendorID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="vendorID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.vendor.map((vendoritem) => (
                              <option value={vendoritem.ID} selected={this.state.fields.vendorID == vendoritem.ID}>
                                {vendoritem.name}
                              </option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.vendorID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Mall:</label>

                        <div className={this.state.errors.mallID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="mallID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.mall.map((mallitem) => (
                              <option value={mallitem.ID} selected={this.state.fields.mallID == mallitem.ID}>
                                {mallitem.name}
                              </option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.mallID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Location:</label>

                        <div className={this.state.errors.locationID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="locationID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.location.map((locationitem) => (
                              <option
                                value={locationitem.ID}
                                selected={this.state.fields.locationID == locationitem.ID}
                              >
                                {locationitem.name}
                              </option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.locationID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Store Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Store Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Slug: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.slug ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Store Slug"
                            name="slug"
                            value={this.state.fields.slug}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.slug}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Latitude: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.latitude ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Latitude"
                            name="latitude"
                            value={this.state.fields.latitude}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.latitude}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Longitude: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.longitude ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Longitude"
                            name="longitude"
                            value={this.state.fields.longitude}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.longitude}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Delivery Method: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.delivery_method ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="delivery_method" className="form-control" onChange={this.handleChange}>
                            <option value="none" selected={this.state.fields.delivery_method == 'none'}>
                              None
                            </option>
                            <option value="delivery" selected={this.state.fields.delivery_method == 'delivery'}>
                              Delivery
                            </option>
                            <option
                              value="click_and_collect"
                              selected={this.state.fields.delivery_method == 'click_and_collect'}
                            >
                              Click & Collect
                            </option>
                            <option value="both" selected={this.state.fields.delivery_method == 'both'}>
                              Both
                            </option>
                          </select>

                          <div className="errorMsg">{this.state.errors.delivery_method}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Exclude From OneCart Delivery : <span className="red"></span>
                        </label>

                        <div className={this.state.errors.exclude_from_del ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="checkbox"
                            name="exclude_from_del"
                            value="yes"
                            onChange={this.handleChange}
                            checked={this.state.fields.exclude_from_del == 'yes' ? 'checked' : ''}
                          />
                          <div className="errorMsg">{this.state.errors.exclude_from_del}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Service Charge In % : <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.service_charge ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Service Charge"
                            name="service_charge"
                            value={this.state.fields.service_charge}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.service_charge}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Delivery Charge Type: <span className="red"></span>
                        </label>

                        <div className={this.state.errors.delivery_charge_type ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="delivery_charge_type" className="form-control" onChange={this.handleChange}>
                            <option value="fix" selected={this.state.fields.delivery_charge_type == 'fix'}>
                              Fix
                            </option>
                            <option value="variable" selected={this.state.fields.delivery_charge_type == 'variable'}>
                              Variable
                            </option>
                          </select>

                          <div className="errorMsg">{this.state.errors.delivery_charge_type}</div>
                        </div>
                      </div>

                      {method}
                      {min_charge}

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/store">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Storeedit;
