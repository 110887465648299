import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class Orderedit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
    };
    this.getorderbyid();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  renderAlert() {
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  getorderbyid() {
    axios
      .post(`${Auth.getapiurl()}/admin/getorderbyid`, {
        session: Auth.getToken(),
        orderID: this.props.match.params.id,
      })
      .then((res) => {
        const fields = res.data[0];

        this.setState({ fields });
      });
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['payStatus']) {
      formIsValid = false;
      errors['payStatus'] = '*Please select status.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/Updateorder`, {
          session: Auth.getToken(),
          status: this.state.fields.payStatus,
          orderID: this.props.match.params.id,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });

          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/orders');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  render() {
    const fields = this.state.fields;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Order</h3>
                  </div>

                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Change Status: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.payStatus ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="payStatus" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            <option value="pending" selected={this.state.fields.payStatus == 'pending'}>
                              Pending
                            </option>
                            <option value="success" selected={this.state.fields.payStatus == 'success'}>
                              Success
                            </option>
                          </select>

                          <div className="errorMsg">{this.state.errors.payStatus}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Note: <span className="red"></span>
                        </label>

                        <div className={this.state.errors.payStatus ? 'col-sm-2 has-error' : 'col-sm-2'}>
                          <textarea
                            className="form-control"
                            onChange={this.handleChange}
                            name="order_note"
                            rows="5"
                            cols="20"
                          ></textarea>

                          <div className="errorMsg">{this.state.errors.payStatus}</div>
                        </div>

                        <label className="col-sm-2 control-label">
                          Note Type: <span className="red"></span>
                        </label>

                        <div className={this.state.errors.payStatus ? 'col-sm-2 has-error' : 'col-sm-2'}>
                          <select name="note_type" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            <option value="private_note" selected={this.state.fields.payStatus == 'private_note'}>
                              Private Note
                            </option>
                            <option
                              value="send_to_customer"
                              selected={this.state.fields.payStatus == 'send_to_customer'}
                            >
                              Send to Customer
                            </option>
                          </select>

                          <div className="errorMsg">{this.state.errors.payStatus}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/orders">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Orderedit;
