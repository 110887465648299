import ReactImageFallback from 'react-image-fallback';
import { Redirect, Link } from 'react-router-dom';
import React, { Component } from 'react';
import Loader from 'react-loader';
import Moment from 'react-moment';
import axios from 'axios';
import Header from '../Header';
import Auth from '../modules/Auth';
import './style.css';

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deal: {
        id: 0,
        productID: 0,
        productName: '',
        productVendor: '',
        productPrice: 0,
        discountAmount: 0,
        discountType: 'fixedAmount',
        dateStart: undefined,
        dateEnd: undefined,
        minQty: 0,
        isDeal: 0,
        productImage: '',
      },
      loaded: false,
    };

    this.dealID = this.props.match.params.id;
    this.getDealbyID();
  }

  getDealbyID() {
    axios
      .post(`${Auth.getapiurl()}/admin/getDealbyID`, {
        session: Auth.getToken(),
        deal_id: this.dealID,
      })
      .then((res) => {
        const deal = res.data.results[0];
        this.setState({ loaded: true });
        this.setState({ deal });
      });
  }

  render() {
    if (!Auth.getToken()) return <Redirect to="/login" />;
    const { deal } = this.state;

    return (
      <div>
        <Loader loaded={this.state.loaded} />
        <div>
          <Header />
        </div>

        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">
                        <i className="fa fa-gift" />
                        {` Deal#${this.dealID}`}
                      </h3>
                    </div>

                    <div className="col-md-3 text-right">
                      <Link to={`/deal-edit/${this.dealID}`} className="btn btn-success">
                        <i className="fa fa-edit">{' Edit'}</i>
                      </Link>
                    </div>

                    <div className="col-md-3 text-right">
                      <Link to="/deals" className="btn btn-success">
                        <i className="fa fa-arrow-left" aria-hidden="true" />
                        {' Back'}
                      </Link>
                    </div>
                  </div>

                  <div className="box-body" id="divToPrint">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h3 className="panel-title">
                              <i className="fa fa-gift" />
                              {' Deal Table'}
                            </h3>
                          </div>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Product ID</td>
                                <td>{deal.productID}</td>
                              </tr>

                              <tr>
                                <td>Product Name</td>
                                <td>{`${deal.productName} (${deal.productVendor} )`}</td>
                              </tr>

                              <tr>
                                <td>Price</td>
                                <td>
                                  <b>{`R ${deal.productPrice.toFixed(2)}`}</b>
                                </td>
                              </tr>

                              <tr>
                                <td>Discount</td>
                                <td>
                                  <b>
                                    {deal.discountType === 'fixedAmount'
                                      ? `R ${deal.discountAmount.toFixed(2)}`
                                      : `${(deal.discountAmount * 100).toFixed(2)}%`}
                                  </b>
                                </td>
                              </tr>

                              <tr>
                                <td>Start Date</td>
                                <td>
                                  <Moment format="DD-MMM-YYYY">{deal.dateStart}</Moment>
                                </td>
                              </tr>

                              <tr>
                                <td>End Date</td>
                                <td>
                                  <Moment format="DD-MMM-YYYY">{deal.dateEnd}</Moment>
                                </td>
                              </tr>

                              <tr>
                                <td>Quantity</td>
                                <td>{deal.minQty}</td>
                              </tr>
                              <tr>
                                <td>Is Deal</td>
                                <td>{deal.isDeal ? 'YES' : 'NO'}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h3 className="panel-title">
                              <i className="fa fa-camera" />
                              {' Image'}
                            </h3>
                          </div>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td id="dealImage">
                                  <ReactImageFallback
                                    src={Auth.imageCheck(deal.productImage)}
                                    fallbackImage={`${Auth.getapiurl()}/noimage.png`}
                                    initialImage={`${Auth.getapiurl()}/loading.gif`}
                                    alt="Product Image"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default View;
