import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
import Header from '../Header';
import { Redirect, Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch';
import Auth from '../modules/Auth';
import './Recipe.css';

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY } = process.env;
const client = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY);

const index = client.initIndex('products');

class RecipeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: '',
        description: '',
        recipeImage: '',
        category: 0,
        createdBy: '',
        serving: 0,
        totalTime: 0,
        slug: '',
        sort: '',
        instructions: '',
        ingredients: '',
      },
      fileImage: null,
      selectedFile: null,
      selectedprodOption: [],
      prodOptions: [],
      errors: {},
    };

    this.getrecipesbyid();
    this.handleChange = this.handleChange.bind(this);
    this.updateRecipe = this.updateRecipe.bind(this);
  }

  getrecipesbyid() {
    if (this.props.match.params.id) {
      axios
        .post(`${Auth.getapiurl()}/admin/getRecipebyID`, {
          session: Auth.getToken(),
          recipe_id: this.props.match.params.id,
        })
        .then((res) => {
          if (res.data.status == 200) {
            const fields = res.data.results[0];
            const selectedprodOption = res.data.results[0].products;
            this.setState({
              fields: fields,
              selectedprodOption: selectedprodOption,
            });
          }
        });
    }
  }

  renderAlert(statusCode, message) {
    if (message && statusCode == 400) {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {message}
        </div>
      );
    } else if (message && statusCode == 200) {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {message}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;

    if ((e.target.name === 'name') | (e.target.name === 'slug')) {
      let slug = e.target.value
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');

      fields['slug'] = slug;

      this.setState({
        fields,
      });
    }

    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  fileChangedHandler = (event) => {
    this.setState({ selectedFile: event.target.files[0], fileImage: URL.createObjectURL(event.target.files[0]) });
  };

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter recipe name.';
    }

    if (!fields['description']) {
      formIsValid = false;
      errors['description'] = '*Please enter description.';
    }

    if (!fields['createdBy']) {
      formIsValid = false;
      errors['createdBy'] = '*Please enter created by.';
    }

    if (!fields['slug']) {
      formIsValid = false;
      errors['slug'] = '*Please enter slug.';
    }

    if (!fields['sort']) {
      formIsValid = false;
      errors['sort'] = '*Please enter sort.';
    }

    if (!fields['ingredients']) {
      formIsValid = false;
      errors['ingredients'] = '*Please enter ingredients.';
    }

    if (!fields['instructions']) {
      formIsValid = false;
      errors['instructions'] = '*Please enter instructions.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  updateRecipe(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const formData = new FormData();

      if (this.state.selectedFile) {
        formData.append('myFile', this.state.selectedFile, this.state.selectedFile.name);

        formData.set('image', 'YES');
      } else {
        formData.set('image', 'NULL');
      }

      if (this.props.match.params.id !== undefined) {
        formData.set('recipeID', this.props.match.params.id);
      }

      formData.set('session', Auth.getToken());

      formData.set('name', this.state.fields.name);
      formData.set('description', this.state.fields.description);
      formData.set('recipeImage', this.state.fields.recipeImage);
      formData.set('category', this.state.fields.category);
      formData.set('createdBy', this.state.fields.createdBy);
      formData.set('serving', this.state.fields.serving);
      formData.set('totalTime', this.state.fields.totalTime);
      formData.set('slug', this.state.fields.slug);
      formData.set('sort', this.state.fields.sort);
      formData.set('instructions', this.state.fields.instructions);
      formData.set('ingredients', this.state.fields.ingredients);

      let productIDs = [];
      this.state.selectedprodOption.forEach((selectedprodOption) => productIDs.push(selectedprodOption.value));

      let newProductArray = productIDs.join(',');
      formData.append('productIDs', [newProductArray]);

      axios
        .post(`${Auth.getapiurl()}/admin/updateRecipe`, formData, {
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent.loaded / progressEvent.total);
          },
        })
        .then((res) => {
          let message = res.data.message;
          let statusCode = res.data.status;

          if (res.data.status == 200) {
            this.renderAlert(statusCode, message);
            setTimeout(
              function () {
                this.props.history.push('/recipe');
              }.bind(this),
              2000,
            );
          } else {
            this.renderAlert(statusCode, message);
          }
        });
    }
  }

  /* =================== Product change ============== */

  handleproductChange = (selectedprodOption) => {
    this.setState({ selectedprodOption: [...selectedprodOption] });
  };

  getAlgoliaProducts = async (newValue) => {
    try {
      const { hits, queryID } = await index.search(newValue, {
        clickAnalytics: false,
        analytics: false,
      });

      let algoliaProductsArr = [];
      let position = 1;

      for (const hit of hits) {
        algoliaProductsArr.push({
          key: parseInt(hit.objectID),
          label: hit.name + ' (' + hit.vendor.name + ')',
          value: parseInt(hit.objectID),
        });
        position++;
      }

      if (algoliaProductsArr.length > 0) {
        this.setState({
          prodOptions: algoliaProductsArr,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleProductInputChange = (newValue: string) => {
    if (newValue != '') {
      this.getAlgoliaProducts(newValue);
    }
  };

  /* =================== End Product change ============== */

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">{this.props.match.params.id ? 'Edit Recipe' : 'Add Recipe'}</h3>
                  </div>

                  {this.renderAlert()}
                  <form className="form-horizontal" onSubmit={this.updateRecipe}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Enter recipe name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Description: <span className="red">*</span>{' '}
                        </label>

                        <div className={this.state.errors.description ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <textarea
                            className="form-control note"
                            placeholder="Enter Description"
                            name="description"
                            value={this.state.fields.description}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group  bottom-gutter">
                        <label className="col-sm-2 control-label">Recipe Image</label>

                        <div className="col-sm-4 fileContainer">
                          <img
                            src={this.state.fields.recipeImage ? this.state.fields.recipeImage : this.state.fileImage}
                          />
                          <label for="files" class="btn btn-success">
                            {this.state.fields.recipeImage ? 'Change Image' : 'Select Image'}
                          </label>
                          <input id="files" type="file" onChange={this.fileChangedHandler} />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Category: <span className="red">*</span>
                        </label>

                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Enter category"
                            name="category"
                            value={this.state.fields.category}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          CreatedBy: <span className="red">*</span>{' '}
                        </label>

                        <div className={this.state.errors.createdBy ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <textarea
                            className="form-control note"
                            placeholder=" Enter Created By"
                            name="createdBy"
                            value={this.state.fields.createdBy}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Serving: <span className="red">*</span>
                        </label>

                        <div className="col-sm-6">
                          <input
                            type="number"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Enter serving"
                            name="serving"
                            value={this.state.fields.serving}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Total Time: <span className="red">*</span>
                        </label>

                        <div className="col-sm-6">
                          <input
                            type="number"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Enter Total Time"
                            name="totalTime"
                            value={this.state.fields.totalTime}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Slug: <span className="red">*</span>{' '}
                        </label>

                        <div className={this.state.errors.slug ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Enter slug"
                            name="slug"
                            value={this.state.fields.slug}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Sort: <span className="red">*</span>{' '}
                        </label>

                        <div className={this.state.errors.sort ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="number"
                            className="form-control"
                            id="inputproduct"
                            placeholder="Enter sort"
                            name="sort"
                            value={this.state.fields.sort}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Ingredients: <span className="red">*</span>{' '}
                        </label>

                        <div className={this.state.errors.ingredients ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <textarea
                            className="form-control note"
                            placeholder="Enter ingredients"
                            name="ingredients"
                            value={this.state.fields.ingredients}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Instructions: <span className="red">*</span>{' '}
                        </label>

                        <div className={this.state.errors.instructions ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <textarea
                            className="form-control note"
                            placeholder="Enter instructions"
                            name="instructions"
                            value={this.state.fields.instructions}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      {/* products */}
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Products: <span className="red">*</span>
                        </label>
                        <div className={this.state.errors.productID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <Select
                            name="productID"
                            placeholder="Product Search..."
                            value={this.state.selectedprodOption}
                            onChange={this.handleproductChange}
                            options={this.state.prodOptions}
                            onInputChange={this.handleProductInputChange}
                            getOptionLabel={(option) =>
                              `${option.label} ${option.vendor ? '(' + option.vendor + ')' : ''}`
                            }
                            isMulti={true}
                          />

                          <div className="errorMsg">{this.state.errors.productID}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/recipe">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default RecipeEdit;
