import React, { Component } from 'react';
import store from 'store';

import axios from 'axios';
import Header from '../Header';
import { Redirect, Link } from 'react-router-dom';
import Loader from 'react-loader';
import Pagination from '../component/Pagination';
import Auth from '../modules/Auth';

class Dietarytype extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
    this.addnewhandleclick = this.addnewhandleclick.bind(this);

    this.getdietarytypes();

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      loaded: false,
      status_msg: '',
      status: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  getdietarytypes() {
    axios.post(`${Auth.getapiurl()}/admin/getDietary_types`, { session: Auth.getToken() }).then((res) => {
      if (res.data.status == '203') {
        this.setState({ loaded: true });
        this.setState({ status_msg: 'No Records Found' });
        this.setState({ persons: [] });
        this.setState({ status: res.data.status });
      } else {
        this.setState({ loaded: true });
        const persons = res.data.results;
        this.setState({ persons });
      }
    });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/dietary-type-add');
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeDietarytype`, { session: Auth.getToken(), dietarytype_id: itemId })
      .then((response) => {
        this.getdietarytypes();
      });
  };

  getsearch = () => {
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchDietarytype`, { session: Auth.getToken(), search: this.state.search })
      .then((res) => {
        const persons = res.data;
        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          this.setState({ persons });
          this.setState({ status: '204' });
        }
      });
  };

  handlesearchChange = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      this.setState(
        {
          search: event.target.value,
        },
        () => {
          if (this.state.search && this.state.search.length > 1) {
            //if (this.state.search.length % 2 === 0) {
            this.getsearch();
            //}
          } else {
            this.setState({ status: '204' });
            this.getdietarytypes();
          }
        }
      );
    }
  };

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const status = this.state.status;
    const status_msg = this.state.status_msg;
    let table;
    if (status == '203') {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Dietary Type Name</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Dietary Type Name</td>
                <td>Action</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>{status_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Dietary Type Name</td>

                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Dietary Type Name</td>

                <td>Action</td>
              </tr>
            </tfoot>

            <tbody>
              {this.state.pageOfItems.map((item) => (
                <tr key={item.ID}>
                  <td>{item.ID}</td>
                  <td>{item.name}</td>
                  <td>
                    <Link to={'/dietary-type-edit/' + item.ID} className="btn btn-info">
                      <i className="fa fa-edit"></i>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-danger leftside"
                      onClick={() => {
                        if (window.confirm('Delete the item?')) {
                          this.handleDelete(item.ID);
                        }
                      }}
                    >
                      <i className="fa fa-trash"></i>{' '}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination items={this.state.persons} onChangePage={this.onChangePage} />
        </div>
      );
    }

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">Dietary Type</h3>
                    </div>
                    <div className="col-md-4 text-right">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here.."
                        onKeyDown={this.handlesearchChange}
                      />
                    </div>
                    <div className="col-md-2 text-right">
                      <button className="btn btn-success" onClick={this.addnewhandleclick}>
                        <i className="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="row">{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Dietarytype;
