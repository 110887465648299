import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import React, { Fragment } from 'react';
import ReactImageFallback from 'react-image-fallback';
import Auth from '../modules/Auth';

const ShoppingList = (props) => {
  const groupByVendors = (persons) => {
    const items = groupBy(persons, 'vendorName');
    return items;
  };

  return (
    <div className="col-md-12">
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <td className="text-left">Image</td>
            <td className="text-left">Product</td>
            <td className="text-right">Quantity</td>
            <td className="text-right">Unit Price</td>
            <td className="text-right">Total</td>
          </tr>
        </thead>
        <tbody>
          {Object.entries(groupByVendors(props.persons)).map(([key, value]) => {
            return (
              <Fragment key={key}>
                <tr>
                  <td colSpan="5">
                    <b>{key}</b>
                  </td>
                </tr>
                {value.map((item, i) => (
                  <tr key={i}>
                    <td className="text-left">
                      <ReactImageFallback
                        src={Auth.imageCheck(item.imageURL)}
                        fallbackImage={Auth.getapiurl() + '/noimage.png'}
                        initialImage={Auth.getapiurl() + '/loading.gif'}
                        alt="cool image should be here"
                        className="my-image"
                      />
                    </td>
                    <td className="text-left">{item.name}</td>
                    <td className="text-right">{item.quantity}</td>
                    <td className="text-right">
                      {props.currency} {item.unitprice}
                    </td>
                    <td className="text-right">
                      {props.currency} {item.price}
                    </td>
                  </tr>
                ))}
                <tr className="noBorder">
                  <td colSpan="3"></td>
                  <td align="right">
                    <b>Sub-total by {key}</b>
                  </td>
                  <td align="right">
                    <b>
                      {props.currency} {sumBy(value, 'price').toFixed(2)}
                    </b>
                  </td>
                </tr>
              </Fragment>
            );
          })}
          {props.coupon_details1}
          {props.coupon_details2}
          <tr>
            <td colSpan="4" className="text-right">
              <b>Sub-Total</b>
            </td>
            <td className="text-right">
              <b>
                {props.currency} {props.sub_total.toFixed(2)}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="text-right">
              <b>Service Fee</b>
            </td>
            <td className="text-right">
              <b>
                {props.currency} {props.service_fee.toFixed(2)}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="text-right">
              <b>Flat Shipping Rate</b>
            </td>
            <td className="text-right">
              <b>
                {props.currency} {parseFloat(props.delivery_charges).toFixed(2)}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="text-right">
              <b>Food Forward</b>
            </td>
            <td className="text-right">
              <b>
                {props.currency} {parseFloat(props.food_forward).toFixed(2)}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="text-right">
              <b>Tip</b>
            </td>
            <td className="text-right">
              <b>
                {props.currency} {parseFloat(props.tips).toFixed(2)}
              </b>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="text-right">
              <b>Total</b>
            </td>
            <td className="text-right">
              <b>
                {props.currency} {props.fields.net_amount}
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ShoppingList;
