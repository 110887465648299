import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Category from './category/Category';
import CategoryAdd from './category/CategoryAdd';
import CategoryEdit from './category/CategoryEdit';
// import Ckeditor from "./Ckeditor";
import Cmspages from './cmspages/Cmspages';
import Cmspagesadd from './cmspages/Cmspagesadd';
import Cmspagesedit from './cmspages/Cmspagesedit';

import Slider from './cmspages/Slider/Slider';
import Slideradd from './cmspages/Slider/Slideradd';
import Slideredit from './cmspages/Slider/Slideredit';
import Contact from './Contact';

// import Coupon from './coupon/Coupon';
// import CouponAdd from './coupon/CouponAdd';
// import CouponEdit from './coupon/CouponEdit';
// import Couponexadd from './coupon/Couponexadd';
// import Couponexclusion from './coupon/Couponexclusion';
// import Couponexedit from './coupon/Couponexedit';

import Address from './customer/Address/Address';
import Editaddress from './customer/Address/Editaddress';
import Listaddress from './customer/Address/Listaddress';

import AddressType from './customer/AddressType/AddressType';
import AddressTypeadd from './customer/AddressType/AddressTypeadd';
import AddressTypeedit from './customer/AddressType/AddressTypeedit';

import AssignRole from './customer/AssignRole/AssignRole';
import AssignRoleadd from './customer/AssignRole/AssignRoleadd';
import AssignRoleedit from './customer/AssignRole/AssignRoleedit';

import Bilingaddress from './customer/Bilingaddress/Bilingaddress';
import BilingaddressEdit from './customer/Bilingaddress/BilingaddressEdit';
import BilingaddressList from './customer/Bilingaddress/BilingaddressList';

import Cards from './customer/Cards/Cards';
import Cardsadd from './customer/Cards/Cardsadd';
import Cardsedit from './customer/Cards/Cardsedit';

import Customer from './customer/Customer';
import CustomerAdd from './customer/CustomerAdd';
import CustomerEdit from './customer/CustomerEdit';

import Lcard from './customer/Lcard/Lcard';
import Lcardadd from './customer/Lcard/Lcardadd';
import Lcardedit from './customer/Lcard/Lcardedit';

import CustomerRole from './customer/role/CustomerRole';
import CustomerRoleadd from './customer/role/CustomerRoleadd';
import CustomerRoleedit from './customer/role/CustomerRoleedit';

import Wallet from './customer/Wallet/Wallet';
import WalletBalance from './customer/Wallet/WalletBalance';
// import WalletBalanceEdit from './customer/Wallet/WalletBalanceEdit';
import Dashboard from './Dashboard';

import Dietarytype from './dietarytype/Dietarytype';
import Dietarytypeadd from './dietarytype/Dietarytypeadd';
import Dietarytypeedit from './dietarytype/Dietarytypeedit';

import Geo from './Geo';
import Home from './Home';
import Location from './location/Location';
import Locationadd from './location/Locationadd';
import Locationedit from './location/Locationedit';
import Login from './Login';
import Mall from './mall/Mall';
import Malladd from './mall/Malladd';
import Malledit from './mall/Malledit';
import Auth from './modules/Auth';
import Invoice from './order/Invoice';

import Order from './order/Order';
// import Orderaddpayment from './order/Orderaddpayment';
import Orderedit from './order/Orderedit';
import Orderstatus from './order/Orderstatus';
import OrderstatusAdd from './order/OrderstatusAdd';
import OrderstatusEdit from './order/OrderstatusEdit';
import Orderview from './order/Orderview';

import PaymentMethod from './payment/PaymentMethod';
import PaymentMethodadd from './payment/PaymentMethodadd';
import PaymentMethodedit from './payment/PaymentMethodedit';
import Permission from './permission/Permission';
import PermissionAdd from './permission/PermissionAdd';
import PermissionEdit from './permission/PermissionEdit';

import Importexport from './product/import-export/ImportExport';

import Product from './product/Product';

import Productdietary from './product/product-dietary/Productdietary';
import Productdietaryadd from './product/product-dietary/Productdietaryadd';
import Productdietaryedit from './product/product-dietary/Productdietaryedit';

import Productreplacement from './product/product-replacement/Productreplacement';
import Productreplacementadd from './product/product-replacement/Productreplacementadd';
import Productreplacementedit from './product/product-replacement/Productreplacementedit';
import ProductEdit from './product/ProductEdit';

// Recipe
import Recipe from './recipe/Recipe';
import RecipeEdit from './recipe/RecipeEdit';
import RecipeView from './recipe/RecipeView';

// Deal
import Deal from './deal';
import DealEdit from './deal/Edit';
import DealView from './deal/View';

import Stock from './product/stock/Stock';
import Stockadd from './product/stock/Stockadd';
import Stockedit from './product/stock/Stockedit';
import Trashproduct from './product/Trashproduct';
import ProtectedRoute from './ProtectedRoute';
import Refercodeedit from './referfriend/Refercodeedit';
import Refercodelist from './referfriend/Refercodelist';

import Referfriend from './referfriend/Referfriend';
import Access from './role/Access';
import AccessEdit from './role/AccessEdit';
import Role from './role/Role';
import Roleadd from './role/Roleadd';
import Roleedit from './role/Roleedit';

import Store from './store/Store';
import Storeadd from './store/Storeadd';
import Storeedit from './store/Storeedit';
import Stuff from './Stuff';

import Tag from './tag/Tag';
import Tagadd from './tag/Tagadd';
import Tagedit from './tag/Tagedit';
// import User from './users/User';
// import UserAdd from './users/UserAdd';
// import UserEdit from './users/UserEdit';
import Vendor from './vendor/Vendor';
import Vendoradd from './vendor/Vendoradd';
import Vendoredit from './vendor/Vendoredit';

const App = () => (
  <div>
    <AuthProvider>
      <Switch>
        <Route path="/geo" component={Geo} />
        <Route path="/Login" component={Login} />
        <Route exact path="/" component={Dashboard} />
        <Route path="/home" component={Home} />
        <ProtectedRoute path="/Stuff" component={Stuff} />
        <ProtectedRoute path="/Contact" component={Contact} />
        <ProtectedRoute path="/role" component={Role} />
        <ProtectedRoute path="/roleadd" component={Roleadd} />
        <ProtectedRoute path="/roleedit/:id" component={Roleedit} />
        <ProtectedRoute path="/permission" component={Permission} />
        <ProtectedRoute path="/permissionAdd" component={PermissionAdd} />
        <ProtectedRoute path="/permissionEdit/:id" component={PermissionEdit} />
        <ProtectedRoute path="/role-access/permission/:id" component={Access} />
        <ProtectedRoute path="/category" component={Category} />
        <ProtectedRoute path="/categoryadd" component={CategoryAdd} />
        <ProtectedRoute path="/categoryedit/:id" component={CategoryEdit} />
        <ProtectedRoute path="/accessedit/:id/:roleid" component={AccessEdit} />
        <ProtectedRoute path="/vendor" component={Vendor} />
        <ProtectedRoute path="/vendoradd" component={Vendoradd} />
        <ProtectedRoute path="/vendoredit/:id" component={Vendoredit} />

        <ProtectedRoute path="/location" component={Location} />
        <ProtectedRoute path="/locationadd" component={Locationadd} />
        <ProtectedRoute path="/locationedit/:id" component={Locationedit} />

        {/* <ProtectedRoute path="/user" component={User} />
        <ProtectedRoute path="/useradd" component={UserAdd} />
        <ProtectedRoute path="/useredit/:id" component={UserEdit} /> */}

        <ProtectedRoute path="/mall" component={Mall} />
        <ProtectedRoute path="/malladd" component={Malladd} />
        <ProtectedRoute path="/malledit/:id" component={Malledit} />

        <ProtectedRoute path="/store" component={Store} />
        <ProtectedRoute path="/storeadd" component={Storeadd} />
        <ProtectedRoute path="/storeedit/:id" component={Storeedit} />

        <ProtectedRoute path="/tag" component={Tag} />
        <ProtectedRoute path="/tagadd" component={Tagadd} />
        <ProtectedRoute path="/tagedit/:id" component={Tagedit} />

        <ProtectedRoute path="/customers" component={Customer} />
        <ProtectedRoute path="/customeradd" component={CustomerAdd} />
        <ProtectedRoute path="/customeredit/:id" component={CustomerEdit} />

        <ProtectedRoute path="/product" component={Product} />
        <ProtectedRoute path="/product-add" component={ProductEdit} />
        <ProtectedRoute path="/product-edit/:id" component={ProductEdit} />
        <ProtectedRoute path="/trashproducts" component={Trashproduct} />

        <ProtectedRoute path="/customer-role" component={CustomerRole} />
        <ProtectedRoute path="/customer-roleadd" component={CustomerRoleadd} />
        <ProtectedRoute path="/customer-roleedit/:id" component={CustomerRoleedit} />

        <ProtectedRoute path="/customer-lcard" component={Lcard} />
        <ProtectedRoute path="/customer-lcardadd" component={Lcardadd} />
        <ProtectedRoute path="/customer-lcardedit/:id" component={Lcardedit} />

        <ProtectedRoute path="/customer-addresstype" component={AddressType} />
        <ProtectedRoute path="/customer-addresstypeadd" component={AddressTypeadd} />
        <ProtectedRoute path="/customer-addresstypeedit/:id" component={AddressTypeedit} />

        <ProtectedRoute path="/address/:id" component={Address} />
        <ProtectedRoute path="/listaddress/:id" component={Listaddress} />
        <ProtectedRoute path="/editaddress/:id" component={Editaddress} />

        <ProtectedRoute path="/cards/:id" component={Cards} />
        <ProtectedRoute path="/addcard/:id" component={Cardsadd} />
        <ProtectedRoute path="/editcard/:id" component={Cardsedit} />

        <ProtectedRoute path="/bilingaddress/:id" component={Bilingaddress} />
        <ProtectedRoute path="/bilinglistaddress/:id" component={BilingaddressList} />
        <ProtectedRoute path="/bilingeditaddress/:id" component={BilingaddressEdit} />

        <ProtectedRoute path="/assignrole/:id" component={AssignRole} />
        <ProtectedRoute path="/assignroleadd/:id" component={AssignRoleadd} />
        <ProtectedRoute path="/assignroleedit/:id" component={AssignRoleedit} />

        <ProtectedRoute path="/product-stock/:id" component={Stock} />
        <ProtectedRoute path="/product-stock-add/:id" component={Stockadd} />
        <ProtectedRoute path="/product-stock-edit/:id" component={Stockedit} />

        <ProtectedRoute path="/dietary-type" component={Dietarytype} />
        <ProtectedRoute path="/dietary-type-add" component={Dietarytypeadd} />
        <ProtectedRoute path="/dietary-type-edit/:id" component={Dietarytypeedit} />

        <ProtectedRoute path="/orders" component={Order} />
        <ProtectedRoute path="/order-view/:id/:custid/:bookid" component={Orderview} />
        <ProtectedRoute path="/order-edit/:id" component={Orderedit} />
        <ProtectedRoute path="/invoice/:id/:custid/:bookid" component={Invoice} />

        <ProtectedRoute path="/orderstatus" component={Orderstatus} />
        <ProtectedRoute path="/orderstatus-edit/:id" component={OrderstatusEdit} />
        <ProtectedRoute path="/orderstatus-add" component={OrderstatusAdd} />
        {/* <ProtectedRoute path="/order-addpayment/:orderid/:cid" component={Orderaddpayment} /> */}

        {/* <ProtectedRoute path="/coupons" component={Coupon} />
        <ProtectedRoute path="/coupons-add" component={CouponAdd} />
        <ProtectedRoute path="/coupons-edit/:id" component={CouponEdit} />
        <ProtectedRoute path="/coupons-exclude/:id" component={Couponexclusion} />
        <ProtectedRoute path="/couponsexadd/:id" component={Couponexadd} />
        <ProtectedRoute path="/couponsexedit/:id" component={Couponexedit} /> */}

        <ProtectedRoute path="/product-replacement/:id" component={Productreplacement} />
        <ProtectedRoute path="/product-replacement-add/:id" component={Productreplacementadd} />
        <ProtectedRoute path="/product-replacement-edit/:id" component={Productreplacementedit} />

        <ProtectedRoute path="/product-dietary/:id" component={Productdietary} />
        <ProtectedRoute path="/product-dietary-add/:id" component={Productdietaryadd} />
        <ProtectedRoute path="/product-dietary-edit/:id" component={Productdietaryedit} />

        <ProtectedRoute path="/recipe" component={Recipe} />
        <ProtectedRoute path="/recipe-add" component={RecipeEdit} />
        <ProtectedRoute path="/recipe-edit/:id" component={RecipeEdit} />
        <ProtectedRoute path="/recipeview/:id" component={RecipeView} />

        <ProtectedRoute path="/deals" component={Deal} />
        <ProtectedRoute path="/deal-add" component={DealEdit} />
        <ProtectedRoute path="/deal-edit/:id" component={DealEdit} />
        <ProtectedRoute path="/dealview/:id" component={DealView} />

        <ProtectedRoute path="/payment-method" component={PaymentMethod} />
        <ProtectedRoute path="/payment-method-add" component={PaymentMethodadd} />
        <ProtectedRoute path="/payment-method-edit/:id" component={PaymentMethodedit} />

        <ProtectedRoute path="/import-export" component={Importexport} />

        <ProtectedRoute path="/wallet/:id" component={Wallet} />
        <ProtectedRoute path="/wallet" component={Wallet} />
        <ProtectedRoute path="/wallet-balance/:id" component={WalletBalance} />
        {/* <ProtectedRoute path="/wallet-balance-edit/:id" component={WalletBalanceEdit} /> */}

        <ProtectedRoute path="/cmspages" component={Cmspages} />
        <ProtectedRoute path="/cmspage-add" component={Cmspagesadd} />
        <ProtectedRoute path="/cmspage-edit/:id" component={Cmspagesedit} />

        <ProtectedRoute path="/slider" component={Slider} />
        <ProtectedRoute path="/slider-add" component={Slideradd} />
        <ProtectedRoute path="/slider-edit/:id" component={Slideredit} />

        <ProtectedRoute path="/referfriend" component={Referfriend} />
        <ProtectedRoute path="/referfriend-list" component={Refercodelist} />
        <ProtectedRoute path="/referfriend-setting" component={Refercodeedit} />
      </Switch>
    </AuthProvider>
  </div>
);

render(
  <BrowserRouter basename={Auth.getBasename()}>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
