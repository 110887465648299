import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './../../Header';
import Auth from './../../modules/Auth';

class Productdietaryadd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
      Dietary: [],
    };

    this.getDietary();
    this.handleChange = this.handleChange.bind(this);

    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  getDietary() {
    axios
      .post(`${Auth.getapiurl()}/admin/getDietary_types`, {
        session: Auth.getToken(),
      })
      .then((res) => {
        const Dietary = res.data;

        this.setState({ Dietary });
      });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/addReplacementDietaryByreplacementID`, {
          session: Auth.getToken(),
          replacementID: this.props.match.params.id,
          dietaryID: this.state.fields.dietaryID,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/product-dietary/' + this.props.match.params.id);
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['dietaryID']) {
      formIsValid = false;
      errors['dietaryID'] = '*Please select Dietary Type.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Replacement Dietary</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Dietary Type: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.dietaryID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="dietaryID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.Dietary.map((dietary_item) => (
                              <option value={dietary_item.ID}>{dietary_item.name}</option>
                            ))}
                          </select>
                          <div className="errorMsg">{this.state.errors.dietaryID}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to={'/product-dietary/' + this.props.match.params.id}>
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Productdietaryadd;
