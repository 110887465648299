import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class CustomerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
      userbyid: '',
      roles: [],
    };

    console.log(this.props.match.params.id);

    this.getRoles();
    this.getcustomerbyid();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  getcustomerbyid() {
    axios
      .post(`${Auth.getapiurl()}/admin/getcustomerbyid`, {
        session: Auth.getToken(),
        customerID: this.props.match.params.id
      })
      .then((res) => {
        console.log(res);
        const fields = res.data[0];
        this.setState({ fields });
      });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      console.log(this.state.fields.fname);

      axios
        .post(`${Auth.getapiurl()}/admin/updatecustomer`, {
          session: Auth.getToken(),
          fname: this.state.fields.fname,
          sname: this.state.fields.sname,
          email: this.state.fields.email,
          altEmail: this.state.fields.altEmail,
          phone: this.state.fields.phone,
          altPhone: this.state.fields.altPhone,
          customerID: this.props.match.params.id,
          password: this.state.fields.password,
          roleID: this.state.fields.roleID
        })

        .then((res) => {
          console.log('response ' + res.data);
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });

          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/customers');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['fname']) {
      formIsValid = false;
      errors['fname'] = '*Please enter first name.';
    }

    if (typeof fields['fname'] !== 'undefined') {
      if (!fields['fname'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['fname'] = '*Please enter alphabet characters only.';
      }
    }

    if (!fields['sname']) {
      formIsValid = false;
      errors['sname'] = '*Please enter last name.';
    }

    if (typeof fields['sname'] !== 'undefined') {
      if (!fields['sname'].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors['sname'] = '*Please enter alphabet characters only.';
      }
    }

    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = '*Please enter email-ID.';
    }

    if (typeof fields['email'] !== 'undefined') {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields['email'])) {
        formIsValid = false;
        errors['email'] = '*Please enter valid email-ID.';
      }
    }

    if (!fields['phone']) {
      formIsValid = false;
      errors['phone'] = '*Please enter phone no.';
    }

    if (typeof fields['phone'] !== 'undefined') {
      if (!fields['phone'].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors['phone'] = '*Please enter valid phone no.';
      }
    }

    // if (!fields['password']) {
    //   formIsValid = false;
    //   errors['password'] = '*Please enter password.';
    // }

    // if (typeof fields['password'] !== 'undefined') {
    //   if (!fields['password'].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
    //     formIsValid = false;
    //     errors['password'] =
    //       '*Please enter secure and strong password. Use Capital and small letter,special character and number';
    //   }
    // }

    /* if (!fields["roleID"]) {
        formIsValid = false;
        errors["roleID"] = "*Please select role.";
      }
	  */

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  getRoles() {
    axios.post(`${Auth.getapiurl()}/admin/getRoles`, { session: Auth.getToken() }).then((res) => {
      //console.log(res);
      const roles = res.data;
      this.setState({ roles });
    });
  }

  /* handleChange = event => {
     this.setState({ name: event.target.value });
   }

   handleSubmit = event => {
     event.preventDefault();


     axios.post(`http://192.168.1.208:5000/admin/addRole`, { session: store.get('id_token'),name: this.state.name })
       .then(res => {
        this.props.history.push("/role");
       })



   }*/

  render() {
    console.log(this.state.fields.roleID);
    const fields = this.state.fields;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Customer</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          First Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.fname ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="fname"
                            value={this.state.fields.fname}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.fname}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Last Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.sname ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="sname"
                            value={this.state.fields.sname}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.sname}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Email: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.email ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={this.state.fields.email}
                            onChange={this.handleChange}
                            disabled
                          />
                          <div className="errorMsg">{this.state.errors.email}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Alt Email: </label>

                        <div className={this.state.errors.altEmail ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="altEmail"
                            name="altEmail"
                            value={this.state.fields.altEmail}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Phone: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.phone ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            name="phone"
                            value={this.state.fields.phone}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.phone}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Alt Phone: </label>

                        <div className={this.state.errors.altPhone ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="altPhone"
                            name="altPhone"
                            value={this.state.fields.altPhone}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      {/* <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Password: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.password ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={this.state.fields.password}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.password}</div>
                        </div>
                      </div> */}

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Reference Code: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <h5>{this.state.fields.ownRefCode}</h5>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Device OS: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <h5>{this.state.fields.device_os}</h5>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Device Name: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <h5>{this.state.fields.device_name}</h5>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Device ID: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <h5>{this.state.fields.device_id}</h5>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          User Agent: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <h5>{this.state.fields.user_agent}</h5>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          IP Address: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <h5>{this.state.fields.ip_address}</h5>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Device App Version: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <h5>{this.state.fields.device_app_version}</h5>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Last Access Time: <span className="red"></span>
                        </label>
                        <div className="col-sm-6">
                          <h5>{this.state.fields.last_access_datetime}</h5>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/customers">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CustomerEdit;
