import React, { Component } from 'react';
import store from 'store';

import axios from 'axios';
import Header from '../Header';
import { Redirect, Link } from 'react-router-dom';
//import Pagination from '../component/Pagination';
import Auth from '../modules/Auth';
import Loader from 'react-loader';
import Pagination from 'react-js-pagination';

class Location extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
    this.addnewhandleclick = this.addnewhandleclick.bind(this);

    this.getlocations(1, Auth.Itemperpagecount());

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      status: '',
      loaded: false,
      activePage: 1,
      TotalRecords: Auth.Itemperpagecount(),
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  handlePageChange(pageNumber) {
    const perpage = Auth.Itemperpagecount();

    if (this.state.stype == 1) {
      this.getsearch(pageNumber, perpage);
    } else {
      this.getlocations(pageNumber, perpage);
    }

    this.setState({ activePage: pageNumber });
  }

  getlocations(pageNumber, perpage) {
    axios
      .post(`${Auth.getapiurl()}/admin/getLocations`, {
        session: Auth.getToken(),
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        this.setState({ loaded: true });
        const persons = res.data.results;
        const TotalRecords = res.data.Records;
        this.setState({ persons });
        this.setState({ TotalRecords });
      });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/locationadd');
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeLocation`, { session: Auth.getToken(), location_id: itemId })
      .then((response) => {
        this.getlocations(1, Auth.Itemperpagecount());
      });
  };

  getsearch = (pageNumber, perpage) => {
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchLocation`, {
        session: Auth.getToken(),
        search: this.state.search,
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        const persons = res.data;
        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
        } else {
          const persons = res.data.results;
          const TotalRecords = res.data.Records;
          this.setState({ persons });
          this.setState({ TotalRecords });
          this.setState({ status: '204' });
          this.setState({ stype: 1 });
        }
      });
  };

  handlesearchChange = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      this.setState(
        {
          search: event.target.value,
        },
        () => {
          if (this.state.search && this.state.search.length > 1) {
            //if (this.state.search.length % 2 === 0) {
            this.getsearch(1, Auth.Itemperpagecount());
            //}
          } else {
            this.setState({ status: '204' });
            this.getlocations(1, Auth.Itemperpagecount());
          }
        }
      );
    }
  };

  render() {
    let paginate;
    if (this.state.TotalRecords > Auth.Itemperpagecount()) {
      paginate = (
        <Pagination
          prevPageText="Previous"
          nextPageText="Next"
          firstPageText="First"
          lastPageText="Last"
          activePage={this.state.activePage}
          itemsCountPerPage={Auth.Itemperpagecount()}
          totalItemsCount={this.state.TotalRecords}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange}
        />
      );
    }

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const status = this.state.status;
    const status_msg = this.state.status_msg;
    let table;
    if (status == '203') {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>location Id</td>
                <td>location Name</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>location Id</td>
                <td>location Name</td>
                <td>Action</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>{status_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Location Id</td>
                <td>Location Name</td>

                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Location Id</td>
                <td>Location Name</td>

                <td>Action</td>
              </tr>
            </tfoot>

            <tbody>
              {this.state.persons.map((item) => (
                <tr key={item.ID}>
                  <td>{item.ID}</td>
                  <td>{item.name}</td>
                  <td>
                    <Link to={'/locationedit/' + item.ID} className="btn btn-info">
                      <i className="fa fa-edit"></i>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-danger leftside"
                      onClick={() => {
                        if (window.confirm('Delete the item?')) {
                          this.handleDelete(item.ID);
                        }
                      }}
                    >
                      <i className="fa fa-trash"></i>{' '}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">Locations</h3>
                    </div>
                    <div className="col-md-4 text-right">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here.."
                        onKeyDown={this.handlesearchChange}
                      />
                    </div>
                    <div className="col-md-2 text-right">
                      <button className="btn btn-success" onClick={this.addnewhandleclick}>
                        <i className="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="row">
                      {table}
                      {paginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Location;
