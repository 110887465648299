import React, { Component } from 'react';
import axios from 'axios';
import Header from '../Header';
import { Redirect, Link } from 'react-router-dom';
import Auth from '../modules/Auth';
import ReactImageFallback from 'react-image-fallback';
import './Recipe.css';

class RecipeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipe: {
        name: '',
        description: '',
        recipeImage: '',
        category: 0,
        createdBy: '',
        serving: 0,
        totalTime: 0,
        slug: '',
        sort: '',
        instructions: '',
        ingredients: '',
      },
      products: [],
    };

    this.getrecipesbyid();
  }

  getrecipesbyid() {
    axios
      .post(`${Auth.getapiurl()}/admin/getRecipebyID`, {
        session: Auth.getToken(),
        recipe_id: this.props.match.params.id,
      })
      .then((res) => {
        const recipe = res.data.results[0];
        const products = res.data.results[0].products;
        this.setState({ recipe, products });
      });
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>

        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-6">
                      <h3 className="box-title">
                        <i className="fa fa-registered"></i> Recipe#
                        {this.props.match.params.id}
                      </h3>
                    </div>

                    <div className="col-md-3 text-right">
                      <Link to={'/recipe-edit/' + this.props.match.params.id} className="btn btn-success">
                        <i className="fa fa-edit">Edit</i>
                      </Link>
                    </div>

                    <div className="col-md-3 text-right">
                      <Link to={'/recipe'} className="btn btn-success">
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                      </Link>
                    </div>
                  </div>

                  <div className="box-body" id="divToPrint">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h3 className="panel-title">
                              <i className="fa fa-cutlery"></i> Recipe Table
                            </h3>
                          </div>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Name</td>
                                <td>{this.state.recipe.name}</td>
                              </tr>
                              <tr>
                                <td>Description</td>
                                <td>{this.state.recipe.description} </td>
                              </tr>

                              <tr>
                                <td>Instructions</td>
                                <td>{this.state.recipe.instructions}</td>
                              </tr>

                              <tr>
                                <td>Ingredients</td>
                                <td>{this.state.recipe.ingredients}</td>
                              </tr>

                              <tr>
                                <td>Slug</td>
                                <td>{this.state.recipe.slug}</td>
                              </tr>

                              <tr>
                                <td>Sort</td>
                                <td>{this.state.recipe.sort}</td>
                              </tr>

                              <tr>
                                <td>Serving</td>
                                <td>{this.state.recipe.serving} </td>
                              </tr>

                              <tr>
                                <td>Total Time</td>
                                <td>{this.state.recipe.totalTime} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h3 className="panel-title">
                              <i className="fa fa-camera"></i> Image
                            </h3>
                          </div>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>
                                  <ReactImageFallback
                                    src={Auth.imageCheck(this.state.recipe.recipeImage)}
                                    fallbackImage={Auth.getapiurl() + '/noimage.png'}
                                    initialImage={Auth.getapiurl() + '/loading.gif'}
                                    alt="cool image should be here"
                                    id="recipeImage"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-9">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h3 className="panel-title">
                              <i className="fa fa-product-hunt"></i> Recipe Items
                            </h3>
                          </div>
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <td>Image</td>
                                <td>Product</td>
                                <td>vendor</td>
                                <td>Price</td>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.products.map((item) => (
                                <tr key={item.value}>
                                  <td className="text-left">
                                    <ReactImageFallback
                                      src={Auth.imageCheck(item.imageURL)}
                                      fallbackImage={Auth.getapiurl() + '/noimage.png'}
                                      initialImage={Auth.getapiurl() + '/loading.gif'}
                                      alt="cool image should be here"
                                      className="my-image"
                                    />
                                  </td>

                                  <td>{item.label}</td>
                                  <td>{item.vendor}</td>
                                  <td>{item.price}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default RecipeView;
