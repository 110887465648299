import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class Storeadd extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      location: [],
      vendor: [],
      mall: [],
      errorMessage: '',
      status_code: '',
    };

    this.getLocation();
    this.getVendor();
    this.getMall();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  getLocation() {
    axios.post(`${Auth.getapiurl()}/admin/getLocations`, { session: Auth.getToken() }).then((res) => {
      const location = res.data;
      console.log(location);

      this.setState({ location });
    });
  }

  getVendor() {
    axios.post(`${Auth.getapiurl()}/admin/getVendors`, { session: Auth.getToken() }).then((res) => {
      const vendor = res.data;
      console.log(vendor);

      this.setState({ vendor });
    });
  }

  getMall() {
    axios.post(`${Auth.getapiurl()}/admin/getMalls`, { session: Auth.getToken() }).then((res) => {
      const mall = res.data;
      console.log(mall);

      this.setState({ mall });
    });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields['name'] = '';
      fields['slug'] = '';
      fields['latitude'] = '';
      fields['longitude'] = '';
      fields['locationID'] = '';
      fields['mallID'] = '';
      fields['vendorID'] = '';
      fields['delivery_method'] = '';
      fields['service_charge'] = '';
      fields['exclude_from_del'] = '';
      fields['delivery_charge_type'] = '';
      fields['fix'] = '';
      fields['per_km'] = '';
      fields['min_del_charge'] = '';

      this.setState({ fields: fields });

      axios
        .post(`${Auth.getapiurl()}/admin/addStore`, {
          session: Auth.getToken(),
          name: this.state.fields.name,
          slug: this.state.fields.slug,
          latitude: this.state.fields.latitude,
          longitude: this.state.fields.longitude,
          locationID: this.state.fields.locationID,
          mallID: this.state.fields.mallID,
          vendorID: this.state.fields.vendorID,
          delivery_method: this.state.fields.delivery_method,
          service_charge: this.state.fields.service_charge,
          exclude_from_del: this.state.fields.exclude_from_del,
          delivery_charge_type: this.state.fields.delivery_charge_type,
          fix: this.state.fields.fix,
          per_km: this.state.fields.per_km,
          min_del_charge: this.state.fields.min_del_charge,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/store');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter store name.';
    }
    if (!fields['slug']) {
      formIsValid = false;
      errors['slug'] = '*Please enter slug.';
    }

    if (!fields['latitude']) {
      formIsValid = false;
      errors['latitude'] = '*Please enter latitude.';
    }

    if (!fields['longitude']) {
      formIsValid = false;
      errors['longitude'] = '*Please enter longitude.';
    }

    if (!fields['locationID']) {
      formIsValid = false;
      errors['locationID'] = '*Please Select Location.';
    }

    if (!fields['mallID']) {
      formIsValid = false;
      errors['mallID'] = '*Please Select Mall.';
    }

    if (!fields['vendorID']) {
      formIsValid = false;
      errors['vendorID'] = '*Please Select vendor.';
    }

    if (!fields['delivery_method']) {
      formIsValid = false;
      errors['delivery_method'] = '*Please Select Delivery Method.';
    }
    if (!fields['service_charge']) {
      formIsValid = false;
      errors['service_charge'] = '*Please Select Service Charge.';
    }
    if (!fields['delivery_charge_type']) {
      formIsValid = false;
      errors['delivery_charge_type'] = '*Please Select Delivery Charge Type.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    let method, fields, delivery_charge_type;
    let min_charge;

    if (this.state.fields.delivery_charge_type == 'fix') {
      method = (
        <div className="form-group">
          <label className="col-sm-2 control-label">
            Fix: <span className="red"></span>
          </label>

          <div className={this.state.errors.fix ? 'col-sm-6 has-error' : 'col-sm-6'}>
            <input
              type="text"
              className="form-control"
              id="inputstore"
              name="fix"
              value={this.state.fields.fix}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.fix}</div>
          </div>
        </div>
      );
    } else if (this.state.fields.delivery_charge_type == 'variable') {
      method = (
        <div className="form-group">
          <label className="col-sm-2 control-label">
            Per KM: <span className="red"></span>
          </label>

          <div className={this.state.errors.per_km ? 'col-sm-6 has-error' : 'col-sm-6'}>
            <input
              type="text"
              className="form-control"
              id="inputstore"
              name="per_km"
              value={this.state.fields.per_km}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.per_km}</div>
          </div>
        </div>
      );

      min_charge = (
        <div className="form-group">
          <label className="col-sm-2 control-label">
            Minimum Delivery Charge: <span className="red"></span>
          </label>

          <div className={this.state.errors.min_del_charge ? 'col-sm-6 has-error' : 'col-sm-6'}>
            <input
              type="text"
              className="form-control"
              id="inputstore"
              name="min_del_charge"
              value={this.state.fields.min_del_charge}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.min_del_charge}</div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Store</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">Vendor:</label>

                        <div className={this.state.errors.vendorID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="vendorID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.vendor.map((vendoritem) => (
                              <option value={vendoritem.ID}>{vendoritem.name}</option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.vendorID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Mall:</label>

                        <div className={this.state.errors.mallID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="mallID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.mall.map((mallitem) => (
                              <option value={mallitem.ID}>{mallitem.name}</option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.mallID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">Location:</label>

                        <div className={this.state.errors.locationID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="locationID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.location.map((locationitem) => (
                              <option value={locationitem.ID}>{locationitem.name}</option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.locationID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Store Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Store Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Slug: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.slug ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Store Slug"
                            name="slug"
                            value={this.state.fields.slug}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.slug}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Latitude: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.latitude ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Latitude"
                            name="latitude"
                            value={this.state.fields.latitude}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.latitude}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Longitude: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.longitude ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Longitude"
                            name="longitude"
                            value={this.state.fields.longitude}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.longitude}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Delivery Method: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.delivery_method ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="delivery_method" className="form-control" onChange={this.handleChange}>
                            <option value="none">None</option>
                            <option value="delivery">Delivery</option>
                            <option value="click_and_collect">Click and Collect</option>
                            <option value="both">Both</option>
                          </select>

                          <div className="errorMsg">{this.state.errors.delivery_method}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Exclude From OneCart Delivery : <span className="red"></span>
                        </label>

                        <div className={this.state.errors.exclude_from_del ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input type="checkbox" name="exclude_from_del" value="yes" onChange={this.handleChange} />
                          <div className="errorMsg">{this.state.errors.exclude_from_del}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Service Charge In % : <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.service_charge ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputstore"
                            placeholder="Service Charge"
                            name="service_charge"
                            value={this.state.fields.service_charge}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.service_charge}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Delivery Charge Type: <span className="red"></span>
                        </label>

                        <div className={this.state.errors.delivery_charge_type ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="delivery_charge_type" className="form-control" onChange={this.handleChange}>
                            <option value="">Select Charge Type</option>
                            <option value="fix" selected={this.state.fields.delivery_charge_type == 'fix'}>
                              Fix
                            </option>
                            <option value="variable" selected={this.state.fields.delivery_charge_type == 'variable'}>
                              Variable
                            </option>
                          </select>

                          <div className="errorMsg">{this.state.errors.delivery_charge_type}</div>
                        </div>
                      </div>

                      {method}
                      {min_charge}

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/store">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Storeadd;
