import React, { Component } from 'react';
import store from 'store';

import axios from 'axios';
import Header from './../../Header';
import { Redirect, Link } from 'react-router-dom';
import Pagination from './../../component/Pagination';
import Auth from './../../modules/Auth';
import Loader from 'react-loader';

class Productreplacement extends Component {
  constructor(props) {
    super(props);
    //var exampleItems = [...Array(10).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
    this.addnewhandleclick = this.addnewhandleclick.bind(this);

    this.getreplacement();

    this.state = {
      //exampleItems: exampleItems,
      pageOfItems: [],
      persons: [],
      search: '',
      status_msg: '',
      status: '',
      loaded: false,
      Title: '',
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  getreplacement() {
    axios
      .post(`${Auth.getapiurl()}/admin/getReplacementByProductID`, {
        session: Auth.getToken(),
        productID: this.props.match.params.id,
      })
      .then((res) => {
        this.setState({ loaded: true });
        const persons = res.data.results;
        const Title = res.data.product_name;

        if (res.data.status == '203') {
          this.setState({ status_msg: res.data.error });
          this.setState({ status: res.data.status });
          this.setState({ Title: Title });
        } else {
          this.setState({ persons });
          this.setState({ Title: Title });
        }
      });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/product-replacement-add/' + this.props.match.params.id);
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeReplacementByStore`, { session: Auth.getToken(), replacementID: itemId })
      .then((response) => {
        this.getreplacement();
      });
  };

  handlesearchChange = (event) => {
    this.setState(
      {
        search: event.target.value,
      },
      () => {
        if (this.state.search && this.state.search.length > 1) {
          if (this.state.search.length % 2 === 0) {
            this.getsearch();
          }
        } else {
          this.setState({ status: '204' });
          this.getreplacement();
        }
      }
    );
  };

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const status = this.state.status;
    const status_msg = this.state.status_msg;

    let table;
    if (status == '203') {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Original Product</td>
                <td>To Product</td>

                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Original Product</td>
                <td>To Product</td>
                <td>Action</td>
              </tr>
            </tfoot>
            <tbody>
              <tr>
                <td>{status_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      table = (
        <div className="col-md-12">
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Original Product</td>
                <td>To Product</td>
                <td>Action</td>
              </tr>
            </thead>

            <tfoot className="thead-dark">
              <tr>
                <td>Id</td>
                <td>Original Product</td>
                <td>To Product</td>
                <td>Action</td>
              </tr>
            </tfoot>

            <tbody>
              {this.state.pageOfItems.map((item) => (
                <tr key={item.replacementID}>
                  <td>{item.replacementID}</td>
                  <td>{item.origProdname}</td>
                  <td>{item.toProdname}</td>

                  <td>
                    <Link to={'/product-dietary/' + item.replacementID} className="btn btn-success">
                      <i className="fa fa-cutlery"></i> Dietary
                    </Link>

                    <Link to={'/product-replacement-edit/' + item.replacementID} className="btn btn-info leftside">
                      <i className="fa fa-edit"></i>
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-danger leftside"
                      onClick={() => {
                        if (window.confirm('Delete the item?')) {
                          this.handleDelete(item.replacementID);
                        }
                      }}
                    >
                      <i className="fa fa-trash"></i>{' '}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination items={this.state.persons} onChangePage={this.onChangePage} />
        </div>
      );
    }

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-10">
                      <h3 className="box-title">Replacement</h3>{' '}
                      <h5>
                        <b>{this.state.Title}</b>
                      </h5>{' '}
                    </div>

                    <div className="col-md-2 text-right">
                      <button className="btn btn-success" onClick={this.addnewhandleclick}>
                        <i className="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="row">{table}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Productreplacement;
