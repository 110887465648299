import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './../../Header';
import Auth from './../../modules/Auth';

class AssignRoleadd extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      errorMessage: '',
      status_code: '',
      persons: [],
    };
    this.getroles();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  getroles() {
    axios.post(`${Auth.getapiurl()}/admin/getcustomerRoles`, { session: Auth.getToken() }).then((res) => {
      const persons = res.data;
      this.setState({ persons });
    });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/addassignrole`, {
          customerID: this.props.match.params.id,
          roleID: this.state.fields.roleID,
        })
        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/assignrole/' + this.props.match.params.id);
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['roleID']) {
      formIsValid = false;
      errors['roleID'] = '*Please select role.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  /* handleChange = event => {
     this.setState({ name: event.target.value });
   }

   handleSubmit = event => {
     event.preventDefault();


     axios.post(`http://192.168.1.208:5000/admin/addRole`, { session: store.get('id_token'),name: this.state.name })
       .then(res => {
        this.props.history.push("/role");
       })



   }*/

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Role</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Select Role: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.roleID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="roleID" className="form-control" onChange={this.handleChange}>
                            <option value="">-- Select --</option>
                            {this.state.persons.map((role) => (
                              <option value={role.ID}>{role.name}</option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.roleID}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to={'/assignrole/' + this.props.match.params.id}>
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default AssignRoleadd;
