import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header';
import Auth from '../modules/Auth';

class Malledit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: [],
      fields: {},
      errorMessage: '',
      status_code: '',
      errors: {},
    };

    this.getLocation();
    this.getmallsbyid();
    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  getLocation() {
    axios
      .post(`${Auth.getapiurl()}/admin/getLocations`, {
        session: Auth.getToken(),
      })
      .then((res) => {
        const location = res.data;

        this.setState({ location });
      });
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['name']) {
      formIsValid = false;
      errors['name'] = '*Please enter mall name.';
    }

    if (!fields['latitude']) {
      formIsValid = false;
      errors['latitude'] = '*Please enter latitude.';
    }

    if (!fields['longitude']) {
      formIsValid = false;
      errors['longitude'] = '*Please enter longitude.';
    }

    if (!fields['locationID']) {
      formIsValid = false;
      errors['locationID'] = '*Please Select location.';
    }

    if (!fields['radius']) {
      formIsValid = false;
      errors['radius'] = '*Please enter mall radius.';
    }

    if (!fields['streams']) {
      formIsValid = false;
      errors['streams'] = '*Please enter mall stream.';
    }

    if (!fields['delivery_method']) {
      formIsValid = false;
      errors['delivery_method'] = '*Please Select Delivery Method.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submituserRegistrationForm(event) {
    event.preventDefault();

    if (this.validateForm()) {
      let mall_name;
      let mall_latitude;
      let mall_longitude;
      let mall_locationID;
      let mall_radius;
      let mall_streams;
      let mall_delivery_method;

      mall_name = this.state.fields.name;
      mall_latitude = this.state.fields.latitude;
      mall_longitude = this.state.fields.longitude;
      mall_locationID = this.state.fields.locationID;
      mall_radius = this.state.fields.radius;
      mall_streams = this.state.fields.streams;
      mall_delivery_method = this.state.fields.delivery_method;

      axios
        .post(`${Auth.getapiurl()}/admin/UpdateMall`, {
          session: Auth.getToken(),
          name: mall_name,
          latitude: mall_latitude,
          longitude: mall_longitude,
          locationID: mall_locationID,
          radius: mall_radius,
          streams: mall_streams,
          delivery_method: mall_delivery_method,
          mall_id: this.props.match.params.id,
        })
        .then((res) => {
          this.setState({
            errorMessage: res.data.error,
            status_code: res.data.status,
          });

          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/mall');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  getmallsbyid() {
    axios
      .post(`${Auth.getapiurl()}/admin/getMall`, {
        session: Auth.getToken(),
        mall_id: this.props.match.params.id,
      })
      .then((res) => {
        const fields = res.data[0];

        this.setState({ fields });
      });
  }

  render() {
    const fields = this.state.fields;

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Edit Mall</h3>
                  </div>

                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Location: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.locationID ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="locationID" className="form-control" onChange={this.handleChange}>
                            <option value="">--Select--</option>
                            {this.state.location.map((locationitem) => (
                              <option
                                value={locationitem.ID}
                                selected={this.state.fields.locationID == locationitem.ID}
                              >
                                {locationitem.name}
                              </option>
                            ))}
                          </select>

                          <div className="errorMsg">{this.state.errors.locationID}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Mall Name: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.name ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Mall Name"
                            name="name"
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.name}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Latitude: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.latitude ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Latitude"
                            name="latitude"
                            value={this.state.fields.latitude}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.latitude}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Longitude: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.longitude ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Longitude"
                            name="longitude"
                            value={this.state.fields.longitude}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.longitude}</div>
                        </div>
                      </div>

                      {/* radius */}
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Radius: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.radius ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Mall Radius"
                            name="radius"
                            value={this.state.fields.radius}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.radius}</div>
                        </div>
                      </div>

                      {/* Streams */}
                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Stream: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.streams ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <input
                            type="text"
                            className="form-control"
                            id="inputmall"
                            placeholder="Mall Stream"
                            name="streams"
                            value={this.state.fields.streams}
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{this.state.errors.streams}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="col-sm-2 control-label">
                          Delivery Method: <span className="red">*</span>
                        </label>

                        <div className={this.state.errors.delivery_method ? 'col-sm-6 has-error' : 'col-sm-6'}>
                          <select name="delivery_method" className="form-control" onChange={this.handleChange}>
                            <option value="none" selected={this.state.fields.delivery_method == 'none'}>
                              None
                            </option>
                            <option value="delivery" selected={this.state.fields.delivery_method == 'delivery'}>
                              Delivery
                            </option>
                            <option
                              value="click_and_collect"
                              selected={this.state.fields.delivery_method == 'click_and_collect'}
                            >
                              Click & Collect
                            </option>
                            <option value="both" selected={this.state.fields.delivery_method == 'both'}>
                              Both
                            </option>
                          </select>

                          <div className="errorMsg">{this.state.errors.delivery_method}</div>
                        </div>
                      </div>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/mall">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Malledit;
