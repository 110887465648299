import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import store from 'store';
import Header from './Header';

class Home extends Component {
  render() {
    if (!store.get('id_token')) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <h3 className="box-title">Home</h3>
                  </div>
                  <div className="box-body">
                    <div className="row">
                      <div className="col-md-8">
                        <p className="text-center">
                          <strong>This is Home page</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Home;
