import axios from 'axios';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from '../Header';
import Auth from '../modules/Auth';

class Referfriend extends Component {
  constructor() {
    super();
    this.state = {
      fields: {
        referralcode: '',
        referorder: '',
        cookietime: '',
        coupontype: '',
        couponamount: '',
        enable: '',
        coupontypeperson: '',
        couponamountperson: '',
        pagesharelink: '',
        fbshare: '',
        twitshare: '',
        whatsappshare: '',
        emailshare: '',
      },
      errors: {},
      errorMessage: '',
      status_code: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  renderAlert() {
    console.log(this.state.errorMessage);
    if (this.state.errorMessage && this.state.status_code == '203') {
      return (
        <div className="alert alert-danger">
          <strong>!Opps, </strong>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.status_code == '204') {
      return (
        <div className="alert alert-success">
          <strong>Success, </strong>
          {this.state.errorMessage}
        </div>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      axios
        .post(`${Auth.getapiurl()}/admin/addRefercode`, {
          session: Auth.getToken(),
          referralcode: this.state.fields.referralcode,
          referorder: this.state.fields.referorder,
          cookietime: this.state.fields.cookietime,
          coupontype: this.state.fields.coupontype,
          couponamount: this.state.fields.couponamount,
          enable: this.state.fields.enable,
          coupontypeperson: this.state.fields.coupontypeperson,
          couponamountperson: this.state.fields.couponamountperson,
          pagesharelink: this.state.fields.pagesharelink,
          fbshare: this.state.fields.fbshare,
          twitshare: this.state.fields.twitshare,
          whatsappshare: this.state.fields.whatsappshare,
          emailshare: this.state.fields.emailshare,
        })

        .then((res) => {
          this.setState({ errorMessage: res.data.error, status_code: res.data.status });
          if (res.data.status != '203') {
            setTimeout(
              function () {
                this.props.history.push('/referfriend-list');
              }.bind(this),
              2000
            );
          }
        });
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['referorder']) {
      formIsValid = false;
      errors['referorder'] = '*Please enter referorder.';
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div className="col-md-12 text-center"></div>

              <div className="col-md-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Add Referral Code</h3>
                  </div>
                  {this.renderAlert()}
                  <form className="form-horizontal has-validation-callback" onSubmit={this.submituserRegistrationForm}>
                    <div className="box-body">
                      <Tabs defaultIndex={0} onSelect={(index) => console.log(index)}>
                        <TabList>
                          <Tab>General</Tab>
                          <Tab>Coupon Setting</Tab>
                          <Tab>Referred Person Coupon Settings</Tab>
                          <Tab>My Account Test</Tab>
                          <Tab>Share option</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Minimum referral order: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.referorder ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="number"
                                className="form-control"
                                id="inputrole"
                                placeholder="Minimum referral order"
                                name="referorder"
                                value={this.state.fields.referorder}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.referorder}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Cookie Time: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.cookietime ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="number"
                                className="form-control"
                                id="inputrole"
                                name="cookietime"
                                placeholder="cookies in days"
                                value={this.state.fields.cookietime}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.cookietime}</div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Coupon Type: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.coupontype ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <select name="coupontype" className="form-control" onChange={this.handleChange}>
                                <option value="cart_discount">Cart Discount</option>
                                <option value="cart_percent_discount">Cart % Discount</option>
                                <option value="product_discount">Product Discount</option>
                                <option value="product_percent_discount">Product % Discount</option>
                              </select>
                              <div className="errorMsg">{this.state.errors.coupontype}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Coupon Amount: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.couponamount ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="number"
                                className="form-control"
                                id="inputrole"
                                name="couponamount"
                                placeholder="Discount amount %"
                                value={this.state.fields.couponamount}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.couponamount}</div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">Enable: </label>

                            <div className={this.state.errors.enable ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <lable>
                                <input
                                  type="checkbox"
                                  className="checkbox-inline"
                                  id="inputrole"
                                  name="enable"
                                  value="1"
                                  onChange={this.handleChange}
                                />{' '}
                                Check to enable. Coupon code will be sent to their email.
                              </lable>

                              <div className="errorMsg">{this.state.errors.enable}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Coupon Type: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.coupontypeperson ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <select name="coupontypeperson" className="form-control" onChange={this.handleChange}>
                                <option value="cart_discount">Cart Discount</option>
                                <option value="cart_percent_discount">Cart % Discount</option>
                                <option value="product_discount">Product Discount</option>
                                <option value="product_percent_discount">Product % Discount</option>
                              </select>
                              <div className="errorMsg">{this.state.errors.coupontypeperson}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Coupon Amount: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.couponamountperson ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="number"
                                className="form-control"
                                id="inputrole"
                                name="couponamountperson"
                                placeholder="Discount Amount in %"
                                value={this.state.fields.couponamountperson}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.couponamountperson}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Referral code : <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.referralcode ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="referralcode"
                                placeholder="Referral code"
                                value={this.state.fields.referralcode}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.referralcode}</div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Page share link: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.pagesharelink ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="pagesharelink"
                                placeholder="Page share link"
                                value={this.state.fields.pagesharelink}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.pagesharelink}</div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel>
                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Facebook Share Link: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.fbshare ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="fbshare"
                                placeholder="Facebook share link"
                                value={this.state.fields.fbshare}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.fbshare}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Twitter Share Link: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.twitshare ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="twitshare"
                                placeholder="Twitter share link"
                                value={this.state.fields.twitshare}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.twitshare}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Whatsapp Share Link: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.whatsappshare ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="whatsappshare"
                                placeholder="Whatsapp share link"
                                value={this.state.fields.whatsappshare}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.whatsappshare}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">
                              Email Share Link: <span className="red">*</span>
                            </label>

                            <div className={this.state.errors.emailshare ? 'col-sm-6 has-error' : 'col-sm-6'}>
                              <input
                                type="text"
                                className="form-control"
                                id="inputrole"
                                name="emailshare"
                                placeholder="Whatsapp share link"
                                value={this.state.fields.emailshare}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">{this.state.errors.emailshare}</div>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>

                      <div className="box-footer text-center col-sm-8">
                        <Link className="btn btn-primary" to="/referfriend-list">
                          Back
                        </Link>
                        <button type="submit" className="btn btn-info leftside">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Referfriend;
