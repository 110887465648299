import React, { Component } from 'react';
import axios from 'axios';
import Header from '../Header';
import { Redirect, Link } from 'react-router-dom';
import Auth from '../modules/Auth';
import Loader from 'react-loader';
import ReactImageFallback from 'react-image-fallback';
import Pagination from 'react-js-pagination';

class Recipe extends Component {
  constructor(props) {
    super(props);

    this.getRecipes(1, Auth.Itemperpagecount());

    this.state = {
      pageOfItems: [],
      recipes: [],
      search: '',
      status_msg: '',
      showRecords: false,
      loaded: false,
      activePage: 1,
      totalRecords: Auth.Itemperpagecount(),
      stype: '',
      message: '',
      restorestatus: false,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.addnewhandleclick = this.addnewhandleclick.bind(this);
  }

  handlePageChange(pageNumber) {
    const perpage = Auth.Itemperpagecount();
    if (this.state.stype == 1) {
      this.getsearch(pageNumber, perpage);
    } else {
      this.getRecipes(pageNumber, perpage);
    }
    this.setState({ activePage: pageNumber });
  }

  getRecipes(pageNumber, perpage) {
    axios
      .post(`${Auth.getapiurl()}/admin/getAllRecipes`, {
        session: Auth.getToken(),
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        this.setState({ loaded: true });
        const recipes = res.data.results;
        const totalRecords = res.data.records;
        this.setState({ recipes, totalRecords, showRecords: true });
      });
  }

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  addnewhandleclick() {
    this.props.history.push('/recipe-add');
  }

  handleDelete = (itemId) => {
    axios
      .post(`${Auth.getapiurl()}/admin/removeRecipe`, {
        session: Auth.getToken(),
        recipe_id: itemId,
      })
      .then((res) => {
        if (res.data.status == 200) {
          this.getRecipes(1, Auth.Itemperpagecount());
        }
      });
  };

  getsearch = (pageNumber, perpage) => {
    axios
      .post(`${Auth.getapiurl()}/admin/getSearchAllRecipes`, {
        session: Auth.getToken(),
        search: this.state.search,
        pageNumber: pageNumber,
        perpage: perpage,
      })
      .then((res) => {
        if (res.data.status == 400) {
          this.setState({ status_msg: res.data.error, showRecords: false });
        } else {
          const recipes = res.data.results;
          const totalRecords = res.data.records;
          this.setState({ recipes, totalRecords, showRecords: true, stype: 1 });
        }
      });
  };

  handlesearchChange = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      this.setState(
        {
          search: event.target.value,
        },
        () => {
          if (this.state.search && this.state.search.length > 1) {
            this.getsearch(1, Auth.Itemperpagecount());
          } else {
            this.setState({ showRecords: true });
            this.getRecipes(1, Auth.Itemperpagecount());
          }
        }
      );
    }
  };

  render() {
    let paginate;
    if (this.state.totalRecords > Auth.Itemperpagecount()) {
      paginate = (
        <Pagination
          prevPageText="Previous"
          nextPageText="Next"
          firstPageText="First"
          lastPageText="Last"
          activePage={this.state.activePage}
          itemsCountPerPage={Auth.Itemperpagecount()}
          totalItemsCount={this.state.totalRecords}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange}
        />
      );
    }

    if (!Auth.getToken()) {
      return <Redirect to="/login" />;
    }

    const showRecords = this.state.showRecords;
    const status_msg = this.state.status_msg;

    return (
      <div>
        <Loader loaded={this.state.loaded}></Loader>
        <div>
          <Header />
        </div>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border">
                    <div className="col-md-4">
                      <h3 className="box-title">Recipes</h3>
                    </div>
                    <div className="col-md-4 text-right">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here.."
                        onKeyDown={this.handlesearchChange}
                      />
                    </div>
                    <div className="col-md-2 text-right">
                      <button className="btn btn-success" onClick={this.addnewhandleclick}>
                        <i className="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  </div>

                  <div className="box-body">
                    {this.state.restorestatus == true ? (
                      <div className="alert alert-success">{this.state.message}</div>
                    ) : (
                      ''
                    )}
                    <div className="row">
                      {showRecords ? (
                        <div className="col-md-12">
                          <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <td>Recipe Id</td>
                                <td>Recipe Name</td>
                                <td>Description</td>
                                <td>Serving</td>
                                <td>TotalTime</td>
                                <td>Image</td>
                                <td>Action</td>
                              </tr>
                            </thead>

                            <tfoot className="thead-dark">
                              <tr>
                                <td>Recipe Id</td>
                                <td style={{ width: '10%' }}>Recipe Name</td>
                                <td style={{ width: '50%' }}>Description</td>
                                <td>Serving</td>
                                <td>TotalTime</td>
                                <td>Image</td>
                                <td>Action</td>
                              </tr>
                            </tfoot>

                            <tbody>
                              {this.state.recipes.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.Name}</td>
                                  <td>{item.Description}</td>
                                  <td>{item.Serving}</td>
                                  <td>{item.TotalTime}</td>
                                  <td>
                                    <ReactImageFallback
                                      src={Auth.imageCheck(item.RecipeImage)}
                                      fallbackImage={Auth.getapiurl() + '/noimage.png'}
                                      initialImage={Auth.getapiurl() + '/loading.gif'}
                                      alt="cool image should be here"
                                      className="my-image"
                                    />
                                  </td>
                                  <td>
                                    <Link to={'/recipeview/' + item.id} className="btn btn-info leftside">
                                      <i className="fa fa-eye"></i>{' '}
                                    </Link>
                                    <Link to={'/recipe-edit/' + item.id} className="btn btn-info leftside">
                                      <i className="fa fa-edit"></i>
                                    </Link>

                                    <button
                                      type="submit"
                                      className="btn btn-danger leftside"
                                      onClick={() => {
                                        if (window.confirm('Do you want to move item ?')) {
                                          this.handleDelete(item.id);
                                        }
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <td>Recipe Id</td>
                                <td>Recipe Name</td>
                                <td>Description</td>
                                <td>Serving</td>
                                <td>TotalTime</td>
                                <td>Action</td>
                              </tr>
                            </thead>

                            <tfoot className="thead-dark">
                              <tr>
                                <td>Recipe Id</td>
                                <td>Recipe Name</td>
                                <td>Description</td>
                                <td>Serving</td>
                                <td>TotalTime</td>
                                <td>Action</td>
                              </tr>
                            </tfoot>
                            <tbody>
                              <tr>
                                <td>{status_msg}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {paginate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Recipe;
